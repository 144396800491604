import React, {
    useEffect, useState, useContext,
} from 'react';
import useStyles from './UnitRateDetailView.style';
import {
    Box,
} from '@mui/material';
import { CellRendererType, ColumnDefs } from '../../../../types/AgGridTypes';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import unitRateQueries from '../../../../queries/unitrates';
import Loader from '../../../../components/Loader';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import UnitRateType from '../../../../types/UnitRateType';
import AgGridGroupComponent from '../../../../components/agGridTableComponent/AgGridGroup';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext, QueryErrorResponse } from '../../../../types/CommoditiesListType';
import { useOutletContext } from 'react-router-dom';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';

function UnitRateDetailView() {
    const classes = useStyles();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [resourceSortingState, setResourceSortingState] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const cont: OutletContext = useOutletContext();
    const userContext = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const formatCurrency = useDisplayCurrFormatter();

    // Handling API errors
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetUnitRateDetailData',
        unitRateQueries.GET_UNIT_RATE_DETAIL_DATA(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            resourceSortingState,
            'ASC',
            userContext?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const unitRateData = data?.unitrate;
    const tableData = unitRateData?.data;
    const pageInfo = unitRateData?.pageInfo;

    useEffect(() => {
        refetch();
    }, [pageSkipValue, resourceSortingState, sortOrder]);

    const changeSortingValue = (par: string) => {
        if (resourceSortingState === par || resourceSortingState === '') {
            if (sortOrder === 'DESC') {
                setSortOrder('ASC');
            } else {
                setSortOrder('DESC');
            }
        }
        setResourceSortingState(par);
    };

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const rowData = [] as UnitRateType[];
    if (tableData) {
        tableData.forEach((item) => {
            const parentCode = item.code;
            // eslint-disable-next-line max-len
            const parentDescription = `${item.code ?? ''} ${item.description ?? ''} - ${item.labour ?? ''} ${item.unit ?? ''} / Crew Hour; (${item.unit_man_hours ?? ''} Crew Hours per ${item.unit ?? ''})`;
            rowData.push({
                itemCode: parentCode,
                description: parentDescription,
                heirarchy: [parentCode || ''],
            });
            if (item.unit_cost_data) {
                item.unit_cost_data.forEach((unitRateDetail) => {
                    rowData.push({
                        ...unitRateDetail,
                        itemCode: unitRateDetail.entity_code,
                        unit: unitRateDetail.entity_unit,
                        description: unitRateDetail.entity_description,
                        heirarchy: [parentCode || '', unitRateDetail?.entity_code || ''],
                        quantity: unitRateDetail.quantity?.toFixed(2),
                        total_quantity: unitRateDetail.total_quantity?.toFixed(2),
                        labour_hours: unitRateDetail.labour_hours?.toFixed(2),
                    });
                });
            }
        });
    }

    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const gridCustomCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        if (params && params.value) {
            switch (colName) {
            case 'quantity':
            case 'total_quantity':
            case 'labour_hours':
                return params.value ? formatCurr(params.value, local) : '-';
            default:
                return formatCurrency(params?.value);
            }
        }
        if (params.value === null) {
            return '-';
        }
        return params?.value;
    };

    const columnDefs: ColumnDefs[] = [
        {
            field: 'description',
            headerName: 'Description',
            tooltipField: 'description',
            width: 250,
            type: 'string',
        },
        {
            field: 'quantity',
            headerName: 'Qty',
            width: 130,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
        },
        {
            field: 'total_quantity',
            headerName: 'Total qty',
            width: 150,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
        },
        {
            field: 'unit',
            headerName: 'Unit',
            width: 130,
            type: 'string',
        },
        {
            field: 'entity_cost',
            headerName: 'Cost per unit',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 160,
        },
        {
            field: 'labour_hours',
            headerName: 'Labour man hours',
            type: 'numericColumn',
            width: 200,
            cellRenderer: gridCustomCellRenderer,
        },
        {
            field: 'labour',
            headerName: 'Labour cost',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 150,
        },
        {
            field: 'material',
            headerName: 'Material',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 150,
        },
        {
            field: 'equipment',
            headerName: 'Equip C',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 150,
        },
        {
            field: 'p_equipment',
            headerName: 'PE Equip C',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 150,
        },
        {
            field: 'subcontract',
            headerName: 'Sub Cost',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 150,
        },
        {
            field: 'total_unit_resource',
            headerName: 'Total Unit Cost',
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            width: 170,
        },
    ];

    return (
        <div>
            <Box className={`${isFetching ? classes.opacityBlur : ''} ${classes.projectListMain}`}>
                { isFetching ? (
                    <Loader loading={isFetching} />
                ) : null }
                <AgGridGroupComponent
                    parentColumn="Item code"
                    columnDefs={columnDefs}
                    rowData={rowData}
                    changeSortingValue={changeSortingValue}
                    sortOrder={sortOrder}
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    moduleName="UnitRateDetailView"
                    isFetching={isFetching}
                    colFormat={['entity_cost', 'labour', 'material', 'equipment', 'p_equipment', 'subcontract', 'total_unit_resource']}
                />
            </Box>
            <Box>
                <Pagination totalPages={totalPages} handlePageChange={handlePageChange} />
            </Box>
        </div>
    );
}

export default UnitRateDetailView;
