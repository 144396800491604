import React from 'react';
import markerIcon from '../../../../assets/marker.svg';
import { Marker, Tooltip } from 'react-leaflet';
import { Icon } from 'leaflet';
import { Box, Typography } from '@mui/material';
import LeafletMap from '../../../../components/mapBox/LeafletMap';
import mapStyles from './MapView.style';
import ProjectListType from '../../../../types/ProjectListType';

interface Iprops {
    mapData: Partial<ProjectListType>[],
}

function MapView(props: Iprops) {
    const { mapData } = props;
    const classes = mapStyles();
    const customIcon = new Icon({
        iconUrl: markerIcon,
        iconSize: [15, 20],
    });

    return (
        <Box className={classes.mapStyles}>
            <LeafletMap
                zoomLevel={2}
            >
                {mapData?.map((region) => {
                    if (region.heirarchy !== 1 && Number(region?.latitude) && Number(region?.longitude)
                    ) {
                        const lat = Number(region?.latitude);
                        const lang = Number(region?.longitude);
                        return (
                            <Marker
                                position={[lat, lang]}
                                icon={customIcon}
                                key={region?.id}
                            >
                                <Tooltip
                                    offset={[0, -20]}
                                    direction="top"
                                    className={classes.tooltipContainer}
                                >
                                    <div>
                                        <Typography variant="h6">{region?.name}</Typography>
                                        {region?.location && region?.region && (
                                            <Typography variant="h6">{`${region?.location}, ${region?.region}`}</Typography>
                                        )}
                                        <Typography variant="h6">{region?.country?.name}</Typography>
                                    </div>
                                </Tooltip>
                            </Marker>
                        );
                    }
                    return '';
                })}
            </LeafletMap>
        </Box>
    );
}

export default MapView;
