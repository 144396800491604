/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import {
    Box, FormControl, MenuItem, Typography, Button,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import WBSListStyle from './WBSList.style';
import TotalValueType from '../../../../types/TotalValueType';
import CostHeaderBar from '../../../../components/costHeaderBar';
import WbsDefaultViews from '../WBSDifferentView/WbsDefaultViews';
import WbsVersionViews from '../WBSDifferentView/WbsVersionViews';
import WbsCaseView from '../WBSDifferentView/WbsCaseView';
import WbsPhaseView from '../WBSDifferentView/WbsPhaseView';
import { PATH_CMFR } from '../../../../Routes/path';
import { useNavigate } from 'react-router-dom';

export default function WBSList() {
    const classes = WBSListStyle();
    const [view, setView] = useState<string>('Default View');
    const [phaseValue, setPhaseValue] = useState<TotalValueType>();
    const [totalCost, setTotalCost] = useState<number>(0);
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
    };

    const getDefTotalValue = (par: TotalValueType) => {
        if (par !== undefined) {
            setPhaseValue(par);
            setTotalCost(par.total_costTotal);
        }
    };

    const viewsCompo = () => {
        if (view === 'Default View') {
            return <WbsDefaultViews view={view} getDefTotalValue={getDefTotalValue} />;
        }
        if (view === 'Phase View') {
            return <WbsPhaseView view={view} />;
        }
        if (view === 'Case View') {
            return <WbsCaseView view={view} />;
        }
        if (view === 'Version View') {
            return <WbsVersionViews view={view} />;
        }
        return [];
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=wbs`);
    };

    return (
        <Box>
            <div className={classes.TableHeader}>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h2">
                        WBS
                    </Typography>
                    <CostHeaderBar />
                    <Box className={classes.versonUndo}>
                        {/* <Box className={classes.undoButtn}>
                            <button type="submit">
                                <BarChartOutlinedIcon />
                                {' '}
                                Benchmark
                            </button>
                        </Box> */}
                        <Box className={classes.outlineButton}>
                            <Button onClick={navigateToReports}>
                                <DataSaverOffOutlinedIcon />
                                {' '}
                                Reports
                            </Button>
                        </Box>
                        <FormControl variant="filled">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={view}
                                label="View"
                                onChange={handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                color: '#787878',
                                            },
                                            '& .Mui-selected': {
                                                color: '#021948',
                                                bgcolor: '#E6ECFA !important',
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="Default View">
                                    Default View
                                </MenuItem>
                                <MenuItem value="Case View">Case View</MenuItem>
                                <MenuItem value="Phase View">Phase View</MenuItem>
                                <MenuItem value="Version View">Version View</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {/* <Box className={classes.versonUndo}>
                    <Box className={classes.undoButtn}>
                        <button type="submit" onClick={handleClickOpenDuplicate}>
                            <ContentCopyIcon />
                            {' '}
                            Duplicate
                        </button>
                    </Box>
                    <Box className={classes.undoButtn}>
                        <button type="submit">
                            <CloudDownloadOutlinedIcon />
                            {' '}
                            Import
                        </button>
                    </Box>
                    <Box className={classes.undoButtn}>
                        <button type="submit">
                            <CloudUploadOutlinedIcon />
                            {' '}
                            Export
                        </button>
                    </Box>
                    <Box className={classes.undoButtnNew}>
                        <button type="submit" onClick={handleClickOpen}>
                            <AddIcon />
                            {' '}
                            New
                        </button>
                    </Box>
                    <Box>
                        {open && <AddWBSModal open={open} setOpen={setOpen} />}
                        {openDuplicate && <DuplicateRowModal open={openDuplicate} setOpen={setOpenDuplicate} />}
                    </Box>
                </Box> */}
            </div>
            <Box className={classes.tableSec}>
                {viewsCompo()}
            </Box>
        </Box>
    );
}
