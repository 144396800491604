import React, {
    useState, ChangeEvent, useContext, useEffect,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Grid, Button, TextField, DialogActions,
} from '@mui/material';
import AddOrganisationModalStyle from './AddOrganisationModal.style';
import Loader from '../../../../components/Loader';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import organizationQueries from '../../../../queries/organisations';
import { UserContext } from '../../../../store/context/userContext';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import userQueries from '../../../../queries/users';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { useOutletContext } from 'react-router-dom';
import { emailValidation } from '../../../../helper/validations';
import InvitationType from '../../../../types/InvitationTypes';

interface AddOrganisationModalProps {
    open: boolean,
    setOpenAddOrganisationModal: (isOpen: boolean) => void,
    refetchList: () => void
}

function AddOrganisationModal({ open, setOpenAddOrganisationModal, refetchList }: AddOrganisationModalProps) {
    const classes = AddOrganisationModalStyle();
    const initialOrgState = { name: '', display_name: '', email: '' };
    const [organisation, setOrganisation] = useState(initialOrgState);
    const [error, setError] = useState({
        organisation: '',
        email: '',
    });
    const userContext = useContext(UserContext);
    const cont: OutletContext = useOutletContext();

    const { data: allUserDetails, refetch: refetchUser } = useGQLQuery(
        'GetUserRolesAdd',
        userQueries.GET_USER_DETAILS,
        {},
        {
            enabled: false,
            onSuccess: (resData: InvitationType) => {
                userContext?.setUserDetails(resData.getAllUserDetails);
            },
        },
        '/userAuthentication',
    );

    const handleClose = () => {
        setOrganisation(initialOrgState);
        setOpenAddOrganisationModal(false);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const tempError = { ...error };
        const { value, id } = event.target;
        const updatedOrg = {
            ...organisation,
            [id]: value,
        };
        if (id === 'display_name') {
            const name = value !== '' ? value.trim().split(' ').join('_').toLowerCase() : '';
            updatedOrg.name = name;
            tempError.organisation = '';
        }

        if (id === 'email') {
            tempError.email = '';
        }

        setError(tempError);
        setOrganisation(updatedOrg);
    };

    const handleCreateOrgSuccess = (res: InvitationType) => {
        const createError = res.createOrganization?.error;
        if (createError) {
            cont.showNotificationBar(createError, 'error');
        } else {
            refetchList();
            setOrganisation(initialOrgState);
            setOpenAddOrganisationModal(false);
            refetchUser();
            cont.showNotificationBar('Organisation created successfully.', 'success');
        }
    };

    const handleCreateOrgFailure = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const {
        mutate, isLoading,
    } = useGQLMutation(
        'AddOrganisation',
        organizationQueries.CREATE_ORGANISATION(organisation.name, organisation.display_name.trim(), organisation.email),
        {
            onSuccess: handleCreateOrgSuccess,
            onError: handleCreateOrgFailure,
        },
    );

    const handleSubmit = () => {
        // Organisation and email validations
        const { email } = organisation;
        const { name } = organisation;
        const isValidEmail = emailValidation(email);
        const isValidOrgName = organisation.display_name
            ? organisation.display_name.length >= 3 && organisation.display_name.length <= 50
            : 0;
        if (name && isValidOrgName && (!email || (email && isValidEmail))) {
            if (organisation.display_name !== '') {
                mutate({});
            }
        } else {
            let orgError = ''; let
                emailError = '';

            // Organisation name validation check
            if (!name) {
                orgError = 'Mandatory field';
            } else if (!isValidOrgName) {
                orgError = organisation.display_name.length >= 3
                    ? 'Maximum 50 characters allowed in organisation name'
                    : 'Minimum 3 characters required in organisation name.';
            }

            // Email validation check
            if (!email) {
                emailError = '';
            } else if (!isValidEmail) {
                emailError = 'Invalid email format';
            }

            setError({
                organisation: orgError,
                email: emailError,
            });
        }
    };

    const isDisabled = (organisation.display_name === '');

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Create New Organisation</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Organisation Name
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Type Organisation..."
                                color="secondary"
                                id="display_name"
                                value={organisation?.display_name}
                                onChange={handleInputChange}
                                error={!!error.organisation}
                                helperText={error.organisation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Organisation Email
                            </Typography>
                            <TextField
                                fullWidth
                                color="secondary"
                                placeholder="Type email..."
                                id="email"
                                value={organisation?.email}
                                onChange={handleInputChange}
                                error={!!error.email}
                                helperText={error.email}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Loader loading={isLoading} />
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isDisabled}>
                            Done
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AddOrganisationModal;
