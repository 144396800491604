import React from 'react';
import ThemeConfig from './theme';
import { Toaster } from 'react-hot-toast';
import UserContextProvider from './store/context/userContext';
import LoginContextProvider from './store/context/loginContext';
import ProjectContextProvider from './store/context/projectContext';
import UserAuthentication from './components/UserAuthentication';
import ProjectSettingsTabContextProvider from './store/context/projectSettingsTabContext';

function App() {
    return (
        <ThemeConfig>
            <Toaster />
            <LoginContextProvider>
                <UserContextProvider>
                    <ProjectContextProvider>
                        <ProjectSettingsTabContextProvider>
                            <UserAuthentication />
                        </ProjectSettingsTabContextProvider>
                    </ProjectContextProvider>
                </UserContextProvider>
            </LoginContextProvider>
        </ThemeConfig>
    );
}
export default App;
