import React, {
    useContext,
    useState,
} from 'react';
import NavigationWrapper from '../navigationWrapper';
import Router from '../../Routes';
import { useLocation } from 'react-router-dom';
import { PRE_LOGIN_ROUTES } from '../sidebar/AppDrawerMenuConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../store/context/userContext';

export default function UserAuthentication() {
    const [openSidebar, setOpenSideBar] = useState(true);
    const { isAuthenticated } = useAuth0();
    const { pathname } = useLocation();

    // Navbar , sidebar will be disabled for the following cases
    // 1. Its pre-login route
    // 2. User is not authenticated from auth0
    // 3. User details are getting fetched
    // 4. After User details are fetched, org id is not there, case when user's org is deactivated, unless its super admin
    const isPreLoginRoute = PRE_LOGIN_ROUTES.includes(pathname);
    const userContext = useContext(UserContext);
    const userDetailsFetched = !!userContext?.user;
    const isUserOrgActive = userContext?.user?.default_org_id;
    const isSuperAdmin = userContext?.user?.userRole === 'SuperAdmin';

    return (
        <>
            { isPreLoginRoute
            || !isAuthenticated
            || !userDetailsFetched
            || (!isUserOrgActive && !isSuperAdmin) ? null : <NavigationWrapper open={openSidebar} setOpen={setOpenSideBar} /> }
            <Router open={openSidebar} />
        </>
    );
}
