import { makeStyles } from '@mui/styles';

const DashboardStyle = makeStyles(() => ({
    warningIcn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
    },
    inWarningIcon: {
        width: '16px',
        color: '#ffe41e',
    },
    mainContentWrapper: {
        width: 'calc(100% - 240px)',
        marginLeft: '240px',
    },
    innerMainContentWrapper: {
        padding: '60px 30px',
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '40px',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c !important',
        },
    },
    containerElement: {
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '3px',
        fontSize: '12px !important',
        fontWeight: 500,
        fontFamily: 'Inter-Medium !important',
        color: '#353535',
        height: '310px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chartStyle: {
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '10px 7px',
        '& svg': {
            verticalAlign: 'text-bottom',
        },
        '& span': {
            color: '#353535',
            '&:nth-child(1)': {
                color: '#1C1C1C',
            },
            verticalAlign: 'middle',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '5.5px 32px 5.5px 12px !important',
            fontSize: '13px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#323232',
        },
    },
    undoButtn: {
        marginLeft: '25px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '7px 11px',
            cursor: 'pointer',
            fontFamily: 'Inter-Medium !important',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '2px',
            },
        },
    },
    industryRegion: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '7px',
        '& h2': {
            marginBottom: '10px',
            color: '#1c1c1c !important',
            fontWeight: '700 !important',
        },
    },
    industryRegionWidth: {
        width: '49%',
        '& .ag-cell': {
            '& img': {
                width: '27px',
                height: '18px',
                border: '1px solid #000',
                borderRadius: '2px',
                marginLeft: '30px',
                marginTop: '2px',
            },
        },
    },
    tabelFoot: {
        '& .ag-row-level-0': {
            backgroundColor: '#C1D6FF',
        },
    },
    recentProects: {
        margin: '24px 0px',
        '& h2': {
            marginBottom: '0px',
        },
        '& input': {
            color: '#A5A5A5',
            fontSize: '12px',
            border: '1px solid #787878',
            padding: '7px 10px 7px 30px',
            width: '143px',
            borderRadius: '4px',
            letterSpacing: '.5px',
            fontWeight: '500',
        },
    },
    inputBoxStyle: {
        position: 'relative',
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '5px',
            transform: 'translateY(-50%)',
            color: '#787878 !important',
            fontSize: '17px',
        },
    },
    mainUnavailbleStyle: {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100vh - 274px)',
        justifyContent: 'center',
    },
    unavailbleStyle: {
        textAlign: 'center',
        '& h1': {
            fontSize: '62px',
            color: '#AAAAAA',
        },
        '& p': {
            color: '#A0A0A0 !important',
            lineHeight: '34px',
            marginTop: '15px !importnat',
        },
    },
    inBoxGraph: {
        borderRadius: '3px',
        border: '1px solid #CECECE',
        padding: '20px',
        height: '270px',
        maxHeight: '270px',
        display: 'flex',
        alignItems: 'end',
    },
    testCon: {
        width: '100%',
        overflowX: 'auto',
    },
    testContainer: {
        height: '270px',
        width: '100%',
    },
    testContainer2: {
        height: '270px',
        width: '50vw',
    },
    inBoxMap: {
        borderRadius: '3px',
        border: '1px solid #CECECE',
        height: '310px',
        maxHeight: '310px',
    },
}));

export default DashboardStyle;
