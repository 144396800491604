import React, { useContext } from 'react';
import {
    Box, DialogContent, DialogTitle,
    Dialog, Button,
} from '@mui/material';
import SaveNewReportModelStyle from './NewReportPopup.style';
import Report from '../../../../components/report';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { ReportType } from '../../../../types/Reports';
import { UserContext } from '../../../../store/context/userContext';
import { useOutletContext } from 'react-router-dom';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import reportQuery from '../../../../queries/report';
import Loader from '../../../../components/Loader';

interface SaveNewReportModelProps {
    open: boolean,
    setOpen: (isOpen: boolean) => void,
    name: string,
    module: string,
    reportListUpdate: (isEditMode: boolean) => void,
    isEditMode: boolean,
    selectedReport: ReportType | null,
    updateEditedReport: (report: ReportType) => void,
}

function NewReportPopup({
    open, setOpen, name, module, reportListUpdate, isEditMode, selectedReport, updateEditedReport,
}: SaveNewReportModelProps) {
    const userCtx = useContext(UserContext);
    const orgID = userCtx?.user?.default_org_id;
    const classes = SaveNewReportModelStyle();
    const cont: OutletContext = useOutletContext();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleApiSuccess = () => {
        cont.showNotificationBar(isEditMode ? 'Report updated successfully.' : 'Report saved successfully.', 'success');
        reportListUpdate(isEditMode);
        setOpen(false);
    };

    const { mutate, data, isLoading } = useGQLMutation(
        'CreateReport',
        reportQuery.ADD_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const { mutate: editMutate, data: editData, isLoading: isUpdating } = useGQLMutation(
        'UpdateReport',
        reportQuery.EDIT_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const handleClose = () => {
        setOpen(false);
    };

    const saveReport = (report: ReportType) => {
        // Create/Update reports
        if (isEditMode) {
            // Update local state to reflect changes in the edited report
            updateEditedReport({
                id: report.id,
                display_name: report.display_name,
                data: report.data,
                module_name: report.module_name,
            });
            editMutate({
                org_id: orgID,
                id: report.id,
                data: report.data,
                // display_name: report.display_name,
            });
        } else {
            mutate({
                org_id: orgID,
                data: report.data,
                module_name: report.module_name,
                display_name: report.display_name,
            });
        }
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>
                    <Box className={classes.outlineButton}>
                        { isEditMode ? 'Edit Report' : 'Save New Report' }
                        <Box>
                            <Button variant="outlined" onClick={handleClose}>Close</Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Loader loading={isLoading || isUpdating} isFromAGGrid />
                    <Report name={name} module={module} saveReport={saveReport} isEditMode={isEditMode} selectedReport={selectedReport} />
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default NewReportPopup;
