import { makeStyles } from '@mui/styles';

const AgGridTableStyle = makeStyles(() => ({
    tabelFoot: {
        // '& .ag-row-level-0': {
        //     backgroundColor: '#fff !important',
        // },
        '& .ag-cell-wrapper': {
            height: '27px !important',
        },
        '& .ag-row-pinned': {
            backgroundColor: '#F8F8F8 !important',
            '&:hover': {
                pointerEvents: 'none',
                backgroundColor: '#FDFDFD !important',
            },
            '& .ag-cell': {
                color: '#353535 !important',
                fontWeight: '700 !important',
                fontFamily: 'Inter-Medium !important',
                lineHeight: '2',
                '& span': {
                    color: '#353535 !important',
                    fontWeight: '700 !important',
                    fontFamily: 'Inter-Medium !important',
                },
                '& b': {
                    color: '#353535 !important',
                    fontWeight: '700 !important',
                    fontFamily: 'Inter-Medium !important',
                },
            },
        },
    },
    AgHeaderCustomDesc: {
        color: '#8F8F8F',
        fontSize: '13px',
        fontWeight: '600',
        fontFamily: 'Inter !important',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            paddingTop: '4px',
        },
        '& svg': {
            color: '#8F8F8F !important',
        },
    },
    containerBox: {
        position: 'relative',
    },
    loaderBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    opacityBox: {
        opacity: '50%',
    },
    noOpacity: {
        opacity: '100%',
    },
    tableStyles: {
        '& .ag-header-cell-text': {
            color: '#8F8F8F',
            fontSize: '13px',
            fontWeight: '600',
            fontFamily: 'Inter-Medium !important',
        },
        '& .ag-row-group-expanded': {
            backgroundColor: '#C1D6FF !important',
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Inter-Medium !important',
            '& .ag-cell': {
                fontWeight: '700 !important',
                fontSize: '13px !important',
                fontFamily: 'Inter-Medium !important',
            },
        },
        '& .ag-row-group-contracted': {
            // backgroundColor: '#C1D6FF !important',
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Inter-Medium !important',
        },
        '& .ag-body': {
            backgroundColor: '#f8f8f8',
        },
        '& .ag-center-cols-container': {
            minWidth: '100% !important',
        },
        '& .ag-floating-bottom-container': {
            minWidth: '100% !important',
        },
        '& .ag-header-cell-resize': {
            opacity: '0',
        },
        '& .ag-cell': {
            color: '#353535',
            fontSize: '13px',
            fontWeight: '500',
            fontFamily: 'Inter !important',
            // textAlign: 'left !important',
            '& .ag-row-group-leaf-indent': {
                marginLeft: '0px !important',
            },
            '& .ag-right-aligned-cell': {
                marginLeft: '0px !important',
            },
            '& a': {
                color: '#007DFF',
            },
        },
        '& .ag-header-cell': {
            '& .ag-checkbox-input': {
                pointerEvents: 'none',
            },
        },
        '& .ag-header-container': {
            marginRight: '17px',
        },
        '& .ag-row-selected::before': {
            backgroundColor: '#C6FFCA !important',
        },
        '& .ag-row-hover': {
            backgroundColor: '#C6FFCA !important',
        },
        '& .ag-checkbox-input-wrapper.ag-checked::after': {
            color: '#021948 !important',
        },
        '& .ag-checkbox-input-wrapper.ag-indeterminate::after': {
            color: '#021948 !important',
        },
        '& .ag-ltr': {
            textAlign: 'left !important',
        },
    },
    tableStylesCommodities: {
        '& .ag-header-cell-text': {
            color: '#8F8F8F',
            fontSize: '13px',
            fontWeight: '600',
            fontFamily: 'Inter-Medium !important',
        },
        '& .ag-body': {
            backgroundColor: '#f8f8f8',
        },
        '& .ag-center-cols-container': {
            minWidth: '100% !important',
        },
        '& .ag-floating-bottom-container': {
            minWidth: '100% !important',
        },
        '& .ag-header-cell-resize': {
            opacity: '0',
        },
        '& .ag-cell': {
            color: '#353535',
            fontSize: '13px',
            fontWeight: '500',
            fontFamily: 'Inter !important',
            // textAlign: 'left !important',
            '& .ag-row-group-leaf-indent': {
                marginLeft: '0px !important',
            },
            '& .ag-right-aligned-cell': {
                marginLeft: '0px !important',
            },
            '& a': {
                color: '#007DFF',
            },
        },
        '& .ag-header-cell': {
            '& .ag-checkbox-input': {
                pointerEvents: 'none',
            },
        },
        '& .ag-header-container': {
            marginRight: '17px',
        },
        '& .ag-row-selected::before': {
            backgroundColor: '#C6FFCA !important',
        },
        '& .ag-row-hover': {
            backgroundColor: '#C6FFCA !important',
        },
        '& .ag-checkbox-input-wrapper.ag-checked::after': {
            color: '#021948 !important',
        },
        '& .ag-checkbox-input-wrapper.ag-indeterminate::after': {
            color: '#021948 !important',
        },
        '& .ag-ltr .ag-right-aligned-cell': {
            // textAlign: 'left !important',
        },
    },
}));

export default AgGridTableStyle;
