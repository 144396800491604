import { makeStyles } from '@mui/styles';

const TotalProjectsBarStyle = makeStyles(() => ({
    chartStyle: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '2px 12px',
        '& span': {
            color: '#353535',
            fontSize: '11px',
            fontWeight: 600,
            '&:nth-child(1)': {
                color: '#1C1C1C',
            },
        },
    },
}));

export default TotalProjectsBarStyle;
