type Month = string | number;

export const dateFormat = (timeStamp: number | string, format?: string) => {
    const date = new Date(timeStamp);
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    if (format === 'dd/mm/yyyy') {
        let monthValue: Month = date.getMonth() + 1;
        if (monthValue !== 11 && monthValue !== 12) {
            monthValue = `0${monthValue}`;
        }
        return `${day}/${monthValue}/${year}`;
    }
    return `${day}-${month}-${year}`;
};
