import { makeStyles } from '@mui/styles';

const PaginationStyle = makeStyles({
    paginationStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '16%',
        fontSize: '12px',
        border: '1px solid #C6C6C6',
        borderRadius: '5px',
        padding: '8px',
        marginTop: '16px',
        marginLeft: 'auto',
        fontFamily: 'Inter-Medium',
        '& svg': {
            color: '#C6C6C6 !important',
            cursor: 'pointer',
        },
        '& span': {
            color: '#353535',
        },
    },
    textSpan: {
        padding: '2px 8px',
        fontSize: '12px',
        fontFamily: 'Inter-Medium',
        borderRadius: '2px',
        border: '0.5px solid #C6C6C6',
        textAlign: 'center',
    },
    blueIcon: {
        fill: '#007DFF !important',
    },
});

export default PaginationStyle;
