import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const TOTAL_PROJRCTS_COUNT = (Org_id: string, selectedIndustry: string, selectedCountry: string) => {
    const industryId = selectedIndustry ? `industry_id: ${addEscapeCharacters(selectedIndustry)}` : '';
    const countryId = selectedCountry ? `country_id: ${addEscapeCharacters(selectedCountry)}` : '';
    return gql`
    query {
        projectTotalCount(
          filter: { 
            org_id: ${addEscapeCharacters(Org_id)}
            ${industryId},
            ${countryId},
          }
        ) {
          project_count
          project_country_count
        }
    }`;
};

export default TOTAL_PROJRCTS_COUNT;
