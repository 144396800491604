import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loader from '../components/Loader';
import React, { useCallback, useContext, useEffect } from 'react';
import { useGQLQuery } from '../hooks/useGQLQuery';
import userQueries from '../queries/users';
import { LoginContext } from '../store/context/loginContext';
import { UserContext } from '../store/context/userContext';
import {
    encodeToken, getDefaultOrgIDInLocalStorage, getOrgIdFromLocalStorage, setAccessTokenInLocalStorage, setDefaultOrgIDInLocalStorage,
} from './StorageHelper';
import { useLocation } from 'react-router-dom';
import { PRE_LOGIN_ROUTES } from '../components/sidebar/AppDrawerMenuConfig';
import useSubscription from '../hooks/useGQLSubscription';
import subscription from '../queries/subscription';
import SubscriptionType from '../types/SubscriptionResponseTypes';
import { useLogout } from '../hooks/useLogout';

export interface AuthGuardProps {
    component: React.FC<{
        open: boolean,
    }>,
    open: boolean,
}

export function AuthGuard({ component, open }: AuthGuardProps) {
    const loginCtx = useContext(LoginContext);
    const userContext = useContext(UserContext);
    const location = useLocation();

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const logout = useLogout();

    // Listen to new data changes, also logout if default org_id is changed.
    const setData = useCallback((data: SubscriptionType) => {
        // If the org_id is not present in the local storage, dont log out user
        // just show a switch organization popup.
        const localOrgId = getOrgIdFromLocalStorage();
        if (data.getAllUserDetails?.default_org_id
            && data.getAllUserDetails.default_org_id !== getDefaultOrgIDInLocalStorage()
            && localOrgId) {
            logout();
        }
        userContext?.setUserDetails(data.getAllUserDetails);
    }, []);
    const initialize = useSubscription(subscription.SUBSCRIBE_ALL_USER_DETAILS, setData);

    const WrapperComponent = withAuthenticationRequired(component, {
        // eslint-disable-next-line react/no-unstable-nested-components
        onRedirecting: () => (
            <div className="page-layout">
                <Loader loading />
            </div>
        ),
    });

    useEffect(() => {
        if (isAuthenticated) {
            // Fetch access token from Auth0 is required
            getAccessTokenSilently()
                .then((token: string) => {
                    // Encode token and store in context as well as local storage
                    const encodedValue = encodeToken(token);
                    setAccessTokenInLocalStorage(encodedValue);
                    loginCtx?.setLoginToken(encodedValue);
                    initialize();
                })
                .catch(() => console.log('error'));
        }
    }, [isAuthenticated]);

    //  Get user details like id, name, org_id, role
    const { data: allUserDetails, refetch, isFetching } = useGQLQuery(
        'GetUserRoles',
        userQueries.GET_USER_DETAILS,
        {},
        {
            enabled: false,
        },
        '/userAuthentication',
    );

    useEffect(() => {
        // Fetch user information as soon as the access token is available
        if (loginCtx?.loginToken && !PRE_LOGIN_ROUTES.includes(location.pathname)) {
            refetch();
        }
    }, [loginCtx?.loginToken]);

    useEffect(() => {
        userContext?.setUserDetails(allUserDetails?.getAllUserDetails);
        setDefaultOrgIDInLocalStorage(allUserDetails?.getAllUserDetails?.default_org_id);
    }, [allUserDetails]);

    if (isFetching || (!allUserDetails && isAuthenticated)) {
        return <Loader loading={isFetching} />;
    }

    return <WrapperComponent open={open} />;
}
