import React, { useContext } from 'react';
import OrgCostHeaderBarStyle from './OrgCostHeaderBar.style';
import { Box } from '@mui/material';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import costQueries from '../../queries/orgCost';
import { UserContext } from '../../store/context/userContext';
import DonutChart from '../DonutChart/DonutChart';
import { formatCurr } from '../../hooks/useFormatCurrency';

interface DonutData {
    label: string,
    value: number,
}

export default function OrgCostHeaderBar() {
    const classes = OrgCostHeaderBarStyle();
    const userCtx = useContext(UserContext);

    const colors = ['#35B83D', '#484DFF'];

    const { data, isFetching, refetch } = useGQLQuery(
        `GetOrgCost-${userCtx?.user?.default_org_id || ''}`,
        costQueries.GET_ORG_COST(userCtx?.user?.default_org_id || ''),
        {},
        {},
    );

    const orgCost = data?.orgCosts;

    const donutData: DonutData[] = [
        {
            label: 'Direct',
            value: (orgCost?.direct) ? Number(orgCost.direct) : 0,
        },
        {
            label: 'Indirect',
            value: orgCost?.indirect ? Number(orgCost.indirect) : 0,
        },
    ];

    return (
        <Box className={classes.chartStyle}>
            <span style={{ color: colors[0] }}>
                Direct
                &nbsp;
                {orgCost?.direct ? `$${formatCurr(+orgCost.direct, 'en-US')}` : 0}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: colors[1] }}>
                Indirect
                &nbsp;
                {orgCost?.indirect ? `$${formatCurr(+orgCost.indirect, 'en-US')}` : 0}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: '#353535' }}>
                Total
                &nbsp;
                {orgCost?.total ? `$${formatCurr(+orgCost.total, 'en-US')}` : 0}
            </span>
            &nbsp;&nbsp;
            <DonutChart chartData={donutData} innerRd={7} outerRd={11} height={25} inHeader />

        </Box>
    );
}
