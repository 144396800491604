import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    resourceBody: {
        marginTop: '30px',
    },
    selectionSec: {
        display: 'flex',
        alignItems: 'center',
    },
    outlineButton: {
        marginRight: '10px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '0.5px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
    newButton: {
        marginRight: '10px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
        },
    },
    tableSec: {
        position: 'relative',
        marginTop: '30px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 303px) !important',
            },
        },
        '& .ag-right-aligned-cell': {
            textAlign: 'unset',
        },
    },
    preText: {
        borderBottom: '1px solid #CECECE',
        padding: '4px 14px',
    },
    chartPreviewBox: {
        marginTop: '30px',
        height: 'calc(100vh - 250px) !important',
        border: '1px solid #CECECE',
        borderRadius: '4px 14px',
        position: 'relative',
    },
    noDataContainer: {
        position: 'absolute',
        top: '50%',
        left: '45%',
        color: 'gray',
    },
    chartPreviewWrapper: {
        height: '95%',
    },
    loaderContainer: {
        height: '60vh',
    },
    opacityBlur: {
        opacity: '50%',
    },
    gridCheckbox: {
        '&:after': {
            position: 'absolute',
            top: '0',
            left: '0',
            color: '#021948 !important',
        },
        '& svg': {
            width: '17px !important',
        },
    },
}));
