/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
    useState, useEffect, useContext,
} from 'react';
import { ValueFormatterParams, IRowNode } from 'ag-grid-community';
import { Box } from '@mui/material';
import EstimateStyle from './Estimate.style';
import EstimateHeader from '../../components/EstimateHeader';
import estimateQueries from '../../../../queries/estimate';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import Loader from '../../../../components/Loader';
import { OutletContext, QueryErrorResponse } from '../../../../types/CommoditiesListType';
import { useOutletContext } from 'react-router-dom';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { CellRendererType } from '../../../../types/AgGridTypes';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';

interface EstimateDataInterface {
    description: string,
    detailed_description: string,
    quantity: number,
    ur_code: string,
    unit_cost: number,
    total_cost: number,
    wbs: {
        description: string,
        code: string,
        level: number,
        code1: string,
        code2: string
    },
    coa: {
        code: string,
        level: number,
        code1: string,
        code2: string
    }
  }

function CustomGroupRowRenderer(params: ValueFormatterParams) {
    const { node } = params;
    if (node) {
        const rowNode = node;
        const selectColor = () => {
            let color;
            switch (rowNode.field) {
            case 'wbs.first_parent':
                color = '#88B0FF';
                break;
            case 'wbs.second_parent':
                color = '#AECAFF';
                break;
            case 'wbs.self_code_desc':
                color = '#C1D6FF';
                break;
            default:
                color = '#F8F8F8';
                break;
            }
            return color;
        };

        const style = {
            height: '100%',
            backgroundColor: selectColor(),
            fontSize: '12px',
            fontWeight: 700,
            color: '#000',
            lineHeight: '27px',
            paddingLeft: '22px',
        };

        return (
            <div style={style}>
                <span style={style}>
                    <span>
                        {rowNode.key}
                    </span>
                </span>
            </div>
        );
    }
}

export default function Estimate() {
    const classes = EstimateStyle();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const cont: OutletContext = useOutletContext();
    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);
    const formatCurrency = useDisplayCurrFormatter();
    const [searchText, setSearchText] = useState<string>('');

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const rowCellRenderer = (params: CellRendererType) => {
        const { value } = params;
        const colName = params.colDef.field;
        if (value != null) {
            if (params?.node?.rowPinned) {
                return (
                    <span>
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            switch (colName) {
            case 'total_cost':
            case 'total_unit_cost':
                return formatCurrency(value);
            case 'quantity':
                return value && formatCurr(+params.value.toFixed(0), local);
            default:
                return value;
            }
        } else if (value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'wbs.first_parent',
            type: 'number',
            width: 130,
            headerName: 'WBS Parent 1',
            cellRenderer: rowCellRenderer,
            hide: true,
            rowGroup: true,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.second_parent',
            type: 'number',
            width: 130,
            headerName: 'WBS Parent 2',
            rowGroup: true,
            cellRenderer: rowCellRenderer,
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.self_code_desc',
            type: 'number',
            width: 130,
            headerName: 'WBS Self Desc',
            rowGroup: true,
            cellRenderer: rowCellRenderer,
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'coa.self_code_desc',
            type: 'number',
            width: 130,
            headerName: 'COA Self Desc',
            rowGroup: true,
            cellRenderer: rowCellRenderer,
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.code',
            type: 'number',
            width: 130,
            headerName: 'WBS',
            // rowGroup: true,
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'coa.code',
            type: 'number',
            width: 130,
            headerName: 'COA',
            // rowGroup: true,
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'wbs.code1',
            type: 'number',
            width: 130,
            headerName: 'WBS Code1',
            cellRenderer: rowCellRenderer,
            hide: true,
            colId: 'wbscode1',
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.code2',
            type: 'number',
            width: 130,
            headerName: 'WBS Code2',
            cellRenderer: rowCellRenderer,
            hide: true,
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.code3',
            type: 'number',
            width: 130,
            headerName: 'WBS Code3',
            cellRenderer: rowCellRenderer,
            hide: true,
            colId: 'wbscode3',
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.code4',
            type: 'number',
            width: 130,
            headerName: 'WBS Code4',
            cellRenderer: rowCellRenderer,
            hide: true,
            colId: 'wbscode4',
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'sequence',
            type: 'string',
            width: 130,
            headerName: 'Item',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'description',
            type: 'string',
            width: 300,
            headerName: 'Description1',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'detailed_description',
            type: 'string',
            width: 300,
            headerName: 'Description2',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'quantity',
            type: 'numericColumn',
            width: 160,
            headerName: 'Qty',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'ur_code',
            type: 'string',
            width: 160,
            headerName: 'Unit Rate',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            width: 160,
            headerName: 'Total Cost',
            cellRenderer: rowCellRenderer,
        },
        {

            field: 'total_unit_cost',
            type: 'numericColumn',
            width: 160,
            headerName: 'Unit Cost',
            cellRenderer: rowCellRenderer,
        },
    ];

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        `GetEstimateDetails-${projectCtx?.project?.id || ''}`,
        estimateQueries.GET_ESTIMATE_DETAILS(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const estimateData = data?.estimate_detail;
    const estData = estimateData?.data;
    const tableData = estData?.estimatedData;
    const totalValue = estData?.total;
    const pageInfo = estimateData?.pageInfo;
    const rowData = tableData && tableData.length ? tableData : [];
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    let footerData;
    if (totalValue) {
        footerData = [{
            wbs: 'Selected(0)',
            description: `Total Man Hours : ${totalValue.total_man_hours}`,
            detailed_description: `L Cost : ${totalValue.labour_cost}`,
            quantity: `CE Cost : ${totalValue.construction_equip_cost}`,
            ur_code: `M Cost : ${totalValue.material_cost}`,
            total_cost: `E Cost : ${totalValue.equipment_cost}`,
            unit_cost: `T Cost : ${totalValue.total_cost}`,
        }];
    }

    const handlePageChange = (page: number) => {
        const skip = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skip);
    };

    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        refetch();
    }, [pageSkipValue]);

    // const sortedData = rowData.sort((a, b) => +(b.wbs.code) - +(a.wbs.code));

    return (
        <Box className={classes.mainEstmate}>
            <Loader loading={isFetching} />
            <EstimateHeader onFilterTextBoxChanged={onFilterTextBoxChanged} />
            <Box>
                <Box className={isFetching ? classes.opacityBlur : ''}>
                    <AgGridComponent
                        columnDefs={columnDefs}
                        rowData={rowData}
                        changeSortingValue={() => {}}
                        // pinnedBottomRowData={footerData}
                        quickFilterText={searchText}
                        isPinnable
                        isGroupable
                        isRangeSelectable
                        isExportEnabled
                        isClipboardEnabled
                        isToolPanelsEnabled
                        isStatusBarEnabled
                        moduleName="estimate"
                        defaultExpanded={4}
                        isRangeHandle
                        customGroupRowRenderer={CustomGroupRowRenderer}
                        colFormat={['total_cost', 'total_unit_cost']}
                        disableResizable
                    />
                </Box>
                <Box><Pagination totalPages={totalPages} handlePageChange={handlePageChange} /></Box>
            </Box>
        </Box>
    );
}
