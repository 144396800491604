import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    TableHeader: {
        margin: '0px',
        color: '#021948',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 0px 20px 0px',
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: 'none',
                opacity: '1',
            },
        },
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c !important',
        },
    },
    chartStyle: {
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '2px 12px',
        '& img': {
            verticalAlign: 'sub',
        },
        '& span': {
            verticalAlign: 'middle',
            fontSize: '11px',
            fontFamily: 'Inter-Medium',
            fontWeight: '600 !important',
            textTransform: 'none',
            '&:nth-child(1)': {
                color: '#35B83D',
            },
            '&:nth-child(2)': {
                color: '#484DFF',
            },
            '&:nth-child(3)': {
                color: '#353535',
            },
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '27px !important',
            borderRadius: '5px !important',
            border: '1px solid #787878',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '1px 32px 1px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#787878',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    projectListMain: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
    },
    deleteButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#B00000',
            border: '1px solid #B00000',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#B00000',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '2px',
            },
        },
    },
    loaderContainer: {
        height: '60vh',
    },
    opacityBlur: {
        opacity: '50%',
    },
}));
