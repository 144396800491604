import React, {
    ChangeEvent, ChangeEventHandler, useState, useEffect,
} from 'react';
import {
    Box, TextField,
} from '@mui/material';
import PaginationTableStyle from './PaginationTable.style';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface Props {
    totalPages: number,
    handlePageChange: (page: number) => void,
    view?: string,
    isReportsPage?: boolean,
}

export default function Pagination(props: Props) {
    const {
        totalPages, handlePageChange, view, isReportsPage,
    } = props;
    const classes = PaginationTableStyle();
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setCurrentPage(1);
    }, [view]);

    const handleCurrentPageChange = (type: string) => {
        let newPage;
        switch (type) {
        case 'next': newPage = currentPage + 1;
            break;

        case 'previous': newPage = currentPage - 1;
            break;

        case 'last': newPage = totalPages;
            break;

        case 'first': newPage = 1;
            break;

        default: newPage = currentPage;
        }

        setCurrentPage(newPage);
        handlePageChange(newPage);
    };

    // const handlePageInput = (event: ChangeEvent<HTMLInputElement>) => {
    //     const target = event.target as HTMLButtonElement;
    //     const newPage = +(target.value);
    //     if (Number(newPage)) {
    //         if ((newPage <= 1) || (newPage >= totalPages)) {
    //             return;
    //         }
    //         setCurrentPage(newPage);
    //         handlePageChange(newPage);
    //     }
    // };

    const previousEnabled = !(currentPage === 1);
    const nextEnabled = !(currentPage === totalPages) && !!totalPages;

    return (
        <div>
            <Box style={{ width: isReportsPage ? '40%' : '' }} className={classes.paginationStyle}>
                <FirstPageIcon
                    className={previousEnabled ? classes.blueIcon : ''}
                    onClick={previousEnabled ? () => handleCurrentPageChange('first') : undefined}
                />
                <NavigateBeforeIcon
                    className={previousEnabled ? classes.blueIcon : ''}
                    onClick={previousEnabled ? () => handleCurrentPageChange('previous') : undefined}
                />
                <span role="textbox" className={classes.textSpan}>{totalPages > 0 ? currentPage : totalPages}</span>
                of
                {' '}
                {totalPages}
                <NavigateNextIcon
                    className={nextEnabled ? classes.blueIcon : ''}
                    onClick={nextEnabled ? () => handleCurrentPageChange('next') : undefined}
                />
                <LastPageIcon
                    className={nextEnabled ? classes.blueIcon : ''}
                    onClick={nextEnabled ? () => handleCurrentPageChange('last') : undefined}
                />
            </Box>
        </div>
    );
}
