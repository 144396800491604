import React, { useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';
import useStyles from './UserInformation.style';
import { clearAccessTokenOnLogout } from '../../../helper/StorageHelper';
import { UserContext } from '../../../store/context/userContext';
import useIsSuperAdmin from '../../../hooks/isSuperAdmin';
import { PATH_DATABASE, PATH_SETTINGS } from '../../../Routes/path';
import { useUserRole } from '../../../hooks/useUserRole';
import { ADMIN_ROLE, ROLES } from '../../../constants';
import SwitchOrganizationModel from '../components/SwitchOrganizationModel';

export function UserInformation() {
    const classes = useStyles();
    const { user, isAuthenticated, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const userContext = useContext(UserContext);
    const isSuperAdmin = useIsSuperAdmin();
    const userOrgRole = useUserRole();
    const [switchOrgOpen, setSwitchOrgOpen] = useState(false);
    const navigate = useNavigate();

    const handleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (path?: string) => {
        setAnchorEl(null);
        if (path) navigate(path);
    };

    const handleClickOpen = () => {
        setSwitchOrgOpen(true);
        handleClose();
    };

    const handleLogout = () => {
        clearAccessTokenOnLogout();
        logout({ logoutParams: { returnTo: window.location.origin } })
            .then(() => {})
            .catch(() => {});
    };

    // No need to show switch organisation when user is a part of only one organisation
    const userOrganisations = userContext && userContext.user && userContext.user.orgnizations ? userContext.user.orgnizations : [];

    const getUserRole = () => {
        const userRole = ROLES.find((role) => role.id === userOrgRole);
        return isSuperAdmin ? 'Super Admin' : userRole?.role;
    };

    return (
        <div className={classes.rightLogout}>
            <img src={user?.picture} alt="Ellipse" width={40} style={{ borderRadius: '50%' }} />
            <div className={classes.rightLogoutAdminMain}>
                <Tooltip title={isAuthenticated && user ? user.nickname : 'Guest'}>
                    <Typography
                        component="span"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        Hello&nbsp;
                        {isAuthenticated && user ? user.nickname : 'Guest'}

                    </Typography>
                </Tooltip>
                <span className={classes.userRole}>{getUserRole()}</span>
            </div>
            <div className={classes.rightLogoutButoon}>
                <Button
                    onClick={handleMenu}
                >
                    <ExpandMoreIcon />
                </Button>
            </div>
            <Menu
                sx={{ mt: '42px' }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                className={classes.menuStyle}
            >
                { isSuperAdmin ? (
                    <MenuItem onClick={() => handleClose(PATH_DATABASE.organisations)}>
                        Organisations List
                    </MenuItem>
                ) : null}
                { userOrgRole === ADMIN_ROLE ? (
                    <MenuItem onClick={() => handleClose(PATH_SETTINGS.organisation)}>
                        Organisation Settings
                    </MenuItem>
                ) : null }
                { userOrganisations.length > 1 ? (
                    <MenuItem onClick={handleClickOpen}>
                        Switch Organisation
                    </MenuItem>
                ) : null}
                <MenuItem onClick={() => handleClose(PATH_SETTINGS.user)}>
                    User Settings
                </MenuItem>
                { (userOrgRole === ADMIN_ROLE || isSuperAdmin) ? (
                    <MenuItem onClick={() => handleClose(PATH_SETTINGS.importLog)}>
                        Import Logs
                    </MenuItem>
                ) : null }
                <MenuItem
                    onClick={handleLogout}
                >
                    Logout
                </MenuItem>
            </Menu>
            { userOrganisations.length > 1 ? (
                <SwitchOrganizationModel open={switchOrgOpen} setOpen={setSwitchOrgOpen} />
            ) : null}
        </div>
    );
}
