import { makeStyles } from '@mui/styles';

const AppDrawerStyle = makeStyles(() => ({
    leftBar: {
        '& h5': {
            padding: '35px 0px 20px 17px',
            fontSize: '16px',
            fontWeight: '600',
        },
        '& span': {
            cursor: 'pointer',
        },
    },
    leftBarLink: {
        padding: '0px 15px',
        '& svg': {
            fontSize: '18px',
            marginRight: '7px',
        },
        '& img': {
            marginRight: '6px',
            width: '15px',
        },
        '& .MuiListItemButton-root': {
            padding: '2px 0px 2px 25px',
            '& span': {
                color: '#1c1c1c',
                fontFamily: 'Inter',
                fontWeight: '400',
                fontSize: '13px',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 'auto',
        },
        '& .Mui-selected': {
            backgroundColor: '#E6ECFA !important',
            borderRadius: '8px',
            padding: '2px 0px 2px 25px',
            '&:before': {
                content: '""',
                width: '4px',
                height: '16px',
                position: 'absolute',
                left: '0px',
                backgroundColor: '#021948',
                borderRadius: '3px',
            },
            '& span': {
                color: '#021948 !important',
                fontFamily: 'Inter-Medium !important',
                fontWeight: '600 !important',
            },
            '& svg': {
                color: '#021948',
            },
        },
    },
    leftBarLinkProject: {
        padding: '0px 15px',
        '& svg': {
            fontSize: '18px',
        },
        '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            marginRight: '7px',
        },
        '& .MuiListItemButton-root': {
            padding: '0px 0px 0px 40px',
        },
        '& .Mui-selected': {
            backgroundColor: '#E6ECFA !important',
            borderRadius: '8px',
            padding: '0px 10px 0px 40px',
            '&:before': {
                content: '""',
                width: '4px',
                height: '16px',
                position: 'absolute',
                left: '0px',
                backgroundColor: '#021948',
                borderRadius: '3px',
            },
            '&:after': {
                content: '""',
                width: '5px',
                height: '5px',
                position: 'absolute',
                left: '20px',
                backgroundColor: '#021948',
                borderRadius: '50px',
            },
            '& span': {
                color: '#021948',
                fontFamily: 'Inter-Medium',
            },
            '& svg': {
                color: '#021948',
            },
        },
    },
    projectList: {
        paddingLeft: '20px',
        paddingRight: '10px',
        '& P': {
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'left',
        },
    },
    projectListAccordian: {
        '& ul': {
            padding: '0px !important',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            left: '10%',
            transform: 'rotate(180deg)',
            '&.Mui-expanded': {
                transform: 'rotate(0deg)',
            },
            '& svg': {
                fontSize: '17px',
            },
        },
        '& .MuiAccordionSummary-root': {
            minHeight: 'auto !important',
            marginTop: '8px',
        },
        padding: '0px',
        '& .MuiAccordionSummary-content': {
            margin: '0px !important',
            width: '100%',
        },
        '& .MuiPaper-root': {
            padding: '0px !important',
            boxShadow: 'none !important',
        },
        '& p': {
            color: '#000000 !important',
            fontWeight: '600',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            marginTop: '0px !important',
            paddingLeft: '32px',
            fontFamily: 'Inter-Medium',
        },
    },
}));

export default AppDrawerStyle;
