// Coa page styles
import { makeStyles } from '@mui/styles';

const CoaListStyle = makeStyles({
    tabelFoot: {
        // '& .ag-row-level-0': {
        //     backgroundColor: '#fff !important',
        // },
        '& .ag-cell-wrapper': {
            height: '27px !important',
        },
        '& .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell)': {
            borderLeft: 'none !important',
        },
        '& .ag-row-pinned': {
            backgroundColor: '#F8F8F8 !important',
            '&:hover': {
                pointerEvents: 'none',
                backgroundColor: '#FDFDFD !important',
            },
            '& .ag-cell': {
                color: '#353535 !important',
                fontWeight: '700 !important',
                fontFamily: 'Inter-Medium !important',
                lineHeight: '2',
                '& span': {
                    color: '#353535 !important',
                    fontWeight: '700 !important',
                    fontFamily: 'Inter-Medium !important',
                },
                '& b': {
                    color: '#353535 !important',
                    fontWeight: '700 !important',
                    fontFamily: 'Inter-Medium !important',
                },
            },
        },
    },
    heirarchystyl: {
        fontSize: '15.33px',
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiSelect-select': {
            fontSize: '18px',
            height: '21px !important',
            minHeight: '0.4375em !important',
            borderRadius: '5px !important',
            border: '1px solid #787878',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '4px 32px 4px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#787878',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    undoButtnNew: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
               0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3.5px 11px',
            cursor: 'pointer',
            textTransform: 'capitalize',
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
                marginTop: '-5px',
            },
        },
        '&:hover': {
            background: '#007DFF !important',
            borderRadius: '4px',
        },
        '&:focus': {
            backgroundColor: '#007DFF',
            borderRadius: '4px',
        },
    },
    popBtn: {
        fontSize: '16px !important',
        fontWeight: '500 !important',
        padding: '5px 20px !important',
    },
    popheight: {
        height: '500px',
    },
    white: {
        '& input': {
            backgroundColor: '#fff',
            padding: '11.5px 14px',
            fontSize: '15px !important',
        },
    },
    whiteSel: {
        backgroundColor: '#fff',
        height: '44px !important',
        fontSize: '15px !important',
    },
    TableHeader: {
        margin: '0px',
        color: '#021948',
        padding: '2px 0px 2px 0px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: 'none',
                opacity: '1',
            },
        },
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c !important',
        },
    },
    font14: {
        fontSize: '14px !important',
    },
    dView: {
        background: '#fff !important',
        border: '#8d8d8d 1px solid',
        borderRadius: '3px !important',
        height: '35px !important',
        '& :hover': {
            background: '#fff !important',
            border: '#8d8d8d 1px solid',
            borderRadius: '3px !important',
            height: '22px !important',
        },
        '& :focus': {
            background: '#fff !important',
        },
    },
    deleteBtn: {
        border: '1px solid #B00000 !important',
        color: '#B00000 !important',
        '& svg': {
            color: '#B00000 !important',
        },
    },
    dTextBox: {
        margin: '5px !important',
        background: '#fff',
    },
    tableSec: {
        marginTop: '30px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
    },
    loaderContainer: {
        height: '60vh',
    },
});
export default CoaListStyle;
