import {
    Box, Button, FormControl, MenuItem, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TableHeaderStyle from './TableHeader.style';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';

// interface Props {
// tittle: string,
// handleClickOpen: () => void;
// handleChange: (event: SelectChangeEvent) => void;
// handleClickOpenDuplicate: () => void;
// }

interface MyComponentProps {
    onFilterTextBoxChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function EstimateHeader(props: MyComponentProps) {
    const classes = TableHeaderStyle();
    const [view, setView] = useState('default');
    const { onFilterTextBoxChanged } = props;
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=estimate`);
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    ESTIMATE
                </Typography>
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    {/* <Box className={classes.undoButtn}>
                        <button type="submit">
                            <BarChartOutlinedIcon />
                            {' '}
                            Benchmark
                        </button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    <FormControl variant="filled">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={view}
                            label="view"
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            color: '#787878',
                                        },
                                        '& .Mui-selected': {
                                            color: '#021948',
                                            bgcolor: '#E6ECFA !important',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value="default">Default View</MenuItem>
                            {/* <MenuItem value="list">List View</MenuItem> */}
                        </Select>
                    </FormControl>
                    <Box className={classes.inputBoxStyle}>
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Filter..."
                            onChange={onFilterTextBoxChanged}
                        />
                    </Box>
                </Box>
            </Box>
            {/* <div className="example-header">
                <span>Quick Filter:</span>
                <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={onFilterTextBoxChanged}
                />
            </div> */}
            {/* <Box className={classes.versonUndo}>
                <Box className={`${classes.undoButtn} ${classes.deleteButtonStyle}`}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <ContentCopyIcon />
                        {' '}
                        Duplicate
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit">
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit">
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </button>
                </Box>
                <Box className={classes.undoButtnNew}>
                    <button type="submit" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </button>
                </Box>
            </Box> */}
        </div>
    );
}

export default EstimateHeader;
