import { makeStyles } from '@mui/styles';

const EstimateStyle = makeStyles({
    mainEstmate: {
        padding: '0px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
});

export default EstimateStyle;
