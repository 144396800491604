import React, {
    useState, useContext, useEffect,
} from 'react';
import { Box } from '@mui/material';
import ProjectsMapViewStyle from './ProjectsMap.style';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import projectQuery from '../../../../queries/projects';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import ProjectListType, { ProjectCellRendererType } from '../../../../types/ProjectListType';
import { UserContext } from '../../../../store/context/userContext';
import Loader from '../../../../components/Loader';
import {
    Link, useNavigate, useLocation, useOutletContext,
} from 'react-router-dom';
import { ProjectContext } from '../../../../store/context/projectContext';
import { PATH_DATABASE } from '../../../../Routes/path';
import Pagination from '../../../../components/paginationTable';
import AgGridComponent from '../../../../components/agGridTableComponent';
import MapView from '../../Components/MApView';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';

interface Iprops {
    searchedText?: string;
}

interface LocationState {
    state ?: {
        industryId?: string | undefined;
        regionId?: string | undefined;
    }
}

function ProjectsMapView(props: Iprops) {
    const { searchedText } = props;
    const classes = ProjectsMapViewStyle();
    const [pageSkipValue, setPageSkipValue] = useState(0);

    const userCtx = useContext(UserContext);
    const navigate = useNavigate();
    const projectCtx = useContext(ProjectContext);
    const curLocation:LocationState = useLocation();
    const cont: OutletContext = useOutletContext();

    const organizationId = userCtx?.user?.default_org_id;
    const selectedIndustry = curLocation?.state?.industryId;
    const selectedRegion = curLocation?.state?.regionId;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleLink = (project: ProjectListType) => {
        projectCtx?.setProject(project);
        navigate(PATH_DATABASE.projectDashboard);
    };

    const nameCellRenderer = (params: ProjectCellRendererType) => {
        const { value } = params;
        const colName = params.colDef.field;
        let date = params.data?.base_date;
        date = date && date.toString();
        const formattedDate = date && date.split('-')[0];
        if (colName != null) {
            switch (colName) {
            case 'name':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params.data);
                        }}
                    >
                        {value}

                    </Link>
                );
            case 'base_date':
                return formattedDate;
            case 'client_number':
                if (params.data.heirarchy === 1) {
                    return params.data?.country?.name;
                }
                return value;
            case 'country.name':
                if (params.data.heirarchy === 2) {
                    return value;
                }
                return '';
            default:
                return value;
            }
        }
        return '';
    };

    const COLUMN_DEFS = [
        {
            field: 'client_number', type: 'string', width: 180, headerName: 'Project Number', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'name',
            type: 'string',
            width: 350,
            headerName: 'Name',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'estimate_type', type: 'string', width: 150, headerName: 'Estimate Type',
        },
        {
            field: 'industry.description', type: 'string', width: 180, headerName: 'Industry',
        },
        {
            field: 'subindustry.description', type: 'string', width: 180, headerName: 'Sub-Industry',
        },
        {
            field: 'country.name', type: 'string', width: 180, headerName: 'Country', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'region', type: 'string', width: 180, headerName: 'Region',
        },
        {
            field: 'base_date', type: 'string', width: 150, headerName: 'Base Date', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'consultant', type: 'string', width: 150, headerName: 'Consultant',
        },
        {
            field: 'owner', type: 'string', headerName: 'Owner', width: 200,
        },
    ];

    const { data, isFetching, refetch } = useGQLQuery(
        'GetProject',
        projectQuery.GET_PROJECTS(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            '',
            '',
            userCtx?.user?.default_org_id || '',
            selectedIndustry || '',
            selectedRegion || '',
        ),
        {},
        { onError: handleApiError },
    );

    useEffect(() => {
        refetch();
    }, [pageSkipValue, organizationId]);

    const projectData = data?.project;
    const tableData = projectData?.data;
    const pageInfo = projectData?.pageInfo;
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
    const rowData = [] as Partial<ProjectListType>[];

    const countryList = (tableData && tableData.length)
        ? (tableData.map((item) => item.country_name)
            ?.filter((value, index, array) => array.indexOf(value) === index))
        : [];

    countryList?.forEach((item) => {
        rowData.push({
            country: {
                name: item,
            },
            heirarchy: 1,
            id: '',
        });
        tableData?.forEach((projectdata) => {
            if (projectdata?.country_name === item) {
                rowData.push({
                    ...projectdata,
                    heirarchy: 2,
                });
            }
        });
    });

    const changeSortingValue = (str: string) => {};

    // Fetch new page data
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    return (
        <div>
            <Loader loading={isFetching} />
            <Box className={classes.mapBox}>
                <MapView
                    mapData={rowData}
                />
            </Box>
            <Box className={classes.projectMapWidth}>
                <AgGridComponent
                    columnDefs={COLUMN_DEFS}
                    rowData={rowData}
                    changeSortingValue={changeSortingValue}
                    noSelection
                    isRangeSelectable
                    isGroupable
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    quickFilterText={searchedText}
                    moduleName="ProjectsMapView"
                    disableResizable
                />
            </Box>
            <Box><Pagination totalPages={totalPages} handlePageChange={handlePageChange} /></Box>
        </div>
    );
}

export default ProjectsMapView;
