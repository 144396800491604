import { useContext } from 'react';
import { ProjectContext } from '../store/context/projectContext';
import { CURR_FORMAT } from '../helper/CountryFlag';

export const formatCurr = (value: number, local: string) => {
    if (value?.toString()?.includes('.')) {
        // If it has a fractional part, format with two digits after the decimal point
        return new Intl.NumberFormat(local, { minimumFractionDigits: 2 }).format(+value);
    }
    // If it's an integer, result will be the whole number
    return new Intl.NumberFormat(local).format(+value);
};

// This function multiply the value with exchange rate and adds currency symbol and comma separator
export const useFormatCurrency = () => {
    const projectCtx = useContext(ProjectContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;

    return (value: string) => {
        const currencySymbol = displayCurr?.symbol || '';
        const formattedValue = value && exchangeRate ? (+value) * (+exchangeRate) : value;
        const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
        return `${currencySymbol}${formatCurr(+formattedValue, local)}`;
    };
};

// This function add currency symbol and comma separator to the value passed in
export const useDisplayCurrFormatter = () => {
    const projectCtx = useContext(ProjectContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);

    return (value: number) => {
        const currencySymbol = displayCurr?.symbol || '';
        const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
        return `${currencySymbol}${formatCurr(value, local)}`;
    };
};
