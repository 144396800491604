import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_UNIT_RATE_BY_ORGID = ({ org_id }: { org_id: string }) => gql`
query GetUnitRateDataByOrgId {
    unit_rate(where: {org_id: {_eq: "${org_id}"}}) {
      class
      sequence
      description
      productivity
      unit
    }
  }
`;

const GET_UNIT_RATE_DATA = (skip: number, take: number, sort: string, sortOrder: string) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetUnitRateData {
        unitrate(skip: ${skip}, take: ${take}, ${sortType}) {
          pageInfo {
            skip
            take
            hasNextPage
            totalcount
          } 
          data {
            id
            org_id
            project_id
            coa_id
            generic_coa_id
            productivity
            code
            description
            unit
            unit_man_hours
            unit_labour_cost
            unit_consequip_cost
            unit_material_cost
            unit_equipment_cost
            unit_subcontract_cost
            unit_indirect_cost
            input_rate
            created_at
            updated_at
            labour
            total_cost
            total_unit_cost
            unit_cost {
              sub_code
              sub_description
              quantity
            }
          }
        }
      }
    `;
};

const GET_UNIT_RATE_INFO = (skip: number, take: number, orgId: string, projId: string, verId: string, sort?: boolean) => gql`
    query GetUnitRateInfo {
        unitrate(skip: ${skip}, take: ${take}, filter:{org_id: ${addEscapeCharacters(orgId)}, 
        project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}},
        ${sort ? 'sort: {cost_in_estimate: DESC}' : ''}) {
          pageInfo {
            skip
            take
            hasNextPage
            totalcount
          },
          data {
            id
            coa_code
            generic_coa_id
            productivity
            code
            description
            unit
            unit_man_hours
            unit_labour_cost
            unit_consequip_cost
            unit_material_cost
            unit_equipment_cost
            unit_subcontract_cost
            unit_indirect_cost
            created_at
            updated_at
            labour
            quantity
            total_cost
            total_unit_cost
            total_quantity
            unit_cost
            cost_in_estimate
          }
        }
      }
    `;

// the sort hard coded is as per clients requirement in UAT feed back and should be removed in next phase
// Query For fetching Unit rate data for list view
const GET_UNIT_RATE_LIST_VIEW_DATA = (
    skip: number,
    take: number,
    sort: string,
    sortOrder: string,
    orgId: string,
    projId: string,
    verId: string,
) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetUnitRateListViewData {
      unitrate(filter:{
        org_id: ${addEscapeCharacters(orgId)},
        project_id: ${addEscapeCharacters(projId)},
        version_id: ${addEscapeCharacters(verId)}
      }
      skip: ${skip}, take: ${take}, ${sortType}
      sort: { coa_code: ASC, code: ASC }
      )
      {
        pageInfo {
          skip
          take
          hasNextPage
          totalcount
        }
        data {
          coa_code
          coa_description
          code
          description
          unit
          unit_man_hours
          unit_labour_cost
          labour
          unit_material_cost
          unit_consequip_cost
          lab_rate
          quantity
          cost_in_estimate
          total_unit_cost
          unit_cost
          total_quantity
        }
      }
    }
    `;
};

const GET_UNIT_RATE_DETAIL_DATA = (
    skip: number,
    take: number,
    sort: string,
    sortOrder: string,
    orgId: string,
    projId: string,
    verId: string,
) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
      query GetUnitRateDetailData {
          unitrate(filter: {
            org_id: ${addEscapeCharacters(orgId)}, 
            project_id: ${addEscapeCharacters(projId)},
            version_id: ${addEscapeCharacters(verId)},
          }
          skip: ${skip}, take: ${take}, ${sortType}
          sort: { coa_code: ASC, code: ASC, entity_code: ASC },
          is_detailed_view: true,
          )
          {
            pageInfo {
              skip
              take
              hasNextPage
              totalcount
            } 
            data {
              coa_code
              code
              description
              unit
              labour
              unit_man_hours
              quantity
              unit_cost_data {
                entity_code
                entity_unit
                sub_description
                entity_cost
                sub_description
                entity_cost
                quantity
                total_quantity
                labour_hours
                labour
                material
                equipment
                p_equipment
                subcontract
                total_unit_resource
                entity_description
              }
            }
          }
        }
      `;
};

export default {
    GET_UNIT_RATE_BY_ORGID,
    GET_UNIT_RATE_DATA,
    GET_UNIT_RATE_INFO,
    GET_UNIT_RATE_DETAIL_DATA,
    GET_UNIT_RATE_LIST_VIEW_DATA,
};
