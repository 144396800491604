import React, {
    Fragment, useState, useEffect, useContext,
} from 'react';
import useStyles from './Resources.style';
import ResourcesHeader from '../../components/ResourcesHeader';
import { Box, Button } from '@mui/material';
import resourcesQueries from '../../../../queries/resources';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import Loader from '../../../../components/Loader';
import { CellRendererType } from '../../../../types/AgGridTypes';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { QueryErrorResponse, OutletContext } from '../../../../types/CommoditiesListType';
import { useOutletContext } from 'react-router-dom';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import AgGridGroupComponent from '../../../../components/agGridTableComponent/AgGridGroup';
import { formatCurr, useDisplayCurrFormatter } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';

function Resources() {
    const classes = useStyles();
    const [resourceFilterButtonState, setResourceFilterButtonState] = useState('');
    const [resourceSortingState, setResourceSortingState] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const projectId = projectCtx?.project?.id;
    const formatCurrency = useDisplayCurrFormatter();

    const buttonsArr = [
        { id: 1, name: 'All', value: '' },
        { id: 2, name: 'Labour', value: 'type: "L"' },
        { id: 3, name: 'Construction Equipment', value: 'type: "E"' },
        { id: 4, name: 'Permanent Equipment', value: 'type: "P"' },
        { id: 5, name: 'Materials', value: 'type: "M"' },
        { id: 6, name: 'Sub-Contract', value: 'type: "S"' },
        { id: 7, name: 'Other', value: 'type: "Z"' },
    ];

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const customCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        const month = params?.data?.date?.toString().split('-')[1];
        const day = params?.data?.date?.toString().split('-')[2];
        const year = params?.data?.date?.toString().split('-')[0];
        const formattedDate = `${month}/${day}/${year}`;
        switch (colName) {
        case 'cost_per_unit':
            return params.value ? `${formatCurrency(params.value)}` : '';
        case 'total_cost':
            return params.value ? `${formatCurrency(params.value)}` : '';
        case 'escalation_factor':
            return params?.value?.toString() ? `${params.value}%` : '';
        case 'date':
            return params.value ? formattedDate : '';
        case 'cost_input':
            return params.value ? formatCurr(params.value, local) : '';
        case 'total_quantity':
            return params.value ? formatCurr(params.value, local) : '';
        default:
            return params?.value;
        }
    };

    const changeSortingValue = (par: string) => {
        if (resourceSortingState === par || resourceSortingState === '') {
            if (sortOrder === 'DESC') {
                setSortOrder('ASC');
            } else {
                setSortOrder('DESC');
            }
        }
        setResourceSortingState(par);
    };

    const columnDefs = [
        {
            field: 'description', width: 300, headerName: 'Description',
        },
        {
            field: 'unit', width: 150, headerName: 'UoM',
        },
        {
            field: 'cost_input',
            width: 190,
            headerName: 'Input Rate',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'currency', width: 150, headerName: 'Currency',
        },
        {
            field: 'date', width: 130, headerName: 'Date', cellRenderer: customCellRenderer,
        },
        {
            field: 'escalation_factor',
            width: 150,
            headerName: 'Escalation',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'cost_per_unit', width: 150, headerName: 'Rate', cellRenderer: customCellRenderer, type: 'numericColumn',
        },
        {
            field: 'unit_rate',
            width: 150,
            headerName: 'Unit Rate',
        },
        {
            field: 'total_quantity',
            width: 150,
            headerName: 'Total Qty',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'total_cost',
            width: 150,
            headerName: 'Total Cost',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
    ];
    const cont: OutletContext = useOutletContext();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        `GetResourceInfo-${projectId || 0}`,
        resourcesQueries.GET_RESOURCES_INFO(
            resourceFilterButtonState,
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const resourcesData = data?.resource;
    let tableData = resourcesData?.data;
    tableData = tableData?.map((resource) => {
        const resourcedata = {
            ...resource,
        };
        if (resource?.type === 'E') {
            resourcedata.typeName = 'Equipment Hourly Rates';
        } else if (resource?.type === 'L') {
            resourcedata.typeName = 'Labour Rates';
        } else if (resource?.type === 'M') {
            resourcedata.typeName = 'Material Rates';
        } else if (resource?.type === 'P') {
            resourcedata.typeName = 'Pre Equipment Rates';
        } else if (resource?.type === 'Z') {
            resourcedata.typeName = 'Indirect Cost';
        } else if (resource?.type === 'S') {
            resourcedata.typeName = 'Sub Contract Rates';
        }
        return resourcedata;
    });
    const pageInfo = resourcesData?.pageInfo;

    const filterHandler = (param: string) => {
        setResourceFilterButtonState(param);
        setPageSkipValue(0);
    };

    useEffect(() => {
        refetch();
    }, [resourceFilterButtonState, resourceSortingState, sortOrder, pageSkipValue]);

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const rowData = tableData ? tableData.map((item) => item) : [];
    const types = rowData.map((item) => item.typeName);
    const uTypes = types.filter((item, element) => types.indexOf(item) === element);

    const temp: any[] = [];

    uTypes.forEach((parent) => {
        temp.push({
            typeName: parent,
            heirarchy: [parent],
        });
        const children = rowData.filter((item) => item.typeName === parent);
        children.forEach((child) => {
            temp.push({
                heirarchy: [parent, child.code],
                ...child,
                cost_input: child.cost_input.toFixed(2),
                total_quantity: child.total_quantity.toFixed(2),
            });
        });
    });
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    return (
        <Fragment>
            <ResourcesHeader />
            <Box>
                <Box className={classes.resourceBody}>
                    <Box className={classes.selectionSec}>
                        {buttonsArr.map((item) => (
                            <Box key={item.id} className={item.value === resourceFilterButtonState ? classes.newButton : classes.outlineButton}>
                                <Button onClick={() => filterHandler(item.value)} disabled={isFetching}>
                                    {item.name}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box className={`${isFetching ? classes.opacityBlur : ''} ${classes.tableSec}`}>
                    <AgGridGroupComponent
                        parentColumn="Code"
                        columnDefs={columnDefs}
                        rowData={temp}
                        sortOrder={sortOrder}
                        changeSortingValue={changeSortingValue}
                        isExportEnabled
                        isClipboardEnabled
                        isToolPanelsEnabled
                        isStatusBarEnabled
                        moduleName="Resources"
                        rowSelection="multiple"
                        isFetching={isFetching}
                        colFormat={['cost_per_unit', 'total_cost']}
                    />
                    <Loader loading={isFetching} />
                </Box>
                <Box>
                    <Pagination totalPages={totalPages} handlePageChange={handlePageChange} view={resourceFilterButtonState} />
                </Box>
            </Box>
        </Fragment>
    );
}

export default Resources;
