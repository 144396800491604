import React, { useContext, useEffect, useState } from 'react';
import useStyles from './ReportsHeader.style';
import {
    Box, FormControl, MenuItem, Typography, Button, Select, SelectChangeEvent, InputLabel,
} from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveNewReportModel from '../SaveNewReportModel';
import NewReportPopup from '../NewReportPopup';
import { ReportType } from '../../../../types/Reports';
import DeleteReportPopup from '../DeleteReportConfirmationPopup';
import reportQuery from '../../../../queries/report';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { UserContext } from '../../../../store/context/userContext';
import { PATH_CMFR, PATH_DATABASE } from '../../../../Routes/path';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useUserRole } from '../../../../hooks/useUserRole';
import { USER_ROLE } from '../../../../constants';

interface Props {
    reportListUpdate: (isEditMode: boolean) => void,
    selectedReport: ReportType | null,
    reportDeleted: () => void,
    updateEditedReport: (report: ReportType) => void,
    selectedModule: string,
    setSelectedModule: (mod: string) => void,
    onReportExport: () => Promise<void>,
}

const ORGANISATION_MODULES = ['dashboard', 'industries', 'commodities', 'projects'];
const PROJECT_MODULES = ['project dashboard', 'wbs', 'coa', 'packages', 'estimate', 'unit rate', 'resources', 'schedule', 'project settings'];

const MODULE_FILTER_ORGANISATIONS = [
    { label: 'All', value: 'all' },
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Industries', value: 'industries' },
    { label: 'Commodities', value: 'commodities' },
    { label: 'Projects', value: 'projects' },
];

const PROJECT_MODULE_FILTER_ORGANISATIONS = [
    { label: 'All', value: 'all' },
    { label: 'Project Dashboard', value: 'project dashboard' },
    { label: 'WBS', value: 'wbs' },
    { label: 'COA', value: 'coa' },
    { label: 'Packages', value: 'packages' },
    { label: 'Estimate', value: 'estimate' },
    { label: 'Unit Rate', value: 'unit rate' },
    { label: 'Resources', value: 'resources' },
    { label: 'Schedule', value: 'schedule' },
    { label: 'Project Settings', value: 'project settings' },
];

function ReportsHeader({
    reportListUpdate,
    selectedReport,
    reportDeleted, updateEditedReport, selectedModule, setSelectedModule, onReportExport,
}: Props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openReportPopup, setOpenReportPopup] = useState(false);
    const [reportDetails, setReportDetails] = useState<ReportType | null>(null);
    const [deleteReport, setDeleteReport] = useState(false);
    const cont: OutletContext = useOutletContext();
    const [isEditMode, setEditMode] = useState(false);
    const [isDuplicateMode, setIsDuplicateMode] = useState(false);
    const userCtx = useContext(UserContext);
    const orgID = userCtx?.user?.default_org_id;
    // Case when reports page is opened from some module
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search);
    const moduleFilter = query.get('module');
    const userOrgRole = useUserRole();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleApiSuccess = () => {
        cont.showNotificationBar('Report deleted successfully.', 'success');
        reportListUpdate(isEditMode);
        reportDeleted();
        setDeleteReport(false);
    };

    const { mutate, data, isLoading } = useGQLMutation(
        'DeleteReport',
        reportQuery.DELETE_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const handleNewClick = () => {
        setEditMode(false);
        setOpen(true);
    };

    const handleModuleChange = (event: SelectChangeEvent) => {
        const { value } = event.target;
        if (value === 'all') {
            setSelectedModule('all');
        } else {
            setSelectedModule(value);
        }
    };

    const deleteReportAction = () => {
        mutate({
            org_id: orgID,
            id: selectedReport?.id,
        });
    };

    const openReportEditPopup = () => {
        setEditMode(true);
        setOpen(true);
    };

    const openDuplicateReportPopup = () => {
        setIsDuplicateMode(true);
        setOpen(true);
    };

    const saveAsPdf = () => {
        onReportExport()
            .then(() => {})
            .catch((err) => console.log(err));
    };

    const filterMenuItems = pathname.includes(PATH_DATABASE.reports) ? MODULE_FILTER_ORGANISATIONS : PROJECT_MODULE_FILTER_ORGANISATIONS;

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    REPORTS
                </Typography>
            </Box>
            <Box className={classes.rightSection}>
                { selectedReport && selectedReport.id ? (
                    <>
                        <Box className={classes.outlineButton}>
                            <Button onClick={saveAsPdf}>
                                <CloudDownloadOutlinedIcon />
                                {' '}
                                Save as Pdf
                            </Button>
                        </Box>
                        { userOrgRole === USER_ROLE ? null : (
                            <>
                                <Box className={classes.deleteButton}>
                                    <Button onClick={() => setDeleteReport(true)}>
                                        <DeleteOutlineOutlinedIcon />
                                        {' '}
                                        Delete
                                    </Button>
                                </Box>
                                <Box className={classes.outlineButton}>
                                    <Button onClick={openReportEditPopup}>
                                        <EditOutlinedIcon />
                                        {' '}
                                        Edit
                                    </Button>
                                </Box>
                                <Box className={classes.outlineButton}>
                                    <Button onClick={openDuplicateReportPopup}>
                                        <ContentCopyIcon />
                                        {' '}
                                        Duplicate
                                    </Button>
                                </Box>
                            </>
                        ) }
                    </>
                ) : null}
                <Box sx={{ minWidth: 150 }} className={classes.selectMenu}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Module</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedModule}
                            label="Module"
                            onChange={handleModuleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            color: '#787878',
                                        },
                                        '& .Mui-selected': {
                                            color: '#021948',
                                            bgcolor: '#E6ECFA !important',
                                        },
                                    },
                                },
                            }}
                            placeholder="Module"
                            // defaultValue='Module'
                            // disabled={!!(moduleFilter && isValidModule(pathname, moduleFilter))}
                        >
                            { filterMenuItems.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>) }
                        </Select>
                    </FormControl>
                </Box>
                {userOrgRole === USER_ROLE ? null : (
                    <Box className={classes.newButton}>
                        <Button variant="contained" onClick={handleNewClick}>
                            <AddIcon />
                            {' '}
                            New
                        </Button>
                    </Box>
                )}
                {open && (
                    <SaveNewReportModel
                        open={open}
                        setOpen={setOpen}
                        setOpenReportPopup={setOpenReportPopup}
                        setReportDetails={setReportDetails}
                        isEditMode={isEditMode}
                        selectedReport={selectedReport}
                        isDuplicateMode={isDuplicateMode}
                        reportListUpdate={reportListUpdate}
                        setEditMode={setEditMode}
                        setIsDuplicateMode={setIsDuplicateMode}
                    />
                )}
                {openReportPopup && (
                    <NewReportPopup
                        open={openReportPopup}
                        setOpen={() => setOpenReportPopup(false)}
                        name={reportDetails?.display_name || ''}
                        module={isEditMode ? selectedReport?.module_name || '' : reportDetails?.module_name || ''}
                        reportListUpdate={reportListUpdate}
                        isEditMode={isEditMode}
                        selectedReport={isEditMode ? selectedReport : null}
                        updateEditedReport={updateEditedReport}
                    />
                )}
                <DeleteReportPopup
                    title="report"
                    open={deleteReport}
                    setOpenDelRow={setDeleteReport}
                    deleteAction={deleteReportAction}
                    loading={isLoading}
                    reportName={selectedReport?.display_name}
                />
            </Box>
        </div>
    );
}

export default ReportsHeader;
