import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_INDUSTRIES = (orgId: string) => gql`
query GetIndustries  {
  user_industries(org_id: ${addEscapeCharacters(orgId)}) {
    id
    code
    description
    project_count
    total_cost
    level
    areProjectsExcluded
  }
}
`;

const GET_ALL_INDUSTRY = (orgId: string) => gql`
query {
  all_industries(org_id: ${addEscapeCharacters(orgId)}) {
    data {
      id
      code
      description
      project_count
      total_cost
      level
      attr_label
      unit
      projects {
        id
        project_number
        estimate_type
        name
        region
        base_date
        owner
        country_name
        total_cost
        client_number
        unit
        capacity
      }
    }
    pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
  }
}`;

export {
    GET_INDUSTRIES, GET_ALL_INDUSTRY,
};
