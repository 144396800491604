import React, { useState } from 'react';
import useStyles from './IndustriesHeader.style';
import {
    Box, Button, Typography, FormControl, MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { PATH_DATABASE } from '../../../../Routes/path';
import OrgCostHeaderBar from '../../../../components/OrgCostHeaderBar';

function IndustriesHeader() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState('list');
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_DATABASE.reports}?module=industries`);
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    INDUSTRIES
                </Typography>
                <OrgCostHeaderBar />
                <Box className={classes.rightSection}>
                    {/* <Box className={classes.outlineButton}>
                        <Button>
                            <BarChartIcon />
                            {' '}
                            Benchmark
                        </Button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    {/* <FormControl variant="filled">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={view}
                            label="view"
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            color: '#787878',
                                        },
                                        '& .Mui-selected': {
                                            color: '#021948',
                                            bgcolor: '#E6ECFA !important',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value="detail">Detail View</MenuItem>
                            <MenuItem value="list">List View</MenuItem>
                        </Select>
                    </FormControl> */}
                </Box>
            </Box>
            <Box className={classes.rightSection}>
                {/* <Box className={classes.outlineButton}>
                    <Button disabled>
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box> */}
                {/* <Box className={classes.deleteButton}>
                    <Button>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </Button>
                </Box> */}
                {/* <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </Button>
                </Box> */}
                {/* {open && <AddEditResources open={open} setOpen={setOpen} />} */}
            </Box>
        </div>
    );
}

export default IndustriesHeader;
