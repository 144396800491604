import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_COA_EXTENDED_INFO = (skip: number, take: number, sort: string, sortOrder: string) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetCOAExtendedInfo {
      coa(skip: ${skip}, take: ${take}, ${sortType}) {
        pageInfo {
          skip
          take
          hasNextPage
          totalcount
        },
        data {
          coa
          description
          labour_hours
          labour
          cons_equipment
          material
          subcontract
          equipment
          indirect
          total_cost
        }
      }
    }
  `;
};

const GET_COA_INFO = (skip: number, take: number, sort: string, sortOrder: string, orgId: string, projId: string, verId: string) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
  query GetCOAInfo {
    coa(skip: ${skip}, take: ${take}, ${sortType} , filter:{org_id: ${addEscapeCharacters(orgId)}, 
    project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}}) {
      pageInfo {
        skip
        take
        hasNextPage
        totalcount
      },
      data {
        id
        required
        code1
        code2
        code3
        code4
        code5
        code6
        description
        detailed_description
        class
        prod_factor
        created_at
        updated_at
        labour_hours
        labour
        cons_equipment
        material
        subcontract
        equipment
        total_cost
        indirect
        code
        level
      },
      total_values {
        labour_hoursTotal
        labourTotal
        c_equipmentTotal
        materialTotal
        subcontractTotal
        equipmentTotal
        indirectTotal
        total_costTotal
      }  
    }
  }
  `;
};

const GET_COA_PHASE = (skip: number, take: number, sort: string, sortOrder: string, orgId: string, projId: string, verId: string) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
  query GetCOAPhase {
    coaphase(skip: ${skip}, take: ${take}, ${sortType} , filter:{org_id: ${addEscapeCharacters(orgId)}, 
    project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}}) {
      pageInfo {
        skip
        take
        totalcount
        hasNextPage
      }
      data
      total_values 
    }
  }
  `;
};

const GET_COA_CASE = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
query GetCOACase {
  coacase(skip: ${skip}, take: ${take}, filter:{org_id: ${addEscapeCharacters(orgId)}, 
  project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}}) {
    pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
    data
    total_values 
  }
}
`;

const GET_COA_VERSION = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
query GetCOAVersion {
  coaversion(skip: ${skip}, take: ${take}, filter:{org_id: ${addEscapeCharacters(orgId)}, 
  project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}}) {
    pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
    data
    total_values 
  }
}
`;

export default {
    GET_COA_EXTENDED_INFO,
    GET_COA_INFO,
    GET_COA_PHASE,
    GET_COA_CASE,
    GET_COA_VERSION,
};
