import React, { useContext, useEffect } from 'react';
import TotalProjectsBarStyle from './TotalProjectsBar.style';
import { Box } from '@mui/material';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import { UserContext } from '../../store/context/userContext';
import TOTAL_PROJRCTS_COUNT from '../../queries/totalProjects';

interface IProps {
    view?: string,
    selectedIndustry?: string,
    selectedCountry?: string,
    totalProjectCount?: number,
}

function TotalProjectsBar(props: IProps) {
    const {
        view, selectedIndustry, selectedCountry, totalProjectCount,
    } = props;
    const classes = TotalProjectsBarStyle();
    const userCtx = useContext(UserContext);

    const { data, refetch } = useGQLQuery(
        'projectTotalCount',
        TOTAL_PROJRCTS_COUNT(
            userCtx?.user?.default_org_id || '',
            selectedIndustry || '',
            selectedCountry || '',
        ),
    );

    useEffect(() => {
        refetch();
    }, [view, totalProjectCount]);

    const count = data?.projectTotalCount;

    return (
        <Box className={classes.chartStyle}>
            {view === 'mapview' ? (
                <>
                    <span>Total Projects - </span>
                    &nbsp;
                    <span>{count?.project_count}</span>
                    &nbsp;&nbsp;
                    <span>Total Countries - </span>
                    &nbsp;
                    <span>{count?.project_country_count}</span>
                </>
            ) : (
                <>
                    <span>Total</span>
                    &nbsp;&nbsp;
                    {count?.project_count
                        ? <span>{`${count?.project_count} Projects`}</span>
                        : <span>0 Projects</span>}
                </>
            )}
        </Box>
    );
}

export default TotalProjectsBar;
