import { Box, Typography, Button } from '@mui/material';
import DashboardStyle from './Dashboard.style';
import unavailable from '../../../../assets/unavailable.svg';
import React, {
    useContext, useState, useCallback, useEffect,
} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SearchIcon from '@mui/icons-material/Search';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Pagination from '../../../../components/paginationTable';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import projectRecentQuery from '../../../../queries/DashboardRecentProject';
import { GET_REGION } from '../../../../queries/region';
import { GET_INDUSTRIES } from '../../../../queries/industries';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import IndustriesType from '../../../../types/IndustriesType';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { PATH_DATABASE } from '../../../../Routes/path';
import Loader from '../../../../components/Loader';
import RegionMap from '../../Components/RegionMap';
import { FLAGS_LIST } from '../../../../helper/CountryFlag';
import TotalProjectsBar from '../../../../components/totalProjectsBar';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import ProjectListType, { ProjectCellRendererType } from '../../../../types/ProjectListType';
import DefaultCurrencyImage from '../../../../assets/flags/Default.svg';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Toolti from '@mui/material/Tooltip';
import { formatCurr } from '../../../../hooks/useFormatCurrency';

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        datalabels: {
            anchor: 'end' as const,
            align: 'end' as const,
            color: 'black',
        },
    },
    scales: {
        x: {
            ticks: {
                color: '#000',
            },
            title: {
                display: true,
                text: 'Industries',
                font: {
                    size: 15,
                    family: 'Inter',
                },
            },
            grid: {
                display: false,
            },
        },
        y: {
            ticks: {
                color: '#000',
            },
            title: {
                display: true,
                text: 'Projects',
                font: {
                    size: 15,
                    family: 'Inter',
                },
            },
            grid: {
                display: false,
            },
        },
    },
};

interface RegionRendererType {
    length: number;
    colDef: {
        field: string
    }
    value: string,
    data: {
        region: string,
        country: string,
        project_count?: number,
        total_cost: number,
        is_country?: boolean,
        currency_data?: { symbol: string },
        country_id: string,
        latitude: string | null,
        longitude: string | null,
        areProjectsExcluded?: boolean | null,
    }
}

function FlagCellRenderer(params: RegionRendererType) {
    const { data } = params;
    let flag;
    if (data.is_country) {
        if (data.country && FLAGS_LIST[data.country]) {
            flag = <img src={FLAGS_LIST[data.country]} alt="flag" />;
        } else {
            flag = <img src={DefaultCurrencyImage} alt="flag" />;
        }
    }
    return flag;
}

function Dashboard() {
    const classes = DashboardStyle();
    // input below value from user context
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [search, setSearch] = useState('');
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const [show, setShow] = useState(!!userCtx?.user?.default_org_id);
    const navigate = useNavigate();
    const cont: OutletContext = useOutletContext();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleLink = (project: ProjectListType) => {
        projectCtx?.setProject(project);
        navigate(PATH_DATABASE.projectDashboard);
    };

    const regionRender = (params: RegionRendererType) => {
        if (params.data.is_country) {
            return (
                <Link to="/projects" state={{ regionId: params.data.country_id }}>{params.value.toLocaleString()}</Link>
            );
        }
        return (
            <span>
                &emsp;
                {params.value.toLocaleString()}
            </span>
        );
    };

    const totalCostCellRender = (params: RegionRendererType) => {
        if (params.data.areProjectsExcluded) {
            return (
                <span className={classes.warningIcn}>
                    <Toolti title="1 or more projects are excluded from total">
                        <WarningAmberIcon style={{ width: '16px', color: '#ffe41e' }} />
                    </Toolti>
                    &emsp;
                    {params?.data?.currency_data?.symbol || '$'}
                    {formatCurr(+(+params.value).toFixed(0), 'en-US')}
                </span>
            );
        }
        return (
            <span>
                &emsp;
                {params?.data?.currency_data?.symbol || '$'}
                {formatCurr(+(+params.value).toFixed(0), 'en-US')}
            </span>
        );
    };

    const nameCellRenderer = (params: (ProjectCellRendererType)) => {
        const { value } = params;
        const colName = params.colDef.field;
        const mydate = (params.value)?.toString();
        const year = mydate && mydate.split('-')[0];
        const formattedDate = `${year}`;
        if (params?.value != null) {
            switch (colName) {
            case 'name':
                return (
                    <Link
                        to="/projectDashboard"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params.data);
                        }}
                    >
                        {value}
                    </Link>
                );
            case 'total_cost':
                return `${params?.data?.currency_data?.symbol || '$'}${formatCurr(+(+params.value).toFixed(0), 'en-US')}`;
            case 'base_date':
                return `${formattedDate}`;
            case 'total_value':
                return `$${params.value.toLocaleString()}`;
            case 'subindustry.description':
                return params.value ? `${params.value.toLocaleString()}` : '-';
            case 'description':
                return (
                    <Link
                        to="/projects"
                        state={{
                            industryId: params.data.id,
                        }}
                    >
                        {params.value.toLocaleString()}
                    </Link>
                );
            case 'version_code':
            case 'currency_data.code':
            case 'owner':
            case 'consultant':
            case 'industry.description':
            case 'estimate_type':
            case 'country.name':
            case 'client_number':
                return params.value.toLocaleString();
            default:
                return params?.value;
            }
        } else if (params?.value === null) {
            return '-';
        }
        return '';
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetRecentProject',
        projectRecentQuery.GET_RECENT_PROJECTS(pageSkipValue, DEFAULT_PAGE_SIZE, search, userCtx?.user?.default_org_id || ''),
        {},
        {
            enabled: show,
            onError: handleApiError,
        },
    );

    const { data: regionData, isFetching: isRegiontFetching, refetch: regionRefetch } = useGQLQuery(
        'GetRegion',
        GET_REGION(userCtx?.user?.default_org_id || ''),
        {},
        {
            enabled: show,
            onError: handleApiError,
        },
    );

    const { data: barChartData, isFetching: isBarChartFetching, refetch: barChartRefetch } = useGQLQuery(
        'GetIndustries',
        GET_INDUSTRIES(
            userCtx?.user?.default_org_id || '',
        ),
        {},
        {
            enabled: show,
            onError: handleApiError,
        },
    );

    useEffect(() => {
        if (show) {
            refetch();
            regionRefetch();
            barChartRefetch();
        }
    }, [userCtx?.user?.default_org_id]);

    useEffect(() => {
        if (show) {
            refetch();
        }
    }, [pageSkipValue]);

    const columnDefs = [
        {
            field: 'client_number', type: 'string', width: 180, headerName: 'Project Number', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'name',
            type: 'string',
            width: 350,
            headerName: 'Name',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'estimate_type', type: 'string', width: 150, headerName: 'Estimate Type', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'industry.description', type: 'string', width: 180, headerName: 'Industry', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'subindustry.description', type: 'string', width: 180, headerName: 'Sub-Industry', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'country.name', type: 'string', width: 180, headerName: 'Country', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'region', type: 'string', width: 180, headerName: 'Region', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'base_date', type: 'string', width: 150, headerName: 'Base Date', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'consultant', type: 'string', width: 150, headerName: 'Consultant', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'owner', type: 'string', width: 200, headerName: 'Owner', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 150, headerName: 'Total Cost', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'currency_data.code', type: 'string', width: 150, headerName: 'Currency', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'version_code', type: 'number', width: 150, headerName: 'Version', cellRendererFramework: nameCellRenderer,
        },
    ];

    const columnDefsForIndustries = [
        {
            field: 'code', width: 130, headerName: 'ID',
        },
        {
            field: 'description',
            type: 'string',
            width: 250,
            headerName: 'Description',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'project_count', type: 'string', width: 130, headerName: 'Projects',
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 180, headerName: 'Value', cellRendererFramework: totalCostCellRender,
        },
    ];

    const columnDefsForRigeon = [
        {
            field: 'flag',
            // type: 'string',
            width: 120,
            maxWidth: 120,
            headerName: '',
            cellRendererFramework: FlagCellRenderer,
        },
        {
            field: 'region',
            type: 'string',
            width: 180,
            headerName: 'Region',
            cellRendererFramework: regionRender,
        },
        {
            field: 'project_count', type: 'string', width: 130, headerName: 'Projects',
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 180, headerName: 'Value', cellRendererFramework: totalCostCellRender,
        },
    ];

    const handlePageChange = (page: number) => {
        // this function handle the page change
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        // this function handle search filter
        setSearch(e.target.value);
    };

    const changeSortingValue = (str: string) => { };

    const industriesData: IndustriesType[] | undefined = barChartData?.user_industries;
    const barGraphIndustrisData = industriesData?.map((item) => item.description);
    // const barGraphProjectCount = industriesData?.map((item) => item.project_count);
    const barGraphProjectCount = industriesData?.map((item) => `${Math.round(item.project_count).toString()}`);
    const regionTableData = regionData?.regions ? regionData?.regions.map((item) => item) : [];

    const projectData = data?.project;
    const tableData = projectData?.data;
    const pageInfo = projectData?.pageInfo;

    const industriesTableData = industriesData ? industriesData.map((item) => item) : [];
    const rowData = tableData ? tableData.map((item) => item) : [];
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    const datas = {
        labels: barGraphIndustrisData,
        datasets: [
            {
                label: 'Projects',
                data: barGraphProjectCount,
                backgroundColor: '#C1D6FF',
                barPercentage: 0.2,
                barThickness: 33,
            },
        ],
    };

    const navigateToReports = () => {
        navigate(`${PATH_DATABASE.reports}?module=dashboard`);
    };

    return (
        <div>
            <Loader loading={isFetching || isBarChartFetching || isRegiontFetching} />
            {show ? (
                <Box>
                    <Box>
                        <Box className={classes.inTableHeader}>
                            <Typography variant="h2">
                                DASHBOARD
                            </Typography>
                            <TotalProjectsBar />
                            <Box className={classes.versonUndo}>
                                <Box className={classes.outlineButton}>
                                    <Button onClick={navigateToReports}>
                                        <DataSaverOffOutlinedIcon />
                                        {' '}
                                        Reports
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.industryRegion}>
                            <Box className={classes.industryRegionWidth}>
                                <Typography variant="h2">
                                    Industries
                                </Typography>
                                {industriesTableData && industriesTableData.length
                                    ? (
                                        <Box className={classes.inBoxGraph}>
                                            <div className={classes.testCon}>
                                                <div className={barGraphProjectCount !== undefined && barGraphProjectCount.length > 10
                                                    ? classes.testContainer2 : classes.testContainer}
                                                >
                                                    <Bar options={options} data={datas} />
                                                </div>
                                            </div>
                                        </Box>
                                    )
                                    : (
                                        <Box className={classes.containerElement}>
                                            No data to show
                                        </Box>
                                    )}
                            </Box>
                            <Box className={classes.industryRegionWidth}>
                                <Typography variant="h2">
                                    Regions
                                </Typography>
                                <Box className={classes.inBoxMap}>
                                    <RegionMap
                                        mapData={regionTableData}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.industryRegion}>
                            <Box className={classes.industryRegionWidth}>
                                <AgGridComponent
                                    moduleName="Industry"
                                    columnDefs={columnDefsForIndustries}
                                    rowData={industriesTableData}
                                    noSelection
                                    changeSortingValue={changeSortingValue}
                                    isRangeSelectable
                                    isClipboardEnabled
                                    isExportEnabled
                                />
                            </Box>

                            <Box className={classes.industryRegionWidth}>
                                <AgGridComponent
                                    moduleName="Region"
                                    columnDefs={columnDefsForRigeon}
                                    noSelection
                                    rowData={regionTableData}
                                    changeSortingValue={changeSortingValue}
                                    isRangeSelectable
                                    isExportEnabled
                                    isClipboardEnabled
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Box className={`${classes.industryRegion} ${classes.recentProects}`}>
                                <Box>
                                    <Typography variant="h2">
                                        Recent Projects
                                    </Typography>
                                </Box>
                                <Box className={classes.inputBoxStyle}>
                                    <input type="text" placeholder="Search" value={search} onChange={handleSearch} />
                                    <span><SearchIcon /></span>
                                </Box>
                            </Box>
                            <AgGridComponent
                                moduleName="RecentProjectsList"
                                columnDefs={columnDefs}
                                rowData={rowData}
                                changeSortingValue={changeSortingValue}
                                isRangeSelectable
                                isExportEnabled
                                isClipboardEnabled
                                quickFilterText={search}
                                disableResizable
                            />
                        </Box>
                        <Box><Pagination totalPages={totalPages} handlePageChange={handlePageChange} /></Box>
                    </Box>
                </Box>
            )
                : (
                    <Box className={classes.mainUnavailbleStyle}>
                        <Box className={classes.unavailbleStyle}>
                            <img src={unavailable} alt="unavailable" />
                            <Typography variant="h1">
                                Page Unavailable
                            </Typography>
                            <Typography variant="body2">
                                Currently, there are no options to display as
                                this space will be populated with various customisation options once
                                {' '}
                                <br />
                                {' '}
                                you create a project.
                            </Typography>
                        </Box>
                    </Box>
                )}
        </div>
    );
}

export default Dashboard;
