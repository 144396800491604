import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    projectListMain: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
    },
    opacityBlur: {
        opacity: '50%',
    },
}));
