import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    tableSec: {
        marginTop: '30px',
        '& .ag-details-row': {
            '& .ag-theme-alpine': {
                '&:nth-child(1)': {
                    height: '100% !important',
                },
            },
        },
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
        '& .ag-details-grid': {
            height: '100% !important',
        },
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
    tableDataBold: {
        fontFamily: 'Inter-Medium',
        fontWeight: '600',
    },
}));
