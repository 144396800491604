import { gql } from 'graphql-request';

const GET_COMMODITIES_BY_ORGID = (
    skip: number,
    take: number,
    sort: string,
    sortOrder: string,
) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetCommoditiesDataByOrgId {
        commodities(skip: ${skip}, take: ${take}, ${sortType}) {
          pageInfo {
          skip
          take
          hasNextPage
          totalcount
        }
        data {
            id
            code
            description
            unit
            total_cost
            level
            attr_label
            commodities_count
        }
    }
    }`;
};

const GET_COMMODITIES_WITH_PROJECTS_BY_ORGID = (
    org_id: string,
    skip: number,
    take: number,
) => gql`
    query GetCommoditiesWithProjectsDataByOrgId {
        commodities_detail(org_id: "${org_id}", skip: ${skip}, take: ${take}) {
          pageInfo {
          skip
          take
          hasNextPage
          totalcount
        }
        data {
            id
            code
            description
            unit
            total_cost
            level
            attr_label
            commodities_count
            projects{
                project_number
                client_number
                name
                estimate_type
                country_name
                region
                base_date
                owner
                total_cost
            }
        }
    }
    }`;

export default {
    GET_COMMODITIES_BY_ORGID,
    GET_COMMODITIES_WITH_PROJECTS_BY_ORGID,
};
