import { gql } from 'graphql-request';

const GET_ORGANISATIONS = gql`
  query { org {
      name
      id
      code
    }
  }
`;

const GET_ORGANISATIONS_USERS = () => gql`
    query GetOrganizationUsers($orgId: String!){
      getorguser(orgId: $orgId){
        role
        user{
          id
          user_name
          email
          auth_id
          created_at
          updated_at
        }
      }
    }
  `;
const GET_ATTRIBUTES_USERS = (variable:string | undefined) => gql`
  query{
  getorgattribute(orgId:"${variable}"){
    id
    org_id
    label
    type
    org{
      id
      org_name
      org_email
    }
  }
}
`;

const GET_ORGANISATIONS_USER_PROJECTS = (skip: number, take: number, orgId: string | undefined, userId: string | undefined) => gql`
query {
  getorganduserprojects(skip: ${skip}, take: ${take}, org_id: "${orgId}",  user_id: "${userId}") {
    data {
      id
      country_name
      region
      name
      owner
      consultant
      total_cost
      base_date
      permission_id
      version_code
      currency_data {
        id
        code
        exchange_rate
        symbol
      }
    }
    pageInfo {
      skip
      take
      hasNextPage
      totalcount
    }
    error
    error_code
  }
}
`;

const GET_UNIT_MEASURE = (variable:string | undefined) => gql`
  query{
    getorgunitmeasure(orgId: "${variable}"){
      id
      org_id
      name
      abbreviation
      conversion
      system
      type
      conversion
      org{
        id 
        org_name
        org_email
      }
    }
  }
`;

const GET_ORGANISATION_LIST = (skip: number, take: number) => gql`
  query {
    getOrganizationList(skip: ${skip}, take: ${take}) {
      data {
        id
        name
        project_count
        updated_at
        user_count
        data_size
      }
      pageInfo {
        skip
        take
        totalcount
        hasNextPage
      }
      error
      error_code
    }
  }
`;

const INSERT_ORGANISATION = gql`
mutation InsertOrganisation($name: String!, $code: String!) {
    insert_org(objects: {name: $name, code: $code}) {
      affected_rows
    }
  }
`;

const CREATE_ORGANISATION = (name: string, display_name: string, email: string) => gql`
mutation {
  createOrganization(input: { name: "${name}", display_name: "${display_name}", email: "${email}" }) {
    id
    name
    display_name
    error
    error_code
  }
}
`;

const SWITCH_ORGANISATION = (org_id: string) => gql`
mutation {
    switchOrg(input: { org_id: ${org_id} }) {
      default_org_id
      error
      error_code
    }
  }
`;

const DELETE_ORGANISATION = (org_id: string) => gql`
mutation {
  deleteOrg(input: { org_id: ${org_id} }) {
    error
    error_code
  }
}
`;

const DEACTIVATE_USER_ORGANISATIONS_SETTINGS = (org_id:string, user_id:string) => (
    gql`
      mutation {
        deactivateUser(
          input: {
            org_id: "${org_id}",
            user_id: "${user_id}",
          }
        ) {
          message
          error
          error_code
        }
      }
      `
);

export default {
    GET_ORGANISATIONS,
    INSERT_ORGANISATION,
    SWITCH_ORGANISATION,
    GET_ORGANISATION_LIST,
    GET_ORGANISATIONS_USER_PROJECTS,
    CREATE_ORGANISATION,
    DELETE_ORGANISATION,
    GET_ORGANISATIONS_USERS,
    GET_ATTRIBUTES_USERS,
    GET_UNIT_MEASURE,
    DEACTIVATE_USER_ORGANISATIONS_SETTINGS,
};
