import { makeStyles } from '@mui/styles';

const IndustriesStyle = makeStyles(() => ({
    industryTable: {
        fontWeight: '600 !important',
        color: 'black',
    },
    loaderContainer: {
        height: '60vh',
    },
    resourceBody: {
        marginTop: '30px',
    },
    selectionSec: {
        display: 'flex',
        alignItems: 'center',
    },
    outlineButton: {
        marginRight: '10px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '0.5px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
    newButton: {
        marginRight: '10px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
        },
    },
    tableSec: {
        marginTop: '30px',
        '& .ag-details-row': {
            '& .ag-theme-alpine': {
                '&:nth-child(1)': {
                    height: '100% !important',
                },
            },
        },
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
}));

export default IndustriesStyle;
