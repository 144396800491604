import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    copyrightContent: {
        width: '100%',
        backgroundColor: '#1C1C1C',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        position: 'fixed',
        bottom: '0px',
        zIndex: '999',
    },
}));

export interface CopyrightStyleProps {
    sx: { pt: number };
    className?: string;
}

function Copyright(props: CopyrightStyleProps) {
    return (
        <Typography variant="body2" align="center" style={{ paddingTop: '5px' }} {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://pcucorp.com/" target="_blank">
                PCU System
            </Link>
            {' '}
            2017.
        </Typography>
    );
}

export function Footer() {
    const classes = useStyles();
    return (
        <Copyright sx={{ pt: 4 }} className={classes.copyrightContent} />
    );
}
