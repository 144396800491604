import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

// the sort hard coded is as per clients requirement in UAT feed back and should be removed in next phase

const GET_ESTIMATE_DETAILS = (skip: number, take: number, orgId: string, projectId: string, versionId: string) => gql`
    query GetEstimateDetails {
        estimate_detail(skip: ${skip}, take: ${take},org_id: ${addEscapeCharacters(orgId)}, project_id: ${addEscapeCharacters(projectId)},
        version_id: ${addEscapeCharacters(versionId)}
        sort: { wbs_code: ASC, coa_code: ASC, sequence: ASC }
        ) {
          pageInfo {
            skip
            take
            hasNextPage
            totalcount
          }
          data {
            estimatedData {
              sequence
              description
              detailed_description
              quantity
              ur_code
              unit_cost
              total_unit_cost
              item_type
              total_cost
              wbs {
                code
                description
                level
                code1
                code2
                code3
                code4
                first_parent
                second_parent
                third_parent
                forth_parent
                fifth_parent
                self_code_desc
              }
              coa {
                code
                level
                code1
                code2
                self_code_desc
              }
            }
            total {
              total_man_hours
              labour_cost
              construction_equip_cost
              material_cost
              equipment_cost
              total_cost
            }
          }
          }
    }`;

export default {
    GET_ESTIMATE_DETAILS,
};
