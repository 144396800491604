import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Footer } from '../footer';
import MainWrapperStyle from './NavigationWrapper.style';
import { AppDrawer } from '../sidebar';
import Navbar from '../navbar';
import React from 'react';

type Props = {
    open: boolean,
    setOpen: (open: boolean) => void,
};

export default function NavigationWrapper(props: Props) {
    const { open, setOpen } = props;
    const classes = MainWrapperStyle();

    return (
        <>
            <Box className={classes.headerSection}>
                <Navbar open={open} setOpen={setOpen} />
                <Box className={`${classes.headerSectionSideBar} ${classes.tableStyleAgGrid}`}>
                    <AppDrawer open={open} setOpen={setOpen}>
                        <Grid className={`${classes.tableSection}`}>
                            <Outlet />
                        </Grid>
                    </AppDrawer>
                </Box>
            </Box>
            <Footer />
        </>
    );
}
