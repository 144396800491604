import React, { useState } from 'react';
import useStyles from './PackageHeader.style';
import {
    Box, Button, Input, Typography,
} from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddEditPackageModel from '../AddEditPackageModel';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import ImportOptionsModel from '../../../ProjectsListing/Components/ImportOptionsModel';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';

interface Props {
    search: string
}

function ResourcesHeader(props: Props) {
    const { search } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openImportOptions, setOpenImportOptions] = useState(false);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickImport = () => {
        setOpenImportOptions(true);
    };

    const handleClickDalete = () => {
        setOpenDeleteModel(true);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=packages`);
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    PACKAGES
                </Typography>
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                </Box>
                {/* <Box className={classes.rightSection}>
                    <Box className={classes.inputBoxStyle}>
                        <Input type="text" value={search} placeholder="Search" />
                        <span><SearchIcon /></span>
                    </Box>
                </Box> */}
            </Box>
            <Box className={classes.rightSection}>
                {/* <Box className={classes.outlineButton}>
                    <Button onClick={handleClickImport}>
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button disabled>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box> */}
                {/* <Box className={classes.deleteButton}>
                    <Button onClick={handleClickDalete}>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </Button>
                </Box> */}
                {/* <Box className={classes.outlineButton}>
                    <Button>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </Button>
                </Box> */}
                {/* <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </Button>
                </Box> */}
                {open && <AddEditPackageModel open={open} setOpen={setOpen} />}
                {openImportOptions && <ImportOptionsModel open={openImportOptions} setOpenImport={setOpenImportOptions} />}
                {openDeleteModel && <DeleteRowModel open={openDeleteModel} setOpenDelRow={setOpenDeleteModel} deleteAction={() => {}} />}
            </Box>
        </div>
    );
}

export default ResourcesHeader;
