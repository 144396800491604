import { gql } from 'graphql-request';

export interface UserProps {
    email?: string
}

const getUserByPk = ({ email }: UserProps) => gql`
query MyQuery {
  user(where: {email: {_eq: "${email}"}}) {
    email
    auth_id
    is_approved
    id
  }
}
`;

const UPDATE_USER = gql`
mutation UpdateUSER($id: uuid!, $is_approved: Boolean!) {
    update_user(_set: {is_approved: $is_approved}, where: {id: {_eq: $id}}) {
      returning {
        email
        id
        is_approved
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($id: uuid!) {
    delete_user(where: {id: {_eq: $id}}) {
      affected_rows
      returning {
        id
        auth_id
        email
      }
    }
  }
`;

const GET_USER_BY_KEY = gql`
  query GetUser ($email: String){ 
    user(where: {email: {_eq: $email}}) {
      id
      email
      is_approved
      auth_id
      org_users {
        role
        org {
            name
        }
      }
    }
  }
`;

const GET_USERS = gql`
  query { user {
      email
      auth_id
      is_approved
      id
      org_users {
        role
     },
    }
  }
`;

const GET_USER_ORGID = gql`
  query GetUserOrgId($auth_id: String) {
    user(where: {auth_id: {_eq: $auth_id}}) {
      id
      org_users {
        org_id
      }
    }
  }
`;

const GET_USER_BY_AUTH_ID = gql`
  query GetUserByAuthId($auth_id: String) {
    user(where: {auth_id: {_eq: $auth_id}}) {
      id
      auth_id
      is_approved
      org_users {
        org_id
        role
        org {
          name
          code
        }
      }
    }
  }
`;

const GET_ORG_USERS_BY_ORG_ID = ({ org_id }: any) => gql`
query GetOrgUsersByOrgId {
    user(where: {org_users: {org_id: {_eq: "${org_id}"}}}) {
      id
      email
      is_approved
      auth_id
      org_users {
        role
        user_id
        org_id
      }
    }
  }
`;

const INSERT_ORG_USER = gql`
  mutation InsertOrgUser($org_id: uuid!, $user_id: uuid, $role: String) {
    insert_org_user(objects: {org_id: $org_id, role: $role, user_id: $user_id}, on_conflict: {constraint: org_user_pkey, update_columns: role}) {
      affected_rows
    }
  }
`;

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($emailId: String!) {
    getUserByEmail(emailId: $emailId) {
      email
    }
  }
`;

const GET_USER_DETAILS = gql`
  query {
    getAllUserDetails {
      user_id
      email
      name
      email_verified
      userRole
      default_org_id
      orgnizations {
        id
        name
        display_name
        role_id
      }
    }
  }
`;

export default {
    GET_USERS,
    UPDATE_USER,
    DELETE_USER,
    getUserByPk,
    GET_USER_BY_KEY,
    GET_USER_ORGID,
    GET_USER_BY_AUTH_ID,
    GET_ORG_USERS_BY_ORG_ID,
    INSERT_ORG_USER,
    GET_USER_BY_EMAIL,
    GET_USER_DETAILS,
};
