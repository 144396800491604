import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_WBS_BY_ORGID = ({ org_id }: any) => gql`
query GetWbsDataByOrgId {
    wbs(where: {org_id: {_eq: "${org_id}"}}) {
      code1
      code2
      code3
      code4
      code5
      code6
      description
      class
      required
    }
  }
`;

const GET_WBS_EXTENDED_INFO = (skip: number, take: number, sort: string, sortOrder: string) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetWBSExtendedInfo {
      wbs(skip: ${skip}, take: ${take}, ${sortType}) {
        pageInfo {
          skip
          take
          hasNextPage
          totalcount
        },
        data {
          wbs
          description
          labour_hours
          labour
          cons_equipment
          material
          subcontract
          equipment
          distributables
          total_cost
          title
          initial_cost
          expansion_cost
          sustaining_cost
          total
          twodotone
          twodotzero
          onedotfive
          onedotzero
          base_case
          preengineered_buildings
          modular_buildings
        },
        total_values{
          labour_hoursTotal
          c_equipmentTotal
          materialTotal
          subcontractTotal
          equipmentTotal
          distributableTotal
          total_costTotal
          initial_cost_total
          expansion_cost_total
          sustaining_total
          base_total
          preengineered_buildings_total
          modular_buildings_total
          twodotone_total
          twodotzero_total
          onedotfive_total
          onedotzero_total
          labourTotal
        }
      }
    }
  `;
};

const GET_WBS_INFO = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
  query GetWBSInfo {
    wbs(skip: ${skip}, take: ${take}, 
      filter:{   
        project_id: ${addEscapeCharacters(projId)}
      org_id: ${addEscapeCharacters(orgId)}
      version_id: ${addEscapeCharacters(verId)}
      }) {
      pageInfo {
        skip
        take
        hasNextPage
        totalcount
      },
      data {
        id
        generic_wbs_id
        required
        code1
        code2
        code3
        code4
        code5
        code6
        description
        detailed_description
        class
        created_at
        updated_at
        labour_hours
        labour
        cons_equipment
        material
        subcontract
        equipment
        total_cost
        distributables
        level
        code
      },
      total_values {
        labour_hoursTotal
        labourTotal
        c_equipmentTotal
        materialTotal
        subcontractTotal
        equipmentTotal
        distributableTotal
        total_costTotal
      }  
    }
  }
`;
const GET_WBS_PHASE_INFO = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
query GetWbsPhase {
     wbsphase(
      skip: ${skip}, take: ${take},
       filter: {
        project_id: ${addEscapeCharacters(projId)}
        org_id: ${addEscapeCharacters(orgId)}
        version_id: ${addEscapeCharacters(verId)}
      }
      ) {
       pageInfo {
         skip
         take
         totalcount
         hasNextPage
       }
       data
       total_values
     }
   }
`;

const GET_WBS_CASE_INFO = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
query GetWbsCase{
  wbscase(
    skip: ${skip}, take: ${take},
    filter: {
      project_id: ${addEscapeCharacters(projId)}
      org_id: ${addEscapeCharacters(orgId)}
      version_id: ${addEscapeCharacters(verId)}
    }
  ) {
    pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
    data
    total_values
  }
}
`;
const GET_WBS_VERSION = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
query GetWbsVersion{
  wbsversion(
    skip: ${skip}, take: ${take},
    filter: {
      project_id: ${addEscapeCharacters(projId)}
      org_id: ${addEscapeCharacters(orgId)}
      version_id: ${addEscapeCharacters(verId)}
    }
    ) {
      pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
    data
    total_values
  }
}

`;
export default {
    GET_WBS_BY_ORGID,
    GET_WBS_EXTENDED_INFO,
    GET_WBS_INFO,
    GET_WBS_PHASE_INFO,
    GET_WBS_CASE_INFO,
    GET_WBS_VERSION,
};
