import React, { useContext } from 'react';
import CostHeaderBarStyle from './costHeaderBar.style';
import { Box } from '@mui/material';
import { useFormatCurrency } from '../../hooks/useFormatCurrency';
import { ProjectContext } from '../../store/context/projectContext';
import DonutChart from '../DonutChart/DonutChart';

interface DonutData {
    label: string,
    value: number,
}
export default function CostHeaderBar() {
    const classes = CostHeaderBarStyle();
    const customCellRender = useFormatCurrency();
    const projectCtx = useContext(ProjectContext);

    const colors = ['#35B83D', '#484DFF'];

    const orgCost = projectCtx?.orgTotalCost;

    const data: DonutData[] = [
        {
            label: 'Direct',
            value: (orgCost?.direct) ? Number(orgCost.direct) : 0,
        },
        {
            label: 'Indirect',
            value: orgCost?.indirect ? Number(orgCost.indirect) : 0,
        },
    ];

    return (
        <Box className={classes.chartStyle}>
            <span style={{ color: colors[0] }}>
                Direct
                &nbsp;
                {customCellRender((orgCost?.direct) || '')}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: colors[1] }}>
                Indirect
                &nbsp;
                {customCellRender((orgCost?.indirect) || '')}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: '#353535' }}>
                Total
                &nbsp;
                {customCellRender((orgCost?.total) || '')}
            </span>
            &nbsp;&nbsp;
            <DonutChart chartData={data} innerRd={7} outerRd={11} height={25} inHeader />

        </Box>
    );
}
