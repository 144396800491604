import React from 'react';
import { CellRendererType } from '../../../../types/AgGridTypes';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import WBSDifferent from './WBSDifferent.style';

function WbsCustom(params: CellRendererType) {
    const classes = WBSDifferent();
    const formatCurrency = useDisplayCurrFormatter();

    // if (params === undefined) { return; }
    const colName = params?.colDef?.field;
    if (params?.value != null) {
        const { value } = params;
        if (params?.node?.rowPinned) {
            return (
                <span>
                    {(colName === 'labour_hours' || colName === 'description')
                        ? params?.value.toLocaleString()
                        : formatCurrency(params?.value)}
                </span>
            );
        }
        if (colName && colName.startsWith('column_')) {
            switch (colName) {
            case `${colName}`:
                return formatCurrency(params?.value);
            default:
                return params?.value;
            }
        }
        switch (colName) {
        case 'code':
            if (params?.data.level === 1) {
                return (
                    <span style={{
                        fontSize: '13px', fontWeight: 700, fontFamily: 'Inter-Medium',
                    }}
                    >
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            if (params?.data.level === 2) {
                return (
                    <span style={{ fontSize: '13px', fontWeight: 600, fontFamily: 'Inter-Medium' }}>
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            return params?.value.toLocaleString();
        case 'description':
            if (params?.data.level === 1) {
                return (
                    <span style={{
                        fontSize: '13px', fontWeight: 700, fontFamily: 'Inter-Medium', textTransform: 'uppercase',
                    }}
                    >
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data.level === 2) {
                return (
                    <span style={{ fontSize: '13px', fontWeight: 600, fontFamily: 'Inter-Medium' }}>
                    &emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data.level === 3) {
                return (
                    <span>
                        &emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data.level === 4) {
                return (
                    <span>
                        &emsp;&emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data.level === 5) {
                return (
                    <span>
                        &emsp;&emsp;&emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            if (params?.data.level === 6) {
                return (
                    <span>
                        &emsp;&emsp;&emsp;&emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            return params?.value.toLocaleString();
        case 'labour_hours':
            return value && formatCurr(+(value.toFixed(0)), 'en-US');
        case 'cons_equipment':
        case 'equipment':
        case 'material':
        case 'total_cost':
        case 'distributables':
        case 'subcontract':
        case 'labour':
            return formatCurrency(params?.value);
        default:
            return params?.value;
        }
    } else if (params?.value === null) {
        return '-';
    }
    return '';
}

export default WbsCustom;
