import React, {
    Fragment,
    useState,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import CommoditiesHeader from '../../components/CommoditiesHeader';
import { Box } from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import useStyles from './Commodities.style';
import commoditiesQueries from '../../../../queries/commodities';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import Loader from '../../../../components/Loader';
import {
    OutletContext,
    QueryErrorResponse,
} from '../../../../types/CommoditiesListType';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import { useOutletContext, Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../store/context/userContext';
import { CellRendererDetailsType } from '../../../../types/AgGridTypes';
import PageUnavailable from '../../../ProjectSettings';
import { formatCurr } from '../../../../hooks/useFormatCurrency';
import { ProjectContext } from '../../../../store/context/projectContext';
import ProjectListType from '../../../../types/ProjectListType';
import { PATH_DATABASE } from '../../../../Routes/path';

function Commodities() {
    const classes = useStyles();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const [show] = useState(!!userCtx?.user?.default_org_id);
    const cont: OutletContext = useOutletContext();
    const projectCtx = useContext(ProjectContext);
    const navigate = useNavigate();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0]
            ? response.errors[0].message
            : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetCommoditiesWithProjectsDataByOrgId',
        commoditiesQueries.GET_COMMODITIES_WITH_PROJECTS_BY_ORGID(
            userCtx?.user?.default_org_id || '',
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
        ),
        {
            onError: handleApiError,
        },
        {
            enabled: show,
        },
    );

    useEffect(() => {
        if (show) {
            refetch();
        }
    }, [pageSkipValue, refetch]);

    const nameCellRenderer = (params: CellRendererDetailsType) => {
        const colName = params.colDef.field;
        if (params.value != null) {
            switch (colName) {
            case 'description':
                if (params?.data.level === 1) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 700,
                                fontFamily: 'Inter-Medium',
                                textTransform: 'uppercase',
                            }}
                        // eslint-disable-next-line react/jsx-no-comment-textnodes
                        >
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data.level === 2) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                fontFamily: 'Inter-Medium',
                            }}
                        >
                &emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data.level === 3) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 500,
                                fontFamily: 'Inter-Medium',
                            }}
                        >
                &emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data.level === 4) {
                    return (
                        <span>
                &emsp;&emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data.level === 5) {
                    return (
                        <span>
                &emsp;&emsp;&emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                return params?.value.toLocaleString();
            case 'unit':
                return `${params.value.toLocaleString()}`;
            case 'total_cost':
                return `$${formatCurr(+(+params.value).toFixed(0), 'en-US')}`;
            case 'code':
                if (params?.data.level === 1) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 700,
                                fontFamily: 'Inter-Medium',
                                textTransform: 'uppercase',
                            }}
                        >
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data.level === 2) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                fontFamily: 'Inter-Medium',
                            }}
                        >
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                return params?.value.toLocaleString();
            default:
                return params.value;
            }
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'row_expand ',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRenderer: 'agGroupCellRenderer',
            sortable: false,
        },
        {
            field: 'code',
            width: 150,
            type: 'string',
            headerName: 'Code',
        },
        {
            field: 'description',
            width: 350,
            type: 'string',
            headerName: 'Description',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'unit',
            width: 130,
            headerName: 'Unit',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'total_cost',
            width: 180,
            headerName: 'Total Cost',
            cellRendererFramework: nameCellRenderer,
            type: 'numericColumn',
        },
    ];

    // const handleLink = (project:ProjectListType) => {
    //     projectCtx?.setProject(project);
    //     navigate(PATH_DATABASE.projectDashboard);
    // };

    const tableNameCellRenderer = (params: CellRendererDetailsType) => {
        const colName = params.colDef.field;
        const date = params.data?.base_date;
        const formattedDate = date && new Date(+date).getFullYear();
        if (params.value != null) {
            switch (colName) {
            // case 'name':
            //     return (
            //         <Link
            //             to="/projectDashboard"
            //             onClick={(e) => {
            //                 e.preventDefault();
            //                 handleLink(params.data);
            //             }}
            //             style={{ color: '#007DFF' }}
            //         >
            //             {params.value}
            //         </Link>
            //     );
            case 'base_date':
                return `${formattedDate}`;
            case 'total_cost':
                return `$${formatCurr(+(+params.value).toFixed(2), 'en-US')}`;
            default:
                return params.value;
            }
        }
        return '';
    };

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'client_number',
                        headerName: 'Project Number',
                        minWidth: 50,
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        minWidth: 50,
                        // cellRendererFramework: tableNameCellRenderer,
                    },
                    {
                        field: 'estimate_type',
                        headerName: 'Estimate Type',
                        minWidth: 50,
                    },
                    {
                        field: 'country_name',
                        headerName: 'Country',
                        minWidth: 50,
                    },
                    {
                        field: 'region',
                        headerName: 'Region',
                        minWidth: 50,
                    },
                    {
                        field: 'base_date',
                        headerName: 'Base Date',
                        minWidth: 50,
                        cellRendererFramework: tableNameCellRenderer,
                    },
                    {
                        field: 'owner',
                        headerName: 'Owner',
                        minWidth: 50,
                    },
                    {
                        field: 'total_cost',
                        headerName: 'Cost',
                        minWidth: 50,
                        cellRendererFramework: tableNameCellRenderer,
                        type: 'numericColumn',
                    },
                ],
                defaultColDef: {
                    flex: 1,
                    sortable: true,
                    unSortIcon: true,
                    suppressMenu: true,
                    resizable: true,
                    suppressMovable: true,
                },
            },
            getDetailRowData(params: CellRendererDetailsType) {
                params.successCallback(params.data.projects || []);
            },
        }),
        [],
    );

    const projectData = data?.commodities_detail;
    const tableData = projectData?.data;
    const pageInfo = projectData?.pageInfo;

    // this function handle pagition
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    // eslint-disable-next-line no-bitwise
    const isCommodities = true;
    const rowData = tableData?.map((item) => item) || [];

    const totalPages = pageInfo && pageInfo.totalcount
        ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE)
        : 0;

    if (!show) {
        return (<PageUnavailable />);
    }

    return (
        <Fragment>
            <Box>
                <CommoditiesHeader />
                <Box
                    className={`${isFetching ? classes.opacityBlur : ''} ${
                        classes.tableSec
                    }`}
                >
                    {isFetching ? <Loader loading={isFetching} /> : null}
                    <AgGridComponent
                        isCommodities={isCommodities}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        changeSortingValue={() => {}}
                        masterDetail
                        detailCellRendererParams={detailCellRendererParams}
                        isStatusBarEnabled
                        isToolPanelsEnabled
                        isClipboardEnabled
                        isExportEnabled
                        isGroupable
                        isRangeSelectable
                        isRangeHandle
                        isPinnable
                        moduleName="commodities"
                    />
                </Box>
            </Box>
            <Box>
                <Pagination
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                />
            </Box>
        </Fragment>
    );
}

export default Commodities;
