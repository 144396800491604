import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_PACKAGES = (
    skip: number,
    take: number,
    orgId: string,
    projectId: string,
    versionId: string,
) => gql`
query {
  package(
    skip: ${skip}
    take: ${take}
    sort: { labour_hours: ASC }
    org_id: ${addEscapeCharacters(orgId)}
    project_id: ${addEscapeCharacters(projectId)}
    version_id: ${addEscapeCharacters(versionId)}
  ) {
    pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
    data {
      packageData {
        id
        code
        name
        labour_hours
        labour
        c_equipment
        material
        subcontract
        equipment
        distributable
        total_cost
        created_at
        updated_at
      }
      total {
        labour_hoursTotal
        labourTotal
        c_equipmentTotal
        materialTotal
        subcontractTotal
        equipmentTotal
        distributableTotal
        total_costTotal
      }
    }
  }
}
`;

export default {
    GET_PACKAGES,
};
