import React, { useEffect, useState, useContext } from 'react';
import useStyles from './UnitRateListView.style';
import {
    Box,
} from '@mui/material';
import { CellRendererType, ColumnDefs } from '../../../../types/AgGridTypes';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import unitRateQueries from '../../../../queries/unitrates';
import Loader from '../../../../components/Loader';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext, QueryErrorResponse } from '../../../../types/CommoditiesListType';
import { useOutletContext } from 'react-router-dom';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import { ValueFormatterParams, IRowNode } from 'ag-grid-community';

interface EstimateDataInterface {
    description_input: string,
    detailed_description: string,
    quantity: number,
    ur_code: string,
    unit_cost: number,
    total_cost: number,
    coa_code: string,
    coa_description: string,
}

function CustomGroupRowRenderer(params: ValueFormatterParams) {
    const { node } = params;

    if (node) {
        const rowNode = node;
        // Check if rowNode level is 1 and there are multiple leaf children
        const leafChildren: IRowNode<EstimateDataInterface>[] = (rowNode.allLeafChildren as IRowNode<EstimateDataInterface>[]) || [];

        let coaCode: string | undefined = '';
        let coaDesc: string | undefined = '';
        const newData1: EstimateDataInterface | undefined = leafChildren[0]?.data;
        if (newData1) {
            coaCode = newData1.coa_code;
            coaDesc = newData1.coa_description;
        }

        const style = {
            height: '100%',
            backgroundColor: '#88B0FF',
            fontSize: '12px',
            lineHeight: '27px',
            paddingLeft: '22px',
        };

        return (
            <div style={style}>
                <span style={style}>
                    <strong>
                        &nbsp;&nbsp;
                        {`${coaCode} - ${coaDesc}`}
                    </strong>
                </span>
            </div>
        );
    }
}

function UnitRateListView() {
    const classes = useStyles();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [resourceSortingState, setResourceSortingState] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const cont: OutletContext = useOutletContext();
    const userContext = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const projectId = projectCtx?.project?.id;
    const formatCurrency = useDisplayCurrFormatter();

    // Handling API errors
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        `GetUnitRateListViewData-${projectId || 0}`,
        unitRateQueries.GET_UNIT_RATE_LIST_VIEW_DATA(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            resourceSortingState,
            'ASC',
            userContext?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const unitRateData = data?.unitrate;
    const tableData = unitRateData?.data;
    const pageInfo = unitRateData?.pageInfo;

    useEffect(() => {
        refetch();
    }, [pageSkipValue, resourceSortingState, sortOrder]);

    const changeSortingValue = (par: string) => {
        if (resourceSortingState === par || resourceSortingState === '') {
            if (sortOrder === 'DESC') {
                setSortOrder('ASC');
            } else {
                setSortOrder('DESC');
            }
        }
        setResourceSortingState(par);
    };

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const rowData = tableData && tableData.length ? tableData.map((item) => ({
        ...item,
        unit_man_hours: item?.unit_man_hours ? (+item.unit_man_hours).toFixed(2) : 0.00,
        // qty: item.unit_cost ? item.unit_cost.reduce((initialValue, uCost) => initialValue + (uCost.quantity || 0), 0) : 0,
    })) : [];

    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const gridCustomCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        if (params && params.value) {
            switch (colName) {
            case 'quantity':
                return params.value ? formatCurr(+params.value.toFixed(0), local) : '-';
            case 'unit_man_hours':
                return params.value ? formatCurr(params.value, local) : '-';
            default:
                return formatCurrency(params?.value);
            }
        }
        if (params.value === null) {
            return '-';
        }
        return params?.value;
    };

    const columnDefs = [
        // {
        //     field: 'blank', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 50,
        // },
        {
            field: 'coa_code',
            rowGroup: true,
            headerName: 'COA',
            width: 180,
            type: 'string',
            cellRendererParams: {
                suppressCount: true,
            },
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 150,
            type: 'string',
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            type: 'string',
        },
        {
            field: 'unit',
            headerName: 'Unit',
            width: 120,
            type: 'string',
        },
        {
            field: 'unit_man_hours',
            headerName: 'Labour Hours',
            width: 160,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
        },
        {
            field: 'lab_rate',
            headerName: 'Labour Rate',
            width: 160,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'unit_labour_cost',
            headerName: 'Labour',
            width: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'unit_material_cost',
            headerName: 'Material',
            width: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'unit_consequip_cost',
            headerName: 'C.Equip',
            width: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'unit_cost',
            headerName: 'Unit Rate',
            width: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'quantity',
            headerName: 'Qty',
            width: 190,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
        },
        {
            field: 'cost_in_estimate',
            headerName: 'Total Cost',
            width: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'total_unit_cost',
            headerName: 'Total Unit Cost',
            width: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
        },
    ];

    return (
        <div>
            <Box className={`${isFetching ? classes.opacityBlur : ''} ${classes.projectListMain}`}>
                { isFetching ? (
                    <Loader loading={isFetching} />
                ) : null }
                <AgGridComponent
                    columnDefs={columnDefs}
                    rowData={rowData}
                    changeSortingValue={changeSortingValue}
                    sortOrder={sortOrder}
                    isRangeSelectable
                    isPinnable
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    customGroupRowRenderer={CustomGroupRowRenderer}
                    defaultExpanded={1}
                    moduleName="UnitRateListView"
                    colFormat={['lab_rate', 'unit_labour_cost', 'unit_material_cost', 'unit_consequip_cost',
                        'unit_cost', 'cost_in_estimate', 'total_unit_cost']}
                    disableResizable
                />
            </Box>
            <Box>
                <Pagination totalPages={totalPages} handlePageChange={handlePageChange} />
            </Box>
        </div>
    );
}

export default UnitRateListView;
