import React, {
    Fragment, useContext, useEffect, useMemo, useState,
} from 'react';
import IndustriesStyle from './industries.style';
import IndustriesHeader from '../../components/IndustriesHeader';
import { Box } from '@mui/material';
import PageUnavailable from '../../../ProjectSettings';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { UserContext } from '../../../../store/context/userContext';
import { GET_ALL_INDUSTRY } from '../../../../queries/industries';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { ColumnDefs, CellRendererType } from '../../../../types/AgGridTypes';
import Loader from '../../../../components/Loader';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { formatCurr } from '../../../../hooks/useFormatCurrency';

interface CellRendererTyp {
   data: {
    projects: string[];
    project_count: number,
   }
    successCallback: (projects: string[]) => void,
    isProjectCountGreaterThanZero: (projects: string[]) => void,
}

function Industries() {
    const classes = IndustriesStyle();
    const userCtx = useContext(UserContext);
    const [data, setData] = useState(!!userCtx?.user?.default_org_id);
    const [pageSkipValue, setPageSkipValue] = useState(0);

    const { data: industryData, isFetching, refetch } = useGQLQuery(
        'GetIndustries',
        GET_ALL_INDUSTRY(userCtx?.user?.default_org_id || ''),
        {},
        {
            enabled: data,
        },
    );

    useEffect(() => {
        if (data) {
            refetch();
        }
    }, [pageSkipValue]);

    const customCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        const date = params.data?.base_date;
        const formattedDate = date && new Date(+date).getFullYear();
        if (params.value != null) {
            switch (colName) {
            case 'description':
                if (params.data.level === 1) {
                    return (
                        <span className={classes.industryTable}>
                            {params.value}
                        </span>
                    );
                }
                if (params.data.level === 2) {
                    return (
                        <span>
                &emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params.data.level === 3) {
                    return (
                        <span>
                &emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params.data.level === 4) {
                    return (
                        <span>
                &emsp;&emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params.data.level === 5) {
                    return (
                        <span>
                &emsp;&emsp;&emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params.data.level === 6) {
                    return (
                        <span>
                &emsp;&emsp;&emsp;&emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                break;
            case 'total_cost':
                return params.value ? `$${formatCurr(+(+params.value).toFixed(0), 'en-US')}` : '-';
            case 'project_count':
                return params.data.project_count > 0 ? `${params.value}` : '-';
            case 'unit':
                return params.data.unit ? `${params.value}` : '-';
            case 'attr_label':
                return params.data.attr_label ? `${params.value}` : '-';
            case 'capacity':
                return params.data.capacity > 0 ? `${params.value}` : '-';
            case 'base_date':
                return formattedDate;
            default:
                return '';
            }
        }
        if (params.value === null) {
            return '-';
        }
        return '';
    };

    const tableNameCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        if (params.value != null) {
            switch (colName) {
            case 'total_cost':
                return `$${formatCurr(+(+params.value).toFixed(2), 'en-US')}`;
            default:
                return params.value;
            }
        }
        return '';
    };

    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const totalPages = 1;
    const industriesTableData = industryData !== undefined && industryData?.all_industries && Array.isArray(industryData?.all_industries?.data)
        ? industryData?.all_industries?.data.map((item) => item) : [];

    const columnDefs = [
        {
            field: 'row_expand ',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRenderer: 'agGroupCellRenderer',
            sortable: false,
        },
        {
            field: 'code',
            width: 150,
            headerName: 'Code',
            type: 'number',
            sort: 'asc',
        },
        {
            field: 'description',
            width: 350,
            headerName: 'Description',
            cellRenderer: customCellRenderer,
            type: 'string',
        },
        {
            field: 'attr_label',
            width: 150,
            cellRenderer: customCellRenderer,
            headerName: 'Attribute',
            type: 'string',
        },
        {
            field: 'unit',
            width: 130,
            headerName: 'Unit ',
            cellRenderer: customCellRenderer,
            type: 'string',
        },
        {
            field: 'project_count',
            width: 130,
            headerName: 'Projects',
            cellRenderer: customCellRenderer,
            type: 'string',
        },
        {
            field: 'total_cost',
            width: 180,
            headerName: 'Total Cost',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
    ];

    const isProjectCountGreaterThanZero = (params: CellRendererTyp) => params.data.project_count > 0;

    const detailCellRendererParams = useMemo(() => ({
        detailGridOptions: {
            columnDefs: [
                {
                    field: 'client_number', headerName: 'Project Number', minWidth: 50,
                },
                {
                    field: 'name', headerName: 'Name', minWidth: 50,
                },
                {
                    field: 'estimate_type', headerName: 'Estimate Type', minWidth: 50,
                },
                {
                    field: 'country_name', headerName: 'Country', minWidth: 50,
                },
                {
                    field: 'region', headerName: 'Region', minWidth: 50,
                },
                {
                    field: 'base_date', headerName: 'Base Date', minWidth: 50, cellRenderer: customCellRenderer,
                },
                {
                    field: 'owner', headerName: 'Owner', minWidth: 50,
                },
                {
                    field: 'capacity', headerName: 'Capacity', type: 'numericColumn', minWidth: 50, cellRenderer: customCellRenderer,
                },
                {
                    field: 'unit', headerName: 'Unit', minWidth: 50, cellRenderer: customCellRenderer,
                },
                {
                    field: 'total_cost', headerName: 'Cost', minWidth: 50, cellRenderer: tableNameCellRenderer, type: 'numericColumn',
                },
            ],
            defaultColDef: {
                flex: 1,
                sortable: true,
                unSortIcon: true,
                suppressMenu: true,
                resizable: true,
                suppressMovable: true,
            },
        },
        getDetailRowData(params: CellRendererTyp) {
            params.successCallback(isProjectCountGreaterThanZero(params) ? params.data.projects : []);
        },
    }), []);

    if (!data) {
        return (<PageUnavailable />);
    }

    return (
        <Fragment>
            <IndustriesHeader />
            {!data ? (
                <PageUnavailable />
            ) : (
                <Box>
                    {isFetching ? (
                        <Box className={classes.loaderContainer}>
                            <Loader loading={isFetching} />
                        </Box>
                    ) : (
                        <div>
                            <Box className={classes.tableSec}>
                                <AgGridComponent
                                    columnDefs={columnDefs}
                                    rowData={industriesTableData}
                                    changeSortingValue={() => {}}
                                    isRangeSelectable
                                    isGroupable
                                    masterDetail
                                    detailCellRendererParams={detailCellRendererParams}
                                    isExportEnabled
                                    isClipboardEnabled
                                    isToolPanelsEnabled
                                    isStatusBarEnabled
                                    isPinnable
                                    moduleName="Industrie"
                                />
                            </Box>
                            <Box>
                                <Pagination
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                />
                            </Box>
                        </div>
                    )}
                </Box>
            )}
        </Fragment>
    );
}

export default Industries;
