import React, { useContext, useEffect, useState } from 'react';
import ResourcesHeader from '../../components/PackageHeader';
import { Box } from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import useStyles from './Package.style';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import GET_PACKAGES from '../../../../queries/packages'; // Import your GraphQL query
import Loader from '../../../../components/Loader';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';

interface CellDataType {
  orgHierarchy: string[];
  wbs: string;
  labour_hours: number;
  total_cost: number;
  description: string;
}

interface CellRendererType {
  data: CellDataType & { [colId: string]: number };
  colDef: {
    field: string
},
  column: {
    colId: string;
  };
  value: number;
  node: {
    footer: boolean;
  };
}

interface Totals {
    labour_hoursTotal: number;
    labourTotal: number;
    c_equipmentTotal: number;
    materialTotal: number;
    subcontractTotal: number;
    equipmentTotal: number;
    distributableTotal: number;
    total_costTotal: number;
    sustaining_total: number;
}

function Package() {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const formatCurrency = useDisplayCurrFormatter();

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const formatNumber = (number: number) => Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const customCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        switch (colName) {
        case 'c_equipment':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        case 'material':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        case 'subcontract':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        case 'equipment':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        case 'distributable':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        case 'labour':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        case 'labour_hours':
            return params.value ? formatCurr(+params.value.toFixed(0), local) : '_';
        case 'total_cost':
            return params.value ? `${formatCurrency(params.value)}` : '_';
        default:
            return params.value ? `${formatCurrency(params.value)}` : '_';
        }
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetPackage',
        GET_PACKAGES.GET_PACKAGES(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
    );

    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };
    const packageData = data?.package?.data?.packageData;
    const pageInfo = data?.package?.pageInfo;
    const totalValue = data?.package?.data?.total;
    const tableData = packageData ? packageData?.map((item) => item) : [];

    const createData = (params: Totals | undefined) => [
        {
            name: 'TOTAL',
            labour_hours: params?.labour_hoursTotal,
            labour: params?.labourTotal,
            material: params?.materialTotal,
            // sustaining_cost: params?.sustaining_total,
            equipment: params?.equipmentTotal,
            subcontract: params?.subcontractTotal,
            total_cost: params?.total_costTotal,
            distributable: params?.distributableTotal,
            c_equipment: params?.c_equipmentTotal,
        },
    ];
    const pinnedBottomRowData = createData(totalValue);

    useEffect(() => {
        refetch();
    }, [pageSkipValue]);

    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
    const columnDefs = [
        {
            field: 'code',
            width: 130,
            headerName: 'Code',
            aggFunc: 'sum',
            type: 'string',
        },
        {
            field: 'name',
            width: 200,
            headerName: 'Package Name',
            aggFunc: 'sum',
            type: 'string',
        },
        {
            field: 'labour_hours',
            width: 200,
            headerName: 'Labour hours',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'labour',
            width: 150,
            headerName: 'Labour',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'c_equipment',
            width: 200,
            headerName: 'C.Equipment',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'material',
            width: 150,
            headerName: 'Material',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'subcontract',
            width: 200,
            headerName: 'Subcontract',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'equipment',
            width: 150,
            headerName: 'Equipment',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'distributable',
            width: 200,
            headerName: 'Distributables',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'total_cost',
            width: 150,
            headerName: 'Total Cost',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
    ];

    return (
        <Box>
            <ResourcesHeader search={search} />
            {isFetching ? (
                <Box className={classes.loaderContainer}>
                    <Loader loading={isFetching} />
                </Box>
            ) : (
                <div>
                    <Box className={classes.tableSec}>
                        <AgGridComponent
                            columnDefs={columnDefs}
                            rowData={tableData}
                            changeSortingValue={() => {}}
                            pinnedBottomRowData={pinnedBottomRowData}
                            isRangeSelectable
                            isGroupable
                            isExportEnabled
                            isClipboardEnabled
                            isToolPanelsEnabled
                            isStatusBarEnabled
                            moduleName="Package"
                            isPinnable
                            colFormat={['labour', 'c_equipment', 'material', 'subcontract', 'equipment', 'distributable', 'total_cost']}
                            footerFormat={['labour', 'c_equipment', 'material', 'subcontract', 'equipment', 'distributable', 'total_cost']}
                            disableResizable
                        />
                    </Box>
                    <Box>
                        <Pagination
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </div>
            )}
        </Box>
    );
}
export default Package;
