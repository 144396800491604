import React, {
    Fragment, ReactNode, useEffect, useState, useContext,
} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import logo from '../../assets/logo.svg';
import menu from '../../assets/menu_open.svg';
import { Typography } from '@mui/material';
import AppDrawerStyle from './AppDrawer.style';
import { AppDrawerSubMenuItem } from './AppDrawerSubMenuItem';
import { AppDrawerMenuItem } from './AppDrawerMenuItem';
import appDrawerMenuConfig, { appDrawerSubmenuConfig } from './AppDrawerMenuConfig';
import { useUserOrganization } from '../../hooks/useUserRole';
import { PATH_RESTRICTED_CONFIG } from '../../Routes/path';
import { useLocation } from 'react-router-dom';
import { ProjectContext } from '../../store/context/projectContext';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const drawerWidth = 237;

type Props = {
    open: boolean,
    setOpen: (open: boolean) => void,
    children: ReactNode
};

export function AppDrawer(props : Props) {
    const projectCtx = useContext(ProjectContext);
    const { open, setOpen, children } = props;
    const classes = AppDrawerStyle();
    const theme = useTheme();
    const userOrg = useUserOrganization();
    const [showProjectMenus, setShowProjectMenus] = useState<boolean>(false);
    const currentLoc = useLocation();

    useEffect(() => {
        const path = currentLoc?.pathname?.split('/')[1];
        const { show_submenus: showSubmenus } = PATH_RESTRICTED_CONFIG;
        if (showSubmenus.includes(`/${path}`)) {
            setShowProjectMenus(true);
        } else {
            setShowProjectMenus(false);
        }
    }, [currentLoc]);

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            className={classes.leftBar}
        >
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <img src={menu} alt="menu" /> : <ChevronRightIcon />}
                </IconButton>
                <Box><img src={logo} alt="logo" /></Box>
            </DrawerHeader>
            <Typography variant="h5" noWrap>
                <Tooltip title={userOrg && userOrg?.display_name}>
                    <span>{userOrg && userOrg?.display_name}</span>
                </Tooltip>
            </Typography>
            {
                appDrawerMenuConfig.map(({ title, submenu, visible }) => (
                    visible
                        ? (
                            <Fragment key={title}>
                                <AppDrawerMenuItem title={title} />
                                <AppDrawerSubMenuItem items={submenu} />
                            </Fragment>
                        )
                        : null
                ))
            }
            {
                appDrawerSubmenuConfig.length && appDrawerSubmenuConfig.map(({ title, submenu }) => (
                    showProjectMenus
                        ? (
                            <Fragment key={title}>
                                <AppDrawerMenuItem title={projectCtx?.project?.name} />
                                <AppDrawerSubMenuItem items={submenu} />
                            </Fragment>
                        )
                        : null
                ))
            }
        </Drawer>
    );
}
