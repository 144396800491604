import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '5.5px 32px 5.5px 12px !important',
            fontSize: '13px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#323232',
        },
    },
    outlineButton: {
        marginLeft: '25px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    undoButtnNew: {
        fontSize: '12px',
        color: '#fff !important',
        border: '1px solid #007DFF !important',
        borderRadius: '4px',
        backgroundColor: '#007DFF !important    ',
        padding: '2px 10px !important',
        cursor: 'pointer',
        fontFamily: 'Inter-Medium',
        '& svg': {
            color: '#fff !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    redBtn: {
        borderRadius: '4px',
        padding: '3px 15px !important',
        color: '#B00000 !important',
        border: '1px solid #B00000 !important',
        '& svg': {
            color: '#B00000 !important',
        },
    },
    TableHeader: {
        margin: '0px',
        color: '#021948',
        padding: '2px 0px 2px 0px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& button': {
            textTransform: 'none',
        },
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: 'none',
                opacity: '1',
            },
        },
    },
    tableSec: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 310px) !important',
            },
        },
    },
}));
