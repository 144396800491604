import React, {
    ChangeEvent,
    Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import {
    Box, Radio, RadioGroup, FormControlLabel, DialogActions, DialogContent,
    DialogTitle, Dialog, Button, IconButton, Grid, FormControl,
} from '@mui/material';
import useStyles from './SwitchOrganization.style';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../../../../store/context/userContext';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import organizationQueries from '../../../../queries/organisations';
import Loader from '../../../Loader';
import { setDefaultOrgIDInLocalStorage, setOrgIdInLocalStorage } from '../../../../helper/StorageHelper';
import { useNavigate } from 'react-router-dom';
import { PATH_DATABASE } from '../../../../Routes/path';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import userQueries from '../../../../queries/users';
import { OrgUserType } from '../../../../types';
import InvitationType from '../../../../types/InvitationTypes';

interface InviteProps {
    open: boolean,
    setOpen: undefined | ((open: boolean) => void),
}

function SwitchOrganizationModel({ open, setOpen }: InviteProps) {
    const classes = useStyles();
    const userCtx = useContext(UserContext);
    const [selectedOrg, setSelectedOrg] = useState<string>('');
    const navigate = useNavigate();
    const userContext = useContext(UserContext);

    useEffect(() => {
        const defaultOrgId = userCtx?.user?.default_org_id || '';
        setSelectedOrg(defaultOrgId);
    }, []);

    const handleOnUserFetchingSuccess = (data: InvitationType) => {
        userContext?.setUserDetails(data.getAllUserDetails);
        setDefaultOrgIDInLocalStorage(data.getAllUserDetails?.default_org_id);
        if (setOpen) {
            setOpen(false);
        }
        // Save org_id in local storage
        setOrgIdInLocalStorage(selectedOrg);
        navigate(PATH_DATABASE.dashboard);
    };

    //  Get user details like id, name, org_id, role
    const { data, refetch: refetchUser, isFetching } = useGQLQuery(
        'GetUserRolesSwitch',
        userQueries.GET_USER_DETAILS,
        {},
        {
            onSuccess: handleOnUserFetchingSuccess,
            enabled: false,
        },
        '/userAuthentication',
    );

    // For initial popup open by default
    useEffect(() => {
        const defaultOrgId = userCtx?.user?.default_org_id || '';
        setSelectedOrg(defaultOrgId);
    }, [userCtx?.user?.default_org_id]);

    const handleClose = () => {
        // Set initial value of org on popup close
        const defaultOrgId = userCtx?.user?.default_org_id || '';
        setSelectedOrg(defaultOrgId);
        if (setOpen) {
            setOpen(false);
        }
        // Save org_id in local storage
        setOrgIdInLocalStorage(selectedOrg);
        setDefaultOrgIDInLocalStorage(selectedOrg);
    };

    const handleOrgChange = (event: ChangeEvent<HTMLInputElement>, orgId: string) => {
        setSelectedOrg(orgId);
    };

    const handleMutationSuccess = () => {
        // Refetch user details API
        refetchUser();
    };

    const { mutate, isLoading } = useGQLMutation(
        'SwitchOrg',
        // eslint-disable-next-line no-useless-escape
        organizationQueries.SWITCH_ORGANISATION(`\"${selectedOrg}\"`),
        {
            onSuccess: handleMutationSuccess,
        },
    );

    const handleOrgSave = () => {
        // Set default org
        mutate({});
    };

    const organizations = userCtx?.user?.orgnizations;

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Select Organisation</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    {isLoading || isFetching ? <Loader loading={isLoading || isFetching} /> : null}
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Celestial Systems Inc."
                                    name="radio-buttons-group"
                                    className={classes.selectRadio}
                                    value={selectedOrg}
                                    onChange={handleOrgChange}
                                >
                                    { organizations?.map((item) => (
                                        <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.display_name} />
                                    )) }
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={handleOrgSave} disabled={isLoading}>
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default SwitchOrganizationModel;
