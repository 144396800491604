import React, {
    Fragment, useContext,
} from 'react';
import useStyles from './ProjectDashboard.style';
import { Box, Typography } from '@mui/material';
import { Link, useOutletContext } from 'react-router-dom';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { QueryErrorResponse, OutletContext } from '../../../../types/CommoditiesListType';
import projectCs from '../../../../queries/case';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import projectPhase from '../../../../queries/phase';
import wbsQueries from '../../../../queries/wbs';
import coaQueries from '../../../../queries/coa';
import resourcesQueries from '../../../../queries/resources';
import unitRateQueries from '../../../../queries/unitrates';
import labourQueries from '../../../../queries/labourAndCrew';
import Loader from '../../../../components/Loader';
import TotalValueType from '../../../../types/TotalValueType';
import DashboardHeader from '../DashboardHeader';
import { PATH_CMFR } from '../../../../Routes/path';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { HashLink } from 'react-router-hash-link';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import DonutChat from '../../../../components/DonutChart/DonutChart';

function ProjectDashboard() {
    const classes = useStyles();
    const { capex } = PATH_CMFR;
    const formatCurrency = useDisplayCurrFormatter();

    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;
    const projectId = projectCtx?.project?.id;
    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    interface CellDataType {
        labour_hours: number,
        total_cost: number,
        currency?: {
            exchange_rate?: number,
            total_cost: number,
            symbol: string,
        },
    }

    interface CasePhaseType {
        code?: string;
        name?: string;
        total_cost?: number;
    }
    interface CellRendererType {
        data: CellDataType & { [colId: string]: number, },
        column: {
            colId: string
        },
        value: number,
        node: {
            footer: boolean
        },
        colDef: {
            field: string
            headerName: string
        },
    }

    const formatNumber = (number: number) => Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    const customCellRenderer = (params: CellRendererType) => {
        const { colId } = params.column;
        const { data } = params;
        if (params.value != null) {
            switch (colId) {
            case 'labour_hours':
                if (!data && params.node.footer) {
                    return params.value ? <span className={classes.inTableBold}>{formatCurr(+params.value.toFixed(0), local)}</span> : '-';
                }
                return data && data.labour_hours ? formatCurr(+data.labour_hours.toFixed(0), local) : '-';
                break;
            case 'prod_factor':
                return params.value && params.value.toFixed(2);
            case 'cost_per_unit':
                return data && data?.cost_per_unit ? (
                    <span className={classes.inTableBold}>
                        {formatCurrency(data?.cost_per_unit)}
                    </span>
                ) : '-';
                break;
            case 'total_quantity':
                return data && data?.total_quantity ? formatCurr(+data.total_quantity.toFixed(0), local) : '-';
                break;
            default:
                if (!data && params.node.footer) {
                    return params.value ? (
                        <span className={classes.inTableBold}>
                            {formatCurrency(params.value)}
                        </span>
                    ) : '-';
                }
                return data && data[colId] ? formatCurrency(data[colId]) : '-';
            }
        }
        return '-';
    };

    const boldRenderer = (params: CellRendererType) => {
        const { heading } = params.data;
        if (heading) {
            return (
                <span className={classes.inTableBold}>
                    {params.value}
                </span>
            );
        }
        return params.value;
    };

    const compositeCellRenderer = (params: CellRendererType) => {
        const { code, heading } = params.data;
        if (!code && heading && params.value != null) {
            return (
                <span className={classes.inTableBold}>
                    {formatCurrency(params.value)}
                </span>
            );
        }
        if (params.value === null) return '-';
        return formatCurrency(params.value);
    };

    const changeSortingValue = (str: string) => { };

    const columnDefsForCasesAndPhases = [
        {
            field: 'code', type: 'string', width: 130, headerName: 'Code',
        },
        {
            field: 'name', type: 'string', width: 130, headerName: 'Title',
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 190, headerName: 'Total', cellRenderer: customCellRenderer,
        },
    ];

    const columnDefsForWbs = [
        {
            field: 'code', type: 'string', width: 130, headerName: 'Code',
        },
        {
            field: 'description', type: 'string', width: 200, headerName: 'Title', cellRenderer: boldRenderer,
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 190, headerName: 'Total', aggFunc: 'sum', cellRenderer: compositeCellRenderer,
        },
    ];

    const columnDefsForCoa = [
        {
            field: 'code', type: 'string', width: 130, headerName: 'Code',
        },
        {
            field: 'description', type: 'string', width: 200, headerName: 'Title', cellRenderer: boldRenderer,
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 190, headerName: 'Total', aggFunc: 'sum', cellRenderer: compositeCellRenderer,
        },
    ];

    const columnDefsForLabourAndCrewRates = [
        {
            field: 'code', width: 130, type: 'string', headerName: 'COA',
        },
        {
            field: 'account_description', type: 'string', width: 300, headerName: 'Account Description', cellRenderer: 'agGroupCellRenderer',
        },
        {
            field: 'labour_hours', type: 'numericColumn', width: 190, headerName: 'Lab. Hrs', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'labour_rate', type: 'numericColumn', width: 190, headerName: 'Lab. Rate', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'const_distribution',
            type: 'numericColumn',
            width: 190,
            headerName: 'Cont. Distr.',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'sub_total', type: 'numericColumn', width: 190, headerName: 'Subtotal', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'const_equip', type: 'numericColumn', width: 190, headerName: 'Cons. Equip.', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'total', type: 'numericColumn', width: 190, headerName: 'Total', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'prod_factor', type: 'numericColumn', width: 190, headerName: 'Prod. Fact.', cellRenderer: customCellRenderer,
        },
    ];

    const columnDefsForUnitRates = [
        {
            field: 'coa_code', width: 130, headerName: 'COA',
        },
        {
            field: 'code', width: 130, headerName: 'Code',
        },
        {
            field: 'description', width: 250, headerName: 'Description',
        },
        {
            field: 'total_quantity', width: 190, headerName: 'Total Qty', type: 'numericColumn', cellRenderer: customCellRenderer,
        },
        {
            field: 'unit', width: 130, headerName: 'Unit',
        },
        {
            field: 'unit_cost', width: 190, headerName: 'Unit Rate', cellRenderer: customCellRenderer, type: 'numericColumn',
        },
        {
            field: 'cost_in_estimate', width: 190, headerName: 'Total Cost', cellRenderer: customCellRenderer, type: 'numericColumn',
        },
    ];

    const columnDefsForResources = [
        {
            field: 'code', type: 'string', width: 130, headerName: 'Code',
        },
        {
            field: 'description', type: 'string', width: 250, headerName: 'Description',
        },
        {
            field: 'total_quantity', type: 'numericColumn', width: 190, headerName: 'Total Qty', cellRenderer: customCellRenderer,
        },
        {
            field: 'unit', type: 'string', width: 130, headerName: 'Unit',
        },
        {
            field: 'cost_per_unit', type: 'numericColumn', width: 190, headerName: 'Cost per Unit', cellRenderer: customCellRenderer,
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 190, headerName: 'Total Cost', cellRenderer: customCellRenderer,
        },
    ];

    const cont: OutletContext = useOutletContext();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data: projectPhse, refetch, isFetching } = useGQLQuery(
        `GetProjectPhase-${projectId || 0}`,
        projectPhase.GET_PROJECT_PHASE(
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
        '/setting',
    );
    const { data: projectCase, isFetching: isCaseFetching } = useGQLQuery(
        `GetProjectCase-${projectId || 0}`,
        projectCs.GET_PROJECT_CASE(
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
        '/setting',
    );

    const caseData = projectCase?.case;
    const phaseData = projectPhse?.phase;

    let caseAndPhaseData: CasePhaseType[] = [];
    if (caseData && phaseData && (caseData.length !== phaseData.length)) {
        caseAndPhaseData = caseData.length > phaseData.length ? caseData : phaseData;
    } else if (caseData && !phaseData) {
        caseAndPhaseData = caseData;
    } else if (phaseData && !caseData) {
        caseAndPhaseData = phaseData;
    }

    const { data: projectWbs, isFetching: isWbsFetching } = useGQLQuery(
        `GetWbsInfo-${projectId || 0}`,
        wbsQueries.GET_WBS_INFO(
            0,
            999,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );
    const wbsDatas = projectWbs?.wbs?.data;
    const wbsData = wbsDatas?.filter((data) => data.level === 1);
    const wbsDirect = wbsData ? wbsData?.filter((data) => data.class === 'D') : [];
    const wbsIndirect = wbsData ? wbsData?.filter((data) => data.class === 'I') : [];
    const wbsDirectTotalCost = wbsDirect?.reduce((total, item) => total + (item.total_cost || 0), 0);
    const wbsIndirectTotalCost = wbsIndirect?.reduce((total, item) => total + (item.total_cost || 0), 0);
    const totalWbsValue = wbsDirectTotalCost + wbsIndirectTotalCost;

    const emmptyWbsDirectRow = {
        code: '',
        description: 'Direct Cost',
        total_cost: 0,
        heading: true,
    };

    const totalWbsRow = {
        code: '',
        description: 'Total Direct Cost',
        total_cost: wbsDirectTotalCost,
        heading: true,
    };

    const wbsDirectRowData = (wbsDirect.length) ? [
        emmptyWbsDirectRow,
        ...wbsDirect,
        totalWbsRow,
    ] : [];

    const emptywbsIndirectRow = {
        code: '',
        description: 'Indirect Cost',
        total_cost: 0,
        heading: true,
    };

    const totalWbsIndirectRow = {
        code: '',
        description: 'Total Indirect Cost',
        total_cost: wbsIndirectTotalCost,
        heading: true,
    };

    const wbsIndirectRowData = (wbsIndirect.length) ? [
        emptywbsIndirectRow,
        ...wbsIndirect,
        totalWbsIndirectRow,
    ] : [];

    const wbsRowData = [
        ...wbsDirectRowData,
        ...wbsIndirectRowData,
    ];

    const { data: projectCoa, isFetching: isCoaFetching } = useGQLQuery(
        `GetCOAInfo-${projectId || 0}`,
        coaQueries.GET_COA_INFO(
            0,
            999,
            '',
            '',
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const coaDatas = projectCoa?.coa?.data;
    const coaData = coaDatas?.filter((data) => data.level === 1);
    const coaDirect = coaData ? coaData?.filter((data) => data.class === 'D') : [];
    const coaIndirect = coaData ? coaData?.filter((data) => data.class === 'I') : [];
    const coaDirectTotalCost = coaDirect?.reduce((total, item) => total + (item.total_cost || 0), 0);
    const coaIndirectTotalCost = coaIndirect?.reduce((total, item) => total + (item.total_cost || 0), 0);
    const totalValue = coaDirectTotalCost + coaIndirectTotalCost;
    const emptyCoaDirectRow = {
        code: '',
        description: 'Direct Cost',
        total_cost: 0,
        heading: true,
    };

    const totalCoaDirectRow = {
        code: '',
        description: 'Total Direct Cost',
        total_cost: coaDirectTotalCost,
        heading: true,
    };

    const coaDirectRowData = (coaDirect.length) ? [
        emptyCoaDirectRow,
        ...coaDirect,
        totalCoaDirectRow,
    ] : [];

    const emptyCoaIndirectRow = {
        code: '',
        description: 'Indirect Cost',
        total_cost: 0,
        heading: true,
    };

    const totalCoaIndirectRow = {
        code: '',
        description: 'Total Indirect Cost',
        total_cost: coaIndirectTotalCost,
        heading: true,
    };

    const coaIndirectRowData = (coaIndirect.length) ? [
        emptyCoaIndirectRow,
        ...coaIndirect,
        totalCoaIndirectRow,
    ] : [];

    const coaRowData = [
        ...coaDirectRowData,
        ...coaIndirectRowData,
    ];

    const currData = projectCtx?.projectCurrencyData?.getprojectCurrency;
    const totalCurrencyVal = currData && currData?.reduce((accumulator, object) => accumulator + object.total_cost, 0);

    const columnDefsForCurrency = [
        {
            field: 'code', type: 'string', width: 130, headerName: 'Code',
        },
        {
            field: 'name', type: 'string', width: 150, headerName: 'Currency',
        },
        {
            field: 'exchange_rate', type: 'number', headerName: 'FX Rate', width: 130,
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            headerName: `Total(${displayCurr?.code || ''})`,
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            width: 190,
        },
    ];

    const { data: projectResources, isFetching: isResourceFetching } = useGQLQuery(
        `GetResourceInfo-${projectId || 0}`,
        resourcesQueries.GET_RESOURCES_INFO(
            '',
            0,
            25,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            true,
        ),
        {},
        {
            onError: handleApiError,
        },
    );
    const resourcesData = projectResources?.resource?.data;

    const { data: projectUnitRates, isFetching: isUnitRateFetching } = useGQLQuery(
        `GetUnitRateInfo-${projectId || 0}`,
        unitRateQueries.GET_UNIT_RATE_INFO(
            0,
            25,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            true,
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const unitRatesData = projectUnitRates?.unitrate?.data;

    const { data: projectLabourAndCrew, isFetching: isLabourAndCrewFetching } = useGQLQuery(
        `GetLabourAndCrewRate-${projectId || 0}`,
        labourQueries.GET_LABOUR_AND_CREW_INFO(
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const labourAndCrewData = projectLabourAndCrew?.labour_crew_rates?.data;

    const totalLabourValue = projectLabourAndCrew?.labour_crew_rates;

    type RowData = {
        id?: string,
        code?: string,
        description?: string,
        exchange_rate?: number,
        total_cost?: number,
        name?: string,
        value?: number,
        isBaseCurrency?: boolean,
        symbol?: string,
    };

    let wbsChartData; let coaChartData; let currencyChartData;

    const convertChartSpecificData = (rowData: RowData[]) => {
        const dataDonut: { label: string, value: number }[] = [];
        rowData.forEach((data: RowData) => {
            // wbs & coa chart data
            if (data.code && data.description) {
                dataDonut.push({
                    label: `${data?.code} ${data.description}`,
                    value: data.total_cost && exchangeRate ? (+(data.total_cost * exchangeRate).toFixed(0)) : 0,
                });
            } else if (data.code && data.exchange_rate) {
                // currency chart data
                dataDonut.push({
                    label: `${data.code} ${data.name ? data.name : ''}`,
                    value: data.total_cost && exchangeRate ? (+(data.total_cost * exchangeRate).toFixed(0)) : 0,
                });
            }
        });
        return dataDonut;
    };

    if (wbsData) {
        wbsChartData = convertChartSpecificData(wbsData);
    }
    if (coaData) {
        coaChartData = convertChartSpecificData(coaData);
    }
    if (currData) {
        currencyChartData = convertChartSpecificData(currData);
    }
    let formattedDate = '';

    if (projectCtx?.project?.base_date) {
        const date = new Date(projectCtx?.project?.base_date);
        const year = projectCtx?.project?.base_date?.split('-')[0];
        const month = date.toLocaleDateString('default', { month: 'short' });
        formattedDate = `${month} ${year}`;
    }

    const createData = (params: number, value: string, checkHeading: boolean) => [
        {
            description: value,
            total_cost: params,
            heading: checkHeading,
        },
    ];

    const createCurrencyData = (params: number, value: string) => [
        {
            exchange_rate: value,
            total_cost: params,
        },
    ];

    const createLabourData = (params: TotalValueType, value: string) => [
        {
            account_description: value,
            labour_hours: params?.total_labour_hours,
            labour_rate: params?.total_labour_rate,
            const_distribution: params?.total_const_distribution,
            sub_total: params?.total_sub_total,
            const_equip: params?.total_const_equip,
            total: params?.total_total,
        },
    ];

    let pinnedBottomCurrRowData; let pinnedBottomLabourRowData;

    const pinnedBottomRowData = createData(totalValue, 'Total', true);

    if (totalCurrencyVal) {
        pinnedBottomCurrRowData = createCurrencyData(totalCurrencyVal, 'Total');
    }

    const pinnedBottomWbsRowData = createData(totalWbsValue, 'Total', true);

    if (totalLabourValue) {
        pinnedBottomLabourRowData = createLabourData(totalLabourValue.total_values, 'Total Direct Labour Hours');
    }

    // Adding offset to avoid element getting hidden under header
    const scrollWithOffset = (el: HTMLElement) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };
    return (
        <Fragment>
            <DashboardHeader />
            <Box className={classes.container} mt={4}>
                <Box className={classes.containerSec}>
                    <Typography className={classes.label} variant="h5">Project Information</Typography>
                    <Box className={classes.projectSec}>
                        <div className={classes.row}>
                            <div className={classes.projectDetail}>
                                <div className={classes.redSec} />
                                <div>
                                    <Typography className={classes.title}>Estimate</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.estimate_type}</Typography>
                                </div>
                            </div>
                            <div className={classes.projectDetail}>
                                <div className={classes.redSec} />
                                <div>
                                    <Typography className={classes.title}>Accuracy</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.estimate_accuracy}</Typography>
                                </div>
                            </div>
                            <div className={classes.projectDetail}>
                                <div className={classes.redSec} />
                                <div>
                                    <Typography className={classes.title}>Base Date</Typography>
                                    <Typography className={classes.value}>{formattedDate}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.projectDetail}>
                                <div className={classes.blueSec} />
                                <div>
                                    <Typography className={classes.title}>Industry</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.industry?.description}</Typography>
                                </div>
                            </div>
                            <div className={classes.projectDetail}>
                                <div className={classes.blueSec} />
                                <div>
                                    <Typography className={classes.title}>Owner</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.owner}</Typography>
                                </div>
                            </div>
                            <div className={classes.projectDetail}>
                                <div className={classes.blueSec} />
                                <div>
                                    <Typography className={classes.title}>Consultant</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.consultant}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.projectDetail}>
                                <div className={classes.yellowSec} />
                                <div>
                                    <Typography className={classes.title}>Location</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.country?.name}</Typography>
                                </div>
                            </div>
                            <div className={classes.projectDetail}>
                                <div className={classes.yellowSec} />
                                <div>
                                    <Typography className={classes.title}>Currency</Typography>
                                    <Typography className={classes.value}>{projectCtx?.project?.currency_data?.code}</Typography>
                                </div>
                            </div>
                            <div className={classes.projectDetail}>
                                <div className={classes.yellowSec} />
                                <div>
                                    <Typography className={classes.title}>Contingency</Typography>
                                    <Typography className={classes.value}>
                                        {projectCtx?.project?.contingency_wbs}
                                        %
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.containerSec}>
                    <HashLink
                        smooth
                        to={capex.settings.structure}
                        scroll={(el: HTMLElement) => scrollWithOffset(el)}
                        className={classes.labelLink}
                    >
                        Cases/Phases

                    </HashLink>
                    <Box className={classes.casesSec}>
                        <AgGridComponent
                            columnDefs={columnDefsForCasesAndPhases}
                            rowData={caseAndPhaseData}
                            changeSortingValue={changeSortingValue}
                            noSelection
                            isExportEnabled
                            isClipboardEnabled
                            isRangeSelectable
                            moduleName="projectDashboardCasesAndPhases"
                            colFormat={['total_cost']}
                        />
                        <Loader loading={isFetching || isCaseFetching || isWbsFetching || isCoaFetching || isResourceFetching
                            || isUnitRateFetching || isLabourAndCrewFetching}
                        />
                    </Box>
                </Box>
            </Box>
            <Box mt={4}>
                <Link className={classes.labelLink} to={capex.wbs}>WBS Level 1</Link>
                {wbsData ? (
                    <Box className={classes.container}>
                        <Box className={classes.containerElement}>
                            <AgGridComponent
                                columnDefs={columnDefsForWbs}
                                rowData={wbsRowData}
                                changeSortingValue={changeSortingValue}
                                pinnedBottomRowData={pinnedBottomWbsRowData}
                                noSelection
                                isExportEnabled
                                isClipboardEnabled
                                isRangeSelectable
                                moduleName="projectDashboardWbs"
                                colFormat={['total_cost']}
                                footerFormat={['total_cost']}
                            />
                        </Box>
                        {wbsData.length && wbsChartData && totalWbsValue
                            ? (
                                <Box className={classes.containerdonutElement}>
                                    <DonutChat chartData={wbsChartData} innerRd={80} outerRd={100} height={370} />
                                </Box>
                            ) : (
                                <Box className={classes.containerElement}>No Chart Data to Show</Box>
                            )}
                    </Box>
                ) : (
                    <Box className={`${classes.noDataSec} ${classes.wbsHeight}`}>
                        No Data to show
                    </Box>
                )}
            </Box>
            <Box mt={4}>
                <Link className={classes.labelLink} to={capex.coa}>COA Level 1</Link>
                {coaData ? (
                    <Box className={classes.container}>
                        <Box className={classes.containerElement}>
                            <AgGridComponent
                                columnDefs={columnDefsForCoa}
                                rowData={coaRowData}
                                changeSortingValue={changeSortingValue}
                                pinnedBottomRowData={pinnedBottomRowData}
                                noSelection
                                isExportEnabled
                                isClipboardEnabled
                                isRangeSelectable
                                moduleName="projectDashboardCoa"
                                colFormat={['total_cost']}
                                footerFormat={['total_cost']}
                            />
                        </Box>
                        {coaData.length && coaChartData && totalValue
                            ? (
                                <Box className={classes.containerdonutElement}>
                                    <DonutChat chartData={coaChartData} innerRd={80} outerRd={100} height={370} />
                                </Box>
                            ) : (
                                <Box className={classes.containerElement}>No Chart Data to Show</Box>
                            )}
                    </Box>
                ) : (
                    <Box className={`${classes.noDataSec} ${classes.wbsHeight}`}>
                        No Data to show
                    </Box>
                )}
            </Box>
            <Box mt={4}>
                <HashLink
                    smooth
                    className={classes.labelLink}
                    to={capex.settings.currency}
                    scroll={(el: HTMLElement) => scrollWithOffset(el)}
                >
                    Currency

                </HashLink>
                {currData ? (
                    <Box className={classes.container}>
                        <Box className={classes.currencySec}>
                            <AgGridComponent
                                columnDefs={columnDefsForCurrency}
                                rowData={currData}
                                changeSortingValue={changeSortingValue}
                                pinnedBottomRowData={pinnedBottomCurrRowData}
                                noSelection
                                isExportEnabled
                                isClipboardEnabled
                                isRangeSelectable
                                moduleName="projectDashboardCurrency"
                                colFormat={['total_cost']}
                                footerFormat={['total_cost']}
                            />
                        </Box>
                        {currData && currencyChartData && totalCurrencyVal
                            ? (
                                <Box className={classes.currencydonutSec}>
                                    <DonutChat chartData={currencyChartData} height={260} />
                                </Box>
                            ) : (
                                <Box className={classes.currencySec}>No Chart Data to Show</Box>
                            )}
                    </Box>
                ) : (
                    <Box className={`${classes.noDataSec} ${classes.wbsHeight}`}>
                        No Data to show
                    </Box>
                )}
            </Box>
            <Box mt={4}>
                <Link className={classes.labelLink} to={capex.coa}>Labour & Crew Rates</Link>
                {labourAndCrewData ? (
                    <Box className={classes.labourSec}>
                        <AgGridComponent
                            columnDefs={columnDefsForLabourAndCrewRates}
                            rowData={labourAndCrewData}
                            pinnedBottomRowData={pinnedBottomLabourRowData}
                            changeSortingValue={changeSortingValue}
                            noSelection
                            isExportEnabled
                            isClipboardEnabled
                            isRangeSelectable
                            moduleName="projectDashboardLabourAndCrewRates"
                            colFormat={['labour_rate', 'const_distribution', 'sub_total', 'const_equip', 'total']}
                            footerFormat={['labour_rate', 'const_distribution', 'sub_total', 'const_equip', 'total']}
                            disableResizable
                        />
                    </Box>
                ) : (
                    <Box className={`${classes.noDataSec} ${classes.wbsHeight}`}>
                        No Data to show
                    </Box>
                )}
            </Box>
            <Box mt={4}>
                <Link className={classes.labelLink} to={capex.unit}>Unit Rates</Link>
                {unitRatesData ? (
                    <Box className={classes.labourSec}>
                        <AgGridComponent
                            columnDefs={columnDefsForUnitRates}
                            rowData={unitRatesData}
                            changeSortingValue={changeSortingValue}
                            noSelection
                            isExportEnabled
                            isClipboardEnabled
                            isRangeSelectable
                            moduleName="projectDashboardUnitRates"
                            colFormat={['cost_in_estimate', 'unit_cost']}
                        />
                    </Box>
                ) : (
                    <Box className={`${classes.noDataSec} ${classes.wbsHeight}`}>
                        No Data to show
                    </Box>
                )}
            </Box>
            <Box mt={4}>
                <Link className={classes.labelLink} to={capex.resources}>Resources</Link>
                {resourcesData ? (
                    <Box className={classes.labourSec}>
                        <AgGridComponent
                            columnDefs={columnDefsForResources}
                            rowData={resourcesData}
                            changeSortingValue={changeSortingValue}
                            noSelection
                            isExportEnabled
                            isClipboardEnabled
                            isRangeSelectable
                            moduleName="projectDashboardResources"
                            colFormat={['total_cost', 'cost_per_unit']}
                        />
                    </Box>
                ) : (
                    <Box className={`${classes.noDataSec} ${classes.wbsHeight}`}>
                        No Data to show
                    </Box>
                )}
            </Box>
        </Fragment>
    );
}

export default ProjectDashboard;
