import { Auth0Provider } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';

type Props = {
    children: ReactNode
};

function Auth0ProviderWithHistory(props: Props) {
    const { children } = props;
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const connection = process.env.REACT_APP_AUTH0_CONNECTION;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const scope = 'openid profile email';

    if (!(domain && clientId)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                connection,
                audience,
                scope,
            }}
        >
            {children}
        </Auth0Provider>
    );
}

export default Auth0ProviderWithHistory;
