const DEFAULT_PAGE_SIZE = 20;
const SUPER_ADMIN_ROLE = '1';
const ADMIN_ROLE = '2';
const USER_ROLE = '3';
const DEFAULT_SKIP_VALUE = 0;
const DEFAULT_TAKE_VALUE = 25;
const DEFAULT_REPORT_TAKE_VALUE = 99999;

const ROLES = [
    { id: '1', role: 'Super Admin' },
    { id: '2', role: 'Admin' },
    { id: '3', role: 'User' },
];

export {
    DEFAULT_PAGE_SIZE,
    SUPER_ADMIN_ROLE,
    ADMIN_ROLE,
    USER_ROLE,
    ROLES,
    DEFAULT_SKIP_VALUE,
    DEFAULT_TAKE_VALUE,
    DEFAULT_REPORT_TAKE_VALUE,
};
