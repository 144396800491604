import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    undoButtn: {
        width: '40%',
    },
    label: {
        fontWeight: '700 !important',
    },
    labelLink: {
        color: '#007DFF',
        fontSize: '18px',
        fontFamily: 'Inter-Medium',
        fontWeight: '700 !important',
        textDecoration: 'underline',
    },
    projectSec: {
        height: '226px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        marginTop: '16px',
        padding: '0px 24px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '226px !important',
            },
        },
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '33%',
    },
    projectDetail: {
        display: 'flex',
        flexBasis: '33%',
    },
    redSec: {
        width: '5px',
        height: '40px',
        borderRadius: '5px',
        backgroundColor: '#FF4B55',
        marginRight: '8px',
    },
    blueSec: {
        width: '5px',
        height: '40px',
        borderRadius: '5px',
        backgroundColor: '#4F4BFF',
        marginRight: '8px',
    },
    yellowSec: {
        width: '5px',
        height: '40px',
        borderRadius: '5px',
        backgroundColor: '#FFCA66',
        marginRight: '8px',
    },
    title: {
        color: '#737373 !important',
        fontFamily: 'Inter-Light !important',
        fontSize: '13px !important',
        marginTop: 'unset !important',
    },
    value: {
        fontSize: '16px !important',
        fontFamily: 'Inter-Medium !important',
        fontWeight: '600 !important',
        color: '#3D3D3D !important',
        marginTop: 'unset !important',
    },
    casesSec: {
        height: '226px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '25px',
        marginTop: '16px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '226px !important',
            },
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    containerElement: {
        width: '49%',
        height: '396px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '25px',
        marginTop: '16px',
        fontSize: '12px !important',
        fontWeight: 500,
        fontFamily: 'Inter-Medium !important',
        color: '#353535',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '396px !important',
            },
        },
        textAlign: 'center',
        lineHeight: '320px',
    },
    containerdonutElement: {
        width: '49%',
        height: '396px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '12px',
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    currencySec: {
        width: '49%',
        height: '286px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '25px',
        marginTop: '16px',
        fontSize: '12px !important',
        fontWeight: 500,
        fontFamily: 'Inter-Medium !important',
        color: '#353535',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '286px !important',
            },
        },
        textAlign: 'center',
        lineHeight: '300px',
    },
    currencydonutSec: {
        width: '49%',
        height: '286px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '12px',
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    labourSec: {
        height: '396px',
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '25px',
        marginTop: '16px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '396px !important',
            },
        },
    },
    containerSec: {
        width: '49%',
    },
    casesHeight: {
        height: '226px',
    },
    wbsHeight: {
        height: '396px',
    },
    currencyHeight: {
        height: '286px',
    },
    noDataSec: {
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        marginTop: '16px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        fontSize: '12px !important',
        fontWeight: 500,
        fontFamily: 'Inter-Medium !important',
        color: '#353535',
        '& img': {
            width: '150px',
        },
    },
    inTableBold: {
        fontWeight: '700',
        fontFamily: 'Inter-Medium',
    },
    rightSec: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& h2': {
            color: '#737373 !important',
            fontWeight: '400 !important',
        },
        '& button': {
            marginTop: '20px',
            fontSize: '16px',
            color: '#007DFF',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '7px 26px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
    '& .donutchart-legend-item-label': {
        fontSize: '13px',
        fontWeight: 500,
        fontFamily: 'Inter-Medium',
        color: '#353535',
    },
    '& .donutchart': {
        width: '100%',
        height: '310px',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
