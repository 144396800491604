import React, { useState } from 'react';
import useStyles from './UnitRateHome.style';
import {
    Box, Button, Typography, FormControl, MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import CostHeaderBar from '../../../../components/costHeaderBar';
import UnitRateDetailView from '../../Components/UnitRateDetailView';
import UnitRateListView from '../../Components/UnitRateListView';
import { useNavigate } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';

function UnitRateHomePage() {
    const classes = useStyles();
    const [view, setView] = useState('list');
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=unit rate`);
    };

    return (
        <div>
            <div className={classes.TableHeader}>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h2">
                        UNIT RATE
                    </Typography>
                    <CostHeaderBar />
                    <Box className={classes.versonUndo}>
                        <Box className={classes.outlineButton}>
                            <Button onClick={navigateToReports}>
                                <DataSaverOffOutlinedIcon />
                                {' '}
                                Reports
                            </Button>
                        </Box>
                        <FormControl variant="filled">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={view}
                                label="view"
                                onChange={handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                color: '#787878',
                                            },
                                            '& .Mui-selected': {
                                                color: '#021948',
                                                bgcolor: '#E6ECFA !important',
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="detail">Detail View</MenuItem>
                                <MenuItem value="list">List View</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </div>
            <Box>
                { view === 'detail' ? <UnitRateDetailView /> : <UnitRateListView />}
            </Box>
        </div>
    );
}

export default UnitRateHomePage;
