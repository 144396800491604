import wbsQuery from '../../queries/wbs';
import { DEFAULT_PAGE_SIZE, DEFAULT_REPORT_TAKE_VALUE } from '../../constants';
import packageQuery from '../../queries/packages';
import estimateQueries from '../../queries/estimate';
import resourcesQuery from '../../queries/resources';
import unitrateQuery from '../../queries/unitrates';
import scheduleQuery from '../../queries/schedule';
import projectInfoQuery from '../../queries/projectInfo';
import { GET_CURRENCY } from '../../queries/projectCurrency';
import projectPhaseQuery from '../../queries/phase';
import porjectCaseQuery from '../../queries/case';
import labourQuery from '../../queries/labourAndCrew';
import coaQuery from '../../queries/coa';
import projectQueryVersion from '../../queries/version';
import { GET_ALL_INDUSTRY, GET_INDUSTRIES } from '../../queries/industries';
import commoditiesQuery from '../../queries/commodities';
import projectRecentQuery from '../../queries/DashboardRecentProject';
import { GET_REGION } from '../../queries/region';
import projectQuery from '../../queries/projects';

interface ReportQueryDetails {
    query: string,
    jsPath: string,
    operationName?: string,
    endpoint?: string,
}

const getQueryDetails = (datasetName: string, orgId: string, projectId: string, versionId: string) => {
    const datasetFormatted = datasetName.toLowerCase();
    switch (datasetFormatted) {
    case 'wbs':
    case 'wbs info':
        return {
            module: 'WBS',
            query: wbsQuery.GET_WBS_INFO(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.wbs.data.*',
        };
    case 'coa':
    case 'coa info':
        return {
            module: 'COA',
            query: coaQuery.GET_COA_INFO(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.coa.data.*',
        };
    case 'packages':
        // Projects data
        return {
            module: 'Packages',
            query: packageQuery.GET_PACKAGES(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.package.data.packageData.*',
        };
    case 'estimate':
        // Estimates data
        return {
            module: 'Estimate',
            query: estimateQueries.GET_ESTIMATE_DETAILS(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.estimate_detail.data.estimatedData.*',
        };
    case 'resources':
        // Resources data
        return {
            module: 'Resources',
            query: resourcesQuery.GET_RESOURCES_INFO(
                '',
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.resource.data.*',
        };
    case 'unit rate':
        // Unit Rate data
        return {
            module: 'Unit Rate',
            query: unitrateQuery.GET_UNIT_RATE_LIST_VIEW_DATA(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.unitrate.data.*',
        };
    case 'unit cost':
        // Unit Cost data
        return {
            module: 'Unit Cost',
            query: unitrateQuery.GET_UNIT_RATE_DETAIL_DATA(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.unitrate.data[*].unit_cost_data.*',
        };
    case 'schedule':
        // Schedule data
        return {
            module: 'Schedule',
            query: scheduleQuery.GET_SCHEDULE_BY_ORGID(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.schedule.data.scheduleData.*',
        };
    case 'project info':
        return {
            endpoint: '/setting',
            module: 'Project Info',
            query: projectInfoQuery.GET_PROJECTIFO_BY_ID(
                projectId,
                orgId,
            ),
            jsPath: '.data.project_setting',
        };
    case 'project version':
        return {
            endpoint: '/setting',
            module: 'Project Version',
            query: projectQueryVersion.GET_PROJECT_VERSION(
                orgId,
                projectId,
            ),
            jsPath: '.data.version.*',
        };
    case 'project case':
        return {
            endpoint: '/setting',
            module: 'Project Case',
            query: porjectCaseQuery.GET_PROJECT_CASE(
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.case.*',
        };
    case 'project phase':
        return {
            endpoint: '/setting',
            module: 'Project phase',
            query: projectPhaseQuery.GET_PROJECT_PHASE(
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.phase.*',
        };
    case 'project currency':
        return {
            endpoint: '/setting',
            module: 'Project Currency',
            query: GET_CURRENCY(
                orgId,
                projectId,
            ),
            jsPath: '.data.getprojectCurrency.*',
        };
    case 'labour & crew rates':
        return {
            module: 'Labour & Crew Rates',
            query: labourQuery.GET_LABOUR_AND_CREW_INFO(
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.labour_crew_rates.data.*',
        };
    case 'industries':
        return {
            query: GET_ALL_INDUSTRY(
                orgId,
            ),
            jsPath: '.data.all_industries.data.*',
        };
    case 'industries info':
        return {
            module: 'Industries info',
            query: GET_INDUSTRIES(orgId),
            jsPath: '.data.user_industries.*',
        };
    case 'commodities':
        return {
            query: commoditiesQuery.GET_COMMODITIES_WITH_PROJECTS_BY_ORGID(
                orgId,
                0,
                DEFAULT_REPORT_TAKE_VALUE,
            ),
            jsPath: '.data.commodities_detail.data.*',
        };
    case 'projects':
        return {
            module: 'Projects',
            query: projectQuery.GET_PROJECTS(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                '',
                '',
            ),
            jsPath: '.data.project.data.*',
        };
    case 'recent projects':
        return {
            module: 'Recent Projects',
            query: projectRecentQuery.GET_RECENT_PROJECTS(0, DEFAULT_REPORT_TAKE_VALUE, '', orgId),
            jsPath: '.data.project.data.*',
        };
    case 'regions info':
        return {
            module: 'Regions info',
            query: GET_REGION(orgId),
            jsPath: '.data.regions.*',
        };
    default:
        return {
            query: '',
            jsPath: '',
            operationName: '',
        };
    }
};

const getQueryDetailsByDataset = (orgId: string, projectId: string, versionId: string, datasetName: string) => {
    const item = getQueryDetails(datasetName, orgId, projectId, versionId) as ReportQueryDetails;
    const endpoint = item.endpoint ? item.endpoint : '/list';
    const body = {
        operationName: item.operationName,
        query: item.query,
    };
    return `uri=${endpoint};jpath=$${item.jsPath};method=POST;body=${JSON.stringify(body)};`
    + 'Header$Content-Type=application/json;';
};

export default getQueryDetailsByDataset;
