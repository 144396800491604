import React, { useContext, useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../store/context/userContext';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ContactAdmin from '../screens/ContactAdmin';
import SwitchOrganizationModel from '../components/navbar/components/SwitchOrganizationModel';
import { withSnackbar } from '../hooks/useSnackbarHOC';
import { useUserRole } from '../hooks/useUserRole';
import { ROLES } from '../constants';

const drawerWidth = 237;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: '90px',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

import { makeStyles } from '@mui/styles';
import useIsSuperAdmin from '../hooks/isSuperAdmin';
import { getOrgIdFromLocalStorage } from './StorageHelper';

const tableWrapperStyle = makeStyles(() => ({
    tableSection: {
        '& .MuiPaper-root': {
            boxShadow: 'none',
            borderRight: 'none',
            padding: '0px',
            margin: '0px',
        },
        '& .grui-listitem.grui-odd': {
            backgroundColor: '#F8F8F8',
        },
        '& .grui-selected': {
            backgroundColor: '#C1D6FF !important',
        },
        '& .grui-listitem': {
            backgroundColor: '#F8F8F8',
            borderColor: '#CECECE',
            color: '#050505',
            borderBottom: 'none',
            '&:last-child': {
                borderBottom: '1px solid #CECECE',
            },
        },
        '& .grui-dock': {
            border: '1px solid #CECECE',
            borderRadius: '15px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
        },
        '& .grui-title-el': {
            color: '#8F8F8F',
            fontFamily: 'Inter-Bold',
            fontSize: '14px',
        },
        '& .grui-gridcell-body-el': {
            '& div': {
                padding: '5px 12px !important',
                fontSize: '14px',
            },
        },
        '& .grui-gridcolumn-title-wrap-el': {
            padding: '8px 12px',
        },
        '& .grui-headercontainer': {
            borderBottom: '0px',
        },
        '& .grui-rownumberercell .grui-rownumberercell-body-el': {
            padding: '5px',
        },
        '& .grui-gridrow': {
            minHeight: '29px',
        },
        '& .grui-header-el': {
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
            borderColor: '#CECECE',
        },
        '& .grui-layout-box': {
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
        },
    },
    tableStyleAgGrid: {
        '& .ag-root-wrapper': {
            border: '1px solid #CECECE',
            borderRadius: '4px 4px 14.23px 14.23px',
        },
        '& .ag-header': {
            minHeight: '31px !important',
            height: 'auto !important',
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
            borderBottom: '1px solid #CECECE',
        },
        '& .ag-header-row': {
            height: '31px !important',
        },
        '& .ag-header-cell-label': {
            fontWeight: '600',
            color: '#8F8F8F',
            fontSize: '13px',
            // commented below line as value field is not aligned to right
            // flexDirection: 'row !important',
        },
        '& .ag-header-cell': {
            borderRight: '1px solid #CECECE',
        },
        '& .ag-row': {
            borderBottom: '1px solid #CECECE',
            backgroundColor: '#F8F8F8',
            color: '#353535',
            fontSize: '13px',
            fontWeight: '400',
        },
        '& .ag-cell': {
            '& .ag-icon': {
                display: 'none',
            },
        },
        '& .ag-checkbox-input-wrapper': {
            fontSize: '12px',
            lineHeight: 'normal',
            width: '12px',
            height: '12px',
            backgroundColor: '#fff',
            '&:after': {
                color: '#B9B9B9',
            },
        },
    },
}));

/**
 * Adding Notification bar HOC
 * @param param0
 * @returns
 */
function OutletWrapper({ snackbarShowMessage }:{ snackbarShowMessage: (message: string, type: string) => void }) {
    const showNotificationBar = (message: string, type: string) => {
        snackbarShowMessage(message, type);
    };

    return <Outlet context={{ showNotificationBar }} />;
}

const OutletWrapped = withSnackbar(OutletWrapper);

function ProtectedRouteWrapper(props:{ open: boolean }) {
    const { open } = props;
    const userContext = useContext(UserContext);
    const classes = tableWrapperStyle();
    const isSuperAdmin = useIsSuperAdmin();
    const selectedOrg = getOrgIdFromLocalStorage();
    const [switchOrgOpen, setSwitchOrgOpen] = useState(!selectedOrg);
    const navigate = useNavigate();
    const location = useLocation();
    const userOrgRole = useUserRole();

    useEffect(() => {
        const userRole = ROLES.find((role) => role.id === userOrgRole);
        if ((location.pathname === '/organisation' || location.pathname === '/organisations') && userRole?.role === 'User') {
            navigate('/');
        }
    }, [location.pathname, userOrgRole]);

    // If user's organisation is disabled then, show admin page
    if (userContext && userContext.user && !userContext.user.default_org_id && !isSuperAdmin) {
        return <ContactAdmin />;
    }

    // No need to show switch organisation when user is a part of only one organisation
    const userOrganisations = userContext && userContext.user && userContext.user.orgnizations ? userContext.user.orgnizations : [];

    if (!userContext?.user) {
        return null;
    }

    return (
        <Box className={classes.tableStyleAgGrid}>
            <Main open={open}>
                <Box
                    component="main"
                    style={{
                        width: 'calc(100% - 300px)',
                        marginLeft: '237px',
                        padding: '0px 30px 30px 30px',
                    }}
                    className={classes.tableSection}
                >
                    <OutletWrapped />
                    { userOrganisations.length > 1 ? (
                        <SwitchOrganizationModel open={switchOrgOpen} setOpen={setSwitchOrgOpen} />
                    ) : null}
                </Box>
            </Main>
        </Box>
    );
}

export default ProtectedRouteWrapper;
