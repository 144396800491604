/**
 * Function to encode values before storing in local storage
 * @param token
 * @returns
 */
function encodeToken(token: string) {
    return btoa(unescape(encodeURIComponent(token)));
}

/**
 * Function to decode values taken from local storage
 * @param token
 * @returns
 */
function decodeToken(token: string) {
    return decodeURIComponent(escape(atob(token)));
}

/**
 * Function to encode items and set in local storage
 * @param item
 * @param value
 */
function setItemInLocalStorage(item: string, value: string) {
    localStorage.setItem(item, value);
}

/**
 * Function to fetch and decode items from local storage
 * @param item
 * @returns
 */
function getItemFromLocalStorage(item: string) {
    const value = localStorage.getItem(item);
    if (value) {
        const decodedValue = decodeToken(value);
        return decodedValue;
    }
    return value;
}

/**
 * Get Item from local storage
 * @param item
 * @returns : data | undefined
 */
function getItemDataFromLocalStorage(item: string) {
    return localStorage.getItem(item);
}

function removeItemFromStorage(item: string) {
    localStorage.removeItem(item);
}

/**
 * Set Encoded Data to localstorage
 * @param item
 * @returns : data | undefined
 */
function setEncodedItemDataToLocalStorage(item: string, value: string) {
    const encodedValue = encodeToken(value);
    localStorage.setItem(item, encodedValue);
}

/**
 * Get Encoded Item from local storage
 * @param item
 * @returns : data | undefined
 */
function getDecodedItemDataFromLocalStorage(item: string) {
    const value = localStorage.getItem(item);
    if (!value) return value;
    let returnValue = null;
    try {
        returnValue = decodeToken(value);
    } catch (error) {
        console.log(error);
    }
    return returnValue;
}

/**
 * Function to set access token in local storage
 * @returns
 */
function setAccessTokenInLocalStorage(token: string) {
    return setItemInLocalStorage('access_token', token);
}

/**
 * Function to fetch access token from local storage
 * @returns
 */
function getAccessTokenFromLocalStorage() {
    return getItemFromLocalStorage('access_token');
}

function setOrgIdInLocalStorage(orgId: string) {
    const encodedValue = encodeToken(orgId);
    setItemInLocalStorage('org_id', encodedValue);
}

function getOrgIdFromLocalStorage() {
    return getItemFromLocalStorage('org_id');
}

function clearOrgIdFromLocalStorage() {
    localStorage.removeItem('org_id');
}

function clearAccessTokenOnLogout() {
    localStorage.clear();
}

/**
 * Set Default Organization Id from local storage
 * @param org_id String
 */
function setDefaultOrgIDInLocalStorage(default_org_id: string | undefined) {
    setItemInLocalStorage('default_org_id', default_org_id || '');
}

/**
 * Get Default Organization Id from local storage
 * @param org_id String
 */
function getDefaultOrgIDInLocalStorage() {
    return localStorage.getItem('default_org_id');
}

/**
 * Remove Default Organization Id from local storage
 * @param org_id String
 */
function removeDefaultOrgIDInLocalStorage() {
    localStorage.removeItem('default_org_id');
}

function JSONParse<T>(string: string | undefined) {
    return JSON.parse(string || '{}') as T;
}

function getBackendEndpoint() {
    return process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/api/graphql';
}

export {
    getAccessTokenFromLocalStorage,
    setAccessTokenInLocalStorage,
    clearAccessTokenOnLogout, encodeToken, decodeToken, setOrgIdInLocalStorage, getOrgIdFromLocalStorage,
    clearOrgIdFromLocalStorage,
    // Get Set Remove Default Organization ID.
    setDefaultOrgIDInLocalStorage, getDefaultOrgIDInLocalStorage, removeDefaultOrgIDInLocalStorage,
    // Parse JSON to any type
    JSONParse,
    // Generic Set/Get Item from LocalStorage
    getItemDataFromLocalStorage, setItemInLocalStorage, removeItemFromStorage,
    // Encoded Get Item from LocalStorage
    getDecodedItemDataFromLocalStorage, setEncodedItemDataToLocalStorage,
    getBackendEndpoint,
};
