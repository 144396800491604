// Coa page styles
import { makeStyles } from '@mui/styles';

const COADifferent = makeStyles({
    heirarchystyl: {
        fontSize: '15.33px',
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
});
export default COADifferent;
