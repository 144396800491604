import PublicRouter from './Public';
import ProtectedRouter from './Protected';
import { useRoutes, RouteObject } from 'react-router-dom';

export default function Router({ open }:{ open: boolean }) {
    const publicRoutes: RouteObject[] = PublicRouter();
    const protectedRoutes: RouteObject[] = ProtectedRouter(open);

    const ComposedRouter = useRoutes([
        ...publicRoutes,
        ...protectedRoutes,
    ]);

    return ComposedRouter;
}
