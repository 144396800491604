import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import '@sencha/best-react-grid/dist/themes/grui.css';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Loader from '../../../../components/Loader';
import coaQueries from '../../../../queries/coa';
import Pagination from '../../../../components/paginationTable';
import { ColumnDefs } from '../../../../types/AgGridTypes';
import COADifferent from './COADifferent.style';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { useOutletContext } from 'react-router-dom';
import CoaColumn from './CoaColumn';
import { JSONParse } from '../../../../helper/StorageHelper';
import { truthyCodeValidation } from '../../../../helper/validations';

interface ViewsTotalObject {
    labour_hoursTotal: number,
    labourTotal: number,
    distributableTotal: number,
    c_equipmentTotal: number,
    materialTotal: number,
    sustaining_total: number,
    equipmentTotal: number,
    subcontractTotal: number,
    indirectTotal: number,
    total_costTotal: number,
    name: string,
    total_cost: number,
}
type CaseObject = {
    description: string;
    [key: string]: string | number;
  };
interface ViewsDataObject {
    code:string,
    name: string,
    date:string,
    updated_by: string,
    target: {
        value: string,
        checked: boolean
    }
    id: string,
    isCurrentVersion: boolean,
    length: number,
    code1: string,
    code2: string,
    code3: string,
    code4: string,
    code5: string,
    code6: string,
}

interface ViewsDataObjectExtended extends ViewsDataObject {
    [key: string]: string | number | boolean | {
        value: string,
        checked: boolean
    }
}

interface Props {
    view?: string,
}

function CoaVersionViews(props: Props) {
    const { view } = props;
    const classes = COADifferent();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefs[]>([]);
    const [colHeader, setColHeader] = useState<string[]>([]);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    // Function for Total bottom row
    const createVersionData = (params: ViewsTotalObject[]) => {
        const result: CaseObject = {
            description: 'TOTAL',
        };
        for (let i = 0; i < params.length; i += 1) {
            result[`column_${params[i].name}`] = params[i].total_cost && (+(params[i].total_cost) * exchangeRate).toFixed(0);
        }
        return [result];
    };

    // Fetch new page data
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    // Fetch API data
    const { data, isFetching, refetch } = useGQLQuery(
        `GetCOAVersion-${projectCtx?.project?.id || 0}`,
        coaQueries.GET_COA_VERSION(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
        ),
        {},
        { onError: handleApiError },
    );
    // For COA Version View...
    const versionViewData = data?.coaversion;
    const tableVersionData = versionViewData?.data;
    const tableVersionViewData: ViewsDataObject[] = tableVersionData ? JSONParse<ViewsDataObject[]>(tableVersionData?.toString()) : [];
    const versionPageInfo = versionViewData?.pageInfo;
    const versionTotalValue: ViewsTotalObject[] = versionViewData ? JSONParse<ViewsTotalObject[]>(versionViewData.total_values.toString()) : [];

    // For Bottom Total Value...
    let versionPinnedBottomRowData;
    if (versionTotalValue && versionTotalValue.length > 0) {
        versionPinnedBottomRowData = createVersionData(versionTotalValue);
    }

    useEffect(() => {
        refetch();
    }, [pageSkipValue]);

    const columnDefs = [
        {
            field: 'code',
            headerName: 'COA',
            cellRenderer: CoaColumn,
            sort: 'asc',
            width: 120,
            type: 'string',
            hide: false,
        },
        {
            field: 'description',
            headerName: 'Account Description',
            cellRenderer: CoaColumn,
            width: 400,
            type: 'string',
            hide: false,
        },
    ];

    useEffect(() => {
        setNewColumnDefs(columnDefs);
    }, [view]);

    const versionTotalPages = (versionPageInfo && versionPageInfo.totalcount) ? Math.ceil(versionPageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    // Add dynamic column for version view
    const versionDynamicCol = () => {
        if (tableVersionViewData && tableVersionViewData.length > 0 && view === 'Version View') {
            setColHeader([]);
            const tempArr: string[] = [];
            Object.keys(tableVersionViewData[0]).forEach((item) => {
                if (item.startsWith('column_')) {
                    const ak = item.split('_')[1].replaceAll('_', ' ');
                    tempArr.push(`${item}`);
                    columnDefs.push(
                        {
                            field: `${item}`,
                            headerName: ak,
                            cellRenderer: CoaColumn,
                            // hide: view !== 'Version View',
                            width: 150,
                            type: 'numericColumn',
                            hide: false,
                        },
                    );
                    setNewColumnDefs(columnDefs);
                }
            });
            setColHeader([...colHeader, ...tempArr]);
        }
    };

    const generateColumnDefs = () => {
        ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].forEach((code, index) => {
            const flag = (tableVersionViewData?.map((item) => ((item as ViewsDataObjectExtended)[code])))?.some(truthyCodeValidation);
            if (flag) {
                columnDefs.push({
                    field: code,
                    headerName: `Code${index + 1}`,
                    width: 120,
                    cellRenderer: CoaColumn,
                    type: 'string',
                    hide: flag,
                });
            }
        });
        setNewColumnDefs(columnDefs);
    };

    useEffect(() => {
        versionDynamicCol();
        generateColumnDefs();
    }, [view, isFetching]);

    return (
        <div>
            {/* Page Header Start */}
            <Loader loading={isFetching} />
            <Box className={isFetching ? classes.opacityBlur : ''}>
                <AgGridComponent
                    columnDefs={newColumnDefs}
                    rowData={tableVersionViewData}
                    changeSortingValue={() => {}}
                    pinnedBottomRowData={versionPinnedBottomRowData}
                    isClipboardEnabled
                    isExportEnabled
                    isRangeSelectable
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    isGroupable
                    applyRowBgStyle
                    moduleName="coa_versionview"
                    colFormat={colHeader}
                />
            </Box>
            <Box>
                <Pagination
                    totalPages={versionTotalPages}
                    handlePageChange={handlePageChange}
                />
            </Box>
        </div>
    );
}

export default CoaVersionViews;
