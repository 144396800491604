/* eslint-disable no-param-reassign */
import React, {
    useRef, useContext, LegacyRef,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import Paper from '@mui/material/Paper';
import { Box, styled } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AgGridTableStyle from './AgGridTable.style';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    ColumnDefs, RowData, CellRendererType, IDetails,
} from '../../types/AgGridTypes';
import {
    ColDef, ColumnMenuTab, GridOptions, RowClassParams, MenuItemDef, ColumnState,
} from 'ag-grid-community';
import OrganisationDetailType from '../../types/OrganisationDetailType';
import GetOrgUser from '../../types/GetOrgUser';
import ProjectListType from '../../types/ProjectListType';
import sortUpIcon from '../../assets/sortUpIcon.svg';
import sortDownIcon from '../../assets/sortDownIcon.svg';
import Loader from '../Loader';
import { UserContext } from '../../store/context/userContext';
import { JSONParse } from '../../helper/StorageHelper';
import { ReportType } from '../../types/Reports';
import { ProjectContext } from '../../store/context/projectContext';
import { useLocation } from 'react-router-dom';

interface DynamicType {
    [key: string]: string | number,
}

interface LocationState {
    pathname: string;
}

const allMenuTabs: ColumnMenuTab[] = ['generalMenuTab', 'filterMenuTab'];

interface GridState {
    state: ColumnState[],
    filter: { [key: string]: any },
}
interface AgGridComponentProps {
    columnDefs: ColumnDefs[]; // Define your column definitions
    rowData: RowData[]; // Define your row data
    sortOrder?: string;
    changeSortingValue: (par: string) => void;
    gridRef?: React.RefObject<AgGridReact<OrganisationDetailType>>
    | (React.RefObject<AgGridReact<GetOrgUser>>) | (React.RefObject<AgGridReact<ProjectListType>>)
    | React.RefObject<AgGridReact<ReportType>>;
    onSelectionChanged?: (res: any) => void;
    masterDetail?: boolean;
    detailCellRendererParams?: IDetails
    noSelection?: boolean;
    allSelection?: boolean;
    rowHeight?: number;
    headerHeight?: number;
    groupIncludeTotalFooter?: boolean;
    pinnedBottomRowData?: any[];
    noSorting?: boolean,
    isPinnable?: boolean,
    isGroupable?: boolean,
    isRangeSelectable?: boolean,
    isRangeHandle?: boolean,
    isExportEnabled?: boolean,
    isClipboardEnabled?: boolean,
    isToolPanelsEnabled?: boolean,
    isStatusBarEnabled?: boolean,
    isLoading?: boolean,
    applyRowBgStyle?: boolean,
    isCommodities?: boolean
    quickFilterText?: string,
    moduleName?: string,
    defaultExpanded?: number,
    customGroupRowRenderer?: any,
    disableResizable?: boolean,
    colFormat?: string[],
    footerFormat?: string[]
}

const Main = styled('main')({
    flexGrow: 1,
    // height: '80vh',
    overflow: 'auto',
});

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    margin: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
}));

function AgGridComponent({
    columnDefs,
    rowData,
    sortOrder,
    changeSortingValue,
    masterDetail,
    detailCellRendererParams,
    onSelectionChanged = () => { },
    gridRef,
    noSelection,
    allSelection,
    rowHeight,
    headerHeight,
    pinnedBottomRowData,
    groupIncludeTotalFooter,
    noSorting,
    isLoading,
    isPinnable,
    isGroupable,
    isRangeSelectable,
    isRangeHandle,
    isExportEnabled,
    isClipboardEnabled,
    isToolPanelsEnabled,
    isStatusBarEnabled,
    applyRowBgStyle,
    moduleName,
    isCommodities,
    defaultExpanded,
    customGroupRowRenderer,
    quickFilterText,
    disableResizable,
    colFormat,
    footerFormat,
}: AgGridComponentProps) {
    const userCtx = useContext(UserContext);
    const classes = AgGridTableStyle();
    const gridRefCommon = useRef<AgGridReact<OrganisationDetailType> | AgGridReact<GetOrgUser> | AgGridReact<ProjectListType>>(null);
    const activeGridRef = gridRef || gridRefCommon;
    const activeModuleName = moduleName ? `${moduleName}_${userCtx?.user?.user_id || ''}` : '';
    const projectCtx = useContext(ProjectContext);
    const curLocation:LocationState = useLocation();

    const customHeaderComponent = (param: CellRendererType) => (
        <div
            role="presentation"
            className="AgHeaderCustom"
            onClick={() => changeSortingValue(param?.column?.colDef?.field)}
        >
            <div className={classes.AgHeaderCustomDesc}>
                {param?.column?.colDef?.headerName}
                {sortOrder === 'DESC' ? (
                    <span>
                        <KeyboardArrowDownIcon />
                    </span>
                ) : (
                    <span>
                        <KeyboardArrowUpIcon />
                    </span>
                )}
            </div>
        </div>
    );

    const menuTabs = allMenuTabs.slice(isPinnable || isGroupable || activeModuleName ? 0 : 1);

    const defaultColDef: ColDef = {
        menuTabs,
        suppressMovable: true,
        flex: disableResizable ? 0 : 1,
        filter: true,
        enableRowGroup: true,
        enableValue: true,
        enablePivot: true,
        sortable: !noSorting,
        unSortIcon: !noSorting,
        icons: {
            sortAscending: () => `<img src="${sortUpIcon}" width="10" height="10" alt="Asc" class="custom-icon" />`,
            sortDescending: () => `<img src="${sortDownIcon}" width="10" height="10" alt="Desc" class="custom-icon" />`,
        },
    };

    columnDefs.forEach((columnDef) => {
        columnDef.minWidth = 50;
        // if (columnDef.sorting) {
        //     // eslint-disable-next-line no-param-reassign
        //     columnDef.headerComponentFramework = customHeaderComponent;
        // }
        columnDef.resizable = true;
        switch (columnDef.type) {
        case 'string': columnDef.filter = 'agMultiColumnFilter';
            break;
        case 'numericColumn':
        case 'number': columnDef.filter = 'agMultiColumnFilter';
            columnDef.filterParams = {
                filters: [
                    {
                        filter: 'agNumberColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    },
                ],
            };
            break;
        default: columnDef.filter = 'agSetColumnFilter';
        }
    });

    const gridOptions: GridOptions = {
        enableRangeSelection: isRangeSelectable,
        enableRangeHandle: isRangeSelectable,
        groupAllowUnbalanced: true,
        embedFullWidthRows: true,
        getMainMenuItems: (param) => {
            const returnArray: (string | MenuItemDef)[] = [];
            if (isPinnable) returnArray.push('pinSubMenu');
            if (isGroupable) returnArray.push('rowGroup');
            if (isPinnable || isGroupable) returnArray.push('separator');
            if (activeModuleName) {
                returnArray.push({
                    name: 'Save Grid Layout',
                    action: () => {
                        const stateTobeStored: GridState = {
                            state: param.columnApi.getColumnState(),
                            filter: param.api.getFilterModel(),
                        };
                        localStorage.setItem(btoa(activeModuleName), btoa(JSON.stringify(stateTobeStored)));
                    },
                });
            }
            if (isPinnable || isGroupable) returnArray.push('separator');
            if (isPinnable || isGroupable) returnArray.push('resetColumns');
            return returnArray;
        },
        getContextMenuItems: () => {
            const returnArray = [];
            if (isClipboardEnabled) returnArray.push('copy');
            if (isExportEnabled) returnArray.push('export');
            return returnArray;
        },
        rowHeight: rowHeight || 27,
        headerHeight,
        sideBar: isToolPanelsEnabled ? {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
            ],
        } : null,
        statusBar: isStatusBarEnabled ? {
            statusPanels: [
                {
                    statusPanel: 'agAggregationComponent',
                    statusPanelParams: {
                        aggFuncs: ['sum', 'avg', 'min', 'max'],
                    },
                    align: 'right',
                },
            ],
        } : undefined,
    };

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;

    // Function to Multiply table data with Exchange rate in Project related pages
    let updatedRowData: RowData[] = [];
    if (colFormat && colFormat.length && exchangeRate && rowData && rowData.length) {
        updatedRowData = rowData?.map((obj) => {
            const newObj = {};
            Object.keys(obj).forEach((key) => {
                if (colFormat.includes(key)) {
                    // display all the columns in WBS, COA, Package, schedule in whole numbers
                    if (key.startsWith('column_') || curLocation?.pathname === '/schedule'
                        || curLocation?.pathname === '/packages' || curLocation?.pathname === '/coaList' || curLocation?.pathname === '/wbs') {
                        (newObj as DynamicType)[key] = (+((obj as DynamicType)[key]) * exchangeRate).toFixed(0);
                    } else if (key === 'total_cost' || key === 'total' || key === 'cost_in_estimate') {
                        // display total column in whole numbers
                        (newObj as DynamicType)[key] = (+((obj as DynamicType)[key]) * exchangeRate).toFixed(0);
                    } else {
                        // display all the column values fixed to 2 decimal place
                        (newObj as DynamicType)[key] = (+((obj as DynamicType)[key]) * exchangeRate).toFixed(2);
                    }
                } else {
                    (newObj as DynamicType)[key] = (obj as DynamicType)[key];
                }
            });
            return newObj;
        });
    }

    const tableData = colFormat ? updatedRowData : rowData;

    // Function to Multiply table Footer values with Exchange rate in Project related pages
    let newPinnedBottomRowData: object[] = [];
    if (footerFormat && footerFormat.length && exchangeRate && pinnedBottomRowData && pinnedBottomRowData.length) {
        newPinnedBottomRowData = pinnedBottomRowData?.map((obj) => {
            const newObj = {};
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Object.keys(obj).forEach((key) => {
                if (footerFormat.includes(key)) {
                    (newObj as DynamicType)[key] = (+((obj as DynamicType)[key]) * exchangeRate).toFixed(0);
                } else {
                    (newObj as DynamicType)[key] = (obj as DynamicType)[key];
                }
            });
            return newObj;
        });
    }

    const footerData = footerFormat ? newPinnedBottomRowData : pinnedBottomRowData;

    // COLUMN_DEF for the first column containing checkbox
    const checkedColumn = {
        field: 'blank',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: allSelection !== undefined ? allSelection : true,
        width: 30,
        maxWidth: 30,
    };

    const columnDefWithDefault = noSelection ? columnDefs : [checkedColumn, ...columnDefs];
    const getRowStyle = (params: RowClassParams) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (params?.data?.level === 1 && applyRowBgStyle) {
            return { 'background-color': '#C1D6FF' };
        }
        return { 'background-color': 'transperant' };
    };
    const groupDisplayType = 'groupRows';

    const groupDefaultExpanded = defaultExpanded || 0;

    const applyGridState = () => {
        const gridStateString = localStorage.getItem(btoa(activeModuleName));
        if (gridStateString) {
            const gridStateObj = JSONParse<GridState>(atob(gridStateString));
            if (gridStateObj && gridStateObj.state) {
                activeGridRef.current?.columnApi?.applyColumnState({
                    state: gridStateObj.state,
                    applyOrder: true,
                });
            }
            if (gridStateObj && gridStateObj.filter) {
                activeGridRef.current?.api?.setFilterModel(gridStateObj.filter);
            }
        }
    };

    const onGridReady = () => {
        applyGridState();
    };

    return (
        <Box className={classes.tabelFoot}>
            <Main>
                <Item>
                    <Box sx={{ height: '100%', width: '100%' }} className={isLoading ? classes.opacityBox : classes.noOpacity}>
                        <div
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            className={`ag-theme-alpine ${isCommodities ? classes.tableStylesCommodities
                                : classes.tableStyles} 
                            ${classes.containerBox}`}
                            style={{
                                height: '400px',
                                width: '100%',
                            }}
                        >
                            {isLoading && (<Loader loading={isLoading} isFromAGGrid />)}
                            <AgGridReact
                                className={classes.loaderBox}
                                ref={activeGridRef}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefWithDefault}
                                rowData={tableData}
                                getRowStyle={getRowStyle}
                                gridOptions={gridOptions}
                                onSelectionChanged={onSelectionChanged}
                                masterDetail={masterDetail}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                pinnedBottomRowData={footerData}
                                groupIncludeTotalFooter={groupIncludeTotalFooter}
                                detailCellRendererParams={detailCellRendererParams}
                                groupDisplayType={groupDisplayType}
                                suppressColumnMoveAnimation
                                suppressDragLeaveHidesColumns
                                suppressMovableColumns
                                groupDefaultExpanded={groupDefaultExpanded}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                groupRowRenderer={customGroupRowRenderer}
                                onGridReady={onGridReady}
                                quickFilterText={quickFilterText}
                                onFirstDataRendered={applyGridState}
                            />
                        </div>
                    </Box>
                </Item>
            </Main>
        </Box>
    );
}

export default AgGridComponent;
