import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_RESOURCES_BY_ORGID = ({ org_id }: any) => gql`
query GetResourcesDataByOrgId {
    resource(where: {org_id: {_eq: "${org_id}"}}) {
      code
      description
      currency
      unit
      rate
      id
    }
  }
`;

const GET_RESOURCES_EXTENDED_INFO = (type: string, skip: number, take: number, sort: string, sortOrder: string) => {
    const filterType = type === 'type: "A"' ? '' : `filter: {${type}}`;
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetResourcesExtendedInfo {
      resource (skip: ${skip}, take: ${take}, ${sortType}, ${filterType})
      {
        pageInfo {
          skip
          take
          hasNextPage
          totalcount
        }, 
        data {
          id 
          org_id 
          project_id 
          cost_input 
          escalation_factor 
          type 
          code 
          generic_code 
          description 
          currency 
          unit 
          date 
          cost_per_unit 
          uom 
          unit_rate 
          total_quantity 
          total_cost 
          created_at 
          updated_at 
        } 
      }
    }
  `;
};

const GET_RESOURCES_INFO = (type: string, skip: number, take: number, orgId: string, projId: string, verId: string, sort?: boolean) => {
    const filterType = type ? `${type}` : '';
    return gql`
  query GetResourcesInfo {
    resource (skip: ${skip}, take: ${take}, filter:{${filterType}, org_id: ${addEscapeCharacters(orgId)}, 
    project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}},
    sort: ${sort ? '{total_cost: DESC}' : '{type: ASC, code: ASC}'})
    {
      pageInfo {
        skip
        take
        hasNextPage
        totalcount
      }, 
      data {
        id
        cost_input
        escalation_factor
        type
        code
        generic_code
        description
        currency
        unit
        date
        cost_per_unit
        uom
        unit_rate
        total_quantity
        total_cost
        created_at
        updated_at 
      } 
    }
  }
`;
};

export default {
    GET_RESOURCES_BY_ORGID,
    GET_RESOURCES_EXTENDED_INFO,
    GET_RESOURCES_INFO,
};
