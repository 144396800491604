import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_RECENT_PROJECTS = (skip: number, take: number, search: string, orgId: string) => {
    const searchParam = search ? `searchKeyword: ${addEscapeCharacters(search)}` : '';
    return gql`
    query  GetRecentProject {
      project(skip: ${skip}, take: ${take}, sort: {updated_at: DESC}, filter: {
        org_id: ${addEscapeCharacters(orgId)},
        ${searchParam},
      }) {
        pageInfo {
          skip
          take
          totalcount
          hasNextPage
        }
        data {
          id
          base_date
          wbs1
          wbs2
          wbs3
          wbs4
          wbs5
          wbs6
          coa1
          coa2
          coa3
          coa4
          coa5
          coa6
          hide_values
          coa_sep
          name
          client_number
          region
          owner
          consultant
          consultant_number
          estimate_type
          estimate_accuracy
          contingency_wbs
          currency
          wbs_sep
          created_at
          updated_at
          project_number
          total_cost
          estimate_accuracy
          version_id
          version_data {
            id
          }
          version_code
          industry_id
          industry {
            description
          }
          subindustry_id
          subindustry {
            description
          }
          country {
            name
          }
          currency_id
          currency_data {
            id
            code
            exchange_rate
            symbol
          }
          is_active   
        }
      }
    }`;
};
export default {
    GET_RECENT_PROJECTS,
};
