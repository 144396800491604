import React, {
    Dispatch, SetStateAction, useRef, useState, useContext,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Grid, Button, IconButton,
} from '@mui/material';
import downloadTempIcon from '../../../../assets/downloadTempIcon.svg';
import uploadExcelIcon from '../../../../assets/uploadExcelIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import ImportOptionsModelStyle from './ImportOptionsModel.style';
import NotificationBar from '../../../../components/NotificationBar';
import NotificationBarType from '../../../../types/NotificationBarType';
import Loader from '../../../../components/Loader';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../../../../types/OrganisationDetailType';
import { UserContext } from '../../../../store/context/userContext';
import { getAccessTokenFromLocalStorage } from '../../../../helper/StorageHelper';

const importUrl = process.env.REACT_APP_DATA_IMPORT_ENDPOINT || '';
interface InviteProps {
    open: boolean,
    setOpenImport: Dispatch<SetStateAction<boolean>>,
    projectId?: string,
    refetchProjInfo?: ({ orgId }?: { [key: string]: string | number }) => void,
    refetchVersion?: ({ orgId }?: { [key: string]: string | number }) => void,
    refetchPhase?: ({ orgId }?: { [key: string]: string | number }) => void,
    refetchCase?: ({ orgId }?: { [key: string]: string | number }) => void,
    refetchProject?: ({ orgId }?: { [key: string]: string | number }) => void,
}

function ImportOptModel({
    open, setOpenImport, projectId, refetchProjInfo, refetchVersion, refetchPhase, refetchCase, refetchProject,
}: InviteProps) {
    const cont: OutletContext = useOutletContext();
    const classes = ImportOptionsModelStyle();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [isloading, setLoading] = useState<boolean>(false);

    // finding orgId
    const userContext = useContext(UserContext);
    const orgId = userContext?.user?.default_org_id || '';

    const handleClose = () => {
        setOpenImport(false);
    };

    const handleDownload = () => {
        window.open('./sample.xlsx', '_blank');
    };

    const handleUpload = () => {
        // Trigger the file input click programmatically
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // --------------------For Uploading excel file------------------------------------------------
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const accessToken = getAccessTokenFromLocalStorage();
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            const formData = new FormData();
            formData.append('multipartFile', selectedFile);
            formData.append('activeOrgId', orgId);
            if (projectId) {
                formData.append('activeProjectId', projectId);
            }
            setLoading(true);
            fetch(`${importUrl}/spring/pcu/import-excel`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${accessToken || ''}`,
                },
            }).then(async (res) => ({
                err: !res.ok,
                data: await res.text(),
                isUnauthorised: res.status === 401 || res.status === 403,
            })).then((data) => {
                const message = data.isUnauthorised ? 'User is unauthorised' : data.data;
                cont.showNotificationBar(message, data.err ? 'error' : 'success');
                if (!data.err) {
                    setOpenImport(false);
                    if (refetchProjInfo) refetchProjInfo();
                    if (refetchVersion) refetchVersion();
                    if (refetchPhase) refetchPhase();
                    if (refetchCase) refetchCase();
                    if (refetchProject) refetchProject();
                }
            }).catch((error) => {
                if (error instanceof Error) {
                    setNotificationDetails({
                        open: true,
                        message: `Error during file upload: ${error.message}`,
                        type: 'error',
                    });
                } else {
                    setNotificationDetails({
                        open: true,
                        message: `Unexpected error during file upload: ${String(error)}`,
                        type: 'error',
                    });
                }
            })
                .finally(() => {
                    setLoading(false);
                    if (fileInputRef.current) fileInputRef.current.value = '';
                });
        }
    };
    //----------------------------------------------------------------------------------------------

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <Loader loading={isloading} />
                <DialogTitle className={classes.dilogBoxSection}>Import Options</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    disabled={isloading}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={classes.dialogBody}>
                    <Grid container className={classes.sectionBody}>
                        <Grid item xs={6} className={classes.leftdivCenter}>
                            <img src={downloadTempIcon} alt="graph" />
                            <Typography>Download the full project import template in Excel format.</Typography>
                            <Button
                                variant="outlined"
                                onClick={handleDownload}
                            >
                                Download Template

                            </Button>
                        </Grid>
                        <Grid item xs={6} className={classes.rigntdivCenter}>
                            <img src={uploadExcelIcon} alt="graph" />
                            <Typography>Upload a complete project that follows the full project import template in Excel format.</Typography>
                            <Button variant="contained" color="primary" onClick={handleUpload}>
                                Upload .excel file
                            </Button>
                            {/* Hidden file input for triggering file selection */}
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept=".xls,.xlsx"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
        </Box>
    );
}

export default ImportOptModel;
